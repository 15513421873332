import { useState } from 'react';
import { MailHtmlController } from 'fragments/notifications/mail-html/interfaces';
import { DataMail } from '../interfaces';

export const useMailHtmlController = (
    onMailHtmlAdded: () => DataMail,
): /* <--Dependency Injections  like services hooks */ MailHtmlController => {
    /* State */
    // Ex. const [count, setCount] = useState(0);
    const [example, setExample] = useState('example');

    const onButtonPressed = () => {
        // Example event
    };
    return { example, onButtonPressed, data: onMailHtmlAdded() };
};
