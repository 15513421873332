import React from 'react';
import { Button, Col, Divider, Form, Grid, Image, Modal, Row, Tooltip, Typography } from 'antd';
import 'fragments/follow-up-card/follow-up-card-services-payment/follow-up-card-services-payment.scss';
import { FollowUpCardServicesPaymentFragmentProps } from 'fragments/follow-up-card/follow-up-card-services-payment/interfaces';
import { useFollowUpCardServicesPaymentController } from 'fragments/follow-up-card/follow-up-card-services-payment/follow-up-card-services-payment.controller';
import LiabilitiesPayment from 'components/liabilities-payment/liabilities-payment.component';
import { fixSelectOnScroll } from 'tools/view-hooks/fix-autocomplete';

export const FollowUpCardServicesPaymentFragment: React.FC<FollowUpCardServicesPaymentFragmentProps> = (props) => {
    const { useController = useFollowUpCardServicesPaymentController } = props;
    const controller = useController();
    const screens = Grid.useBreakpoint();

    // Render
    return (
        <div className={'follow-up-card-services-payment'}>
            <Modal
                visible={controller.isModalVisible}
                title={`Registrar pagos`}
                onOk={controller.onOk}
                onCancel={controller.onCancel}
                cancelButtonProps={{ disabled: controller.isLoading }}
                okButtonProps={{ loading: controller.isLoading }}
                closable={!controller.isLoading}
                maskClosable={!controller.isLoading}
                destroyOnClose
            >
                <Form
                    form={controller.formInstance}
                    onFinish={controller.onFormFinish}
                    initialValues={controller.formInitialValues}
                    preserve={false}
                >
                    {controller.renterLiabilities.length > 0 && (
                        <div>
                            <Typography.Title level={5}>Obligaciones Inquilino</Typography.Title>
                            <LiabilitiesPayment payerType={'renter'} liabilities={controller.renterLiabilities} />
                        </div>
                    )}
                    {controller.renterLiabilities.length > 0 && controller.ownerLiabilities.length > 0 && <Divider />}
                    {controller.ownerLiabilities.length > 0 && (
                        <div>
                            <Typography.Title level={5}>Obligaciones Propietario</Typography.Title>
                            <LiabilitiesPayment payerType={'owner'} liabilities={controller.ownerLiabilities} />
                        </div>
                    )}
                </Form>
            </Modal>
            <Row>
                {controller.liabilities.map((liability) => (
                    <Col key={liability.id}>
                        <Tooltip
                            key={1}
                            getPopupContainer={fixSelectOnScroll}
                            visible={screens.xs ? false : undefined}
                            title={liability.name}
                        >
                            <Button
                                style={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    display: 'flex',
                                }}
                                type={'ghost'}
                                shape={'circle'}
                                icon={
                                    <Image
                                        preview={false}
                                        onClick={controller.onClickService}
                                        src={
                                            liability.payment?.isPaid == true
                                                ? liability.type?.urlIconPaid
                                                : liability.type?.urlIconNotPaid
                                        }
                                    />
                                }
                            />
                        </Tooltip>
                    </Col>
                ))}
            </Row>
        </div>
    );
};
