import React, { useState } from 'react';

import { Button, DatePicker, Form, Switch, Checkbox, Row, Col, Input, Space } from 'antd';

import 'fragments/filter-inicio/filter-inicio.scss';
import { FilterInicioFragmentProps } from 'fragments/filter-inicio/interfaces';
import { useFilterInicioController } from 'fragments/filter-inicio/filter-inicio.controller';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { Typography } from 'antd/es';

import { SearchOutlined, UpOutlined, DownOutlined } from '@ant-design/icons';

export const FilterInicioFragment: React.FC<FilterInicioFragmentProps> = (props) => {
    const { useController = useFilterInicioController } = props;
    const controller = useController(props.offset, props.limit, props.onFilterChange, props.onCleanFilter);
    const { translate } = useTranslator();
    const { RangePicker } = DatePicker;

    const [showAllFilters, setShowAllFilters] = useState(false);

    // Render
    return (
        <Form
            className={'filter-form'}
            form={controller.form}
            layout={'vertical'}
            initialValues={controller.initialValues}
            onFinish={controller.onFormSubmit}
        >
            <Row gutter={20}>
                <Col xs={24} sm={16}>
                    <Form.Item name="searchText" label={'Nombre'}>
                        <Input
                            size={'middle'}
                            prefix={<SearchOutlined />}
                            placeholder={'Ingrese el nombre del propietario o inquilino'}
                        />
                    </Form.Item>
                </Col>
            </Row>
            {showAllFilters && (
                <Row gutter={20}>
                    <Col xs={24} sm={9} md={8}>
                        <Form.Item
                            label={translate({ key: 'follow-ups.payment-date' })}
                            labelAlign="left"
                            labelCol={{ span: '20' }}
                            name="date"
                            wrapperCol={{ span: '24' }}
                        >
                            <RangePicker picker="month" size={'middle'} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={10} md={6}>
                        <Form.Item name="followUpStatus" label={translate({ key: 'follow-ups.state' })}>
                            <Checkbox.Group>
                                <Row>
                                    <Col>
                                        <Checkbox onChange={controller.filterSelectedFunction} value="expired">
                                            <Typography.Text>
                                                {translate({ key: 'follow-ups.in-arrears' })}
                                            </Typography.Text>
                                        </Checkbox>
                                    </Col>
                                    <Col>
                                        <Checkbox onChange={controller.filterSelectedFunction} value="pending">
                                            <Typography.Text>
                                                {translate({ key: 'follow-ups.pending' })}
                                            </Typography.Text>
                                        </Checkbox>
                                    </Col>
                                    <Col>
                                        <Checkbox onChange={controller.filterSelectedFunction} value="isPaid">
                                            <Typography.Text>{translate({ key: 'follow-ups.paid' })}</Typography.Text>
                                        </Checkbox>
                                    </Col>
                                </Row>
                            </Checkbox.Group>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={4}>
                        <Form.Item
                            label={translate({ key: 'follow-ups.unpaid-services' })}
                            labelAlign="left"
                            labelCol={{ span: '24' }}
                            name="servicesNotPaid"
                            wrapperCol={{ span: '24' }}
                            valuePropName={'checked'}
                        >
                            <Switch />
                        </Form.Item>
                    </Col>
                </Row>
            )}
            <Form.Item>
                <Space>
                    {controller.isCleanFilterVisible && (
                        <Button onClick={controller.cleanFilter} size={'middle'}>
                            Limpiar Filtros
                        </Button>
                    )}
                    <Button type="primary" htmlType="submit" size={'middle'}>
                        Filtrar
                    </Button>
                    <a
                        style={{ fontSize: 12 }}
                        onClick={() => {
                            setShowAllFilters(!showAllFilters);
                        }}
                    >
                        {showAllFilters ? <UpOutlined /> : <DownOutlined />}
                        {showAllFilters ? 'Menos filtros' : 'Mas filtros'}
                    </a>
                </Space>
            </Form.Item>
        </Form>
    );
};
