import React from 'react';
import 'fragments/property-card/property-card-details/property-card-details.scss';
import { PropertyCardDetailsFragmentProps } from 'fragments/property-card/property-card-details/interfaces';
import { usePropertyCardDetailsController } from 'fragments/property-card/property-card-details/property-card-details.controller';
import { AddPropertyDetailFragment } from 'fragments/property-card/add-property-detail/add-property-detail.fragment';
import { Descriptions, Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import ParagraphCollapsible from 'components/paragraph-collapsible/paragraph-collapsible.component';
import { useTranslator } from 'tools/view-hooks/translator-hook';

export const PropertyCardDetailsFragment: React.FC<PropertyCardDetailsFragmentProps> = (props) => {
    const { useController = usePropertyCardDetailsController } = props;
    const controller = useController();
    const { translate } = useTranslator();
    const { Text } = Typography;

    // Render
    return (
        <div className={'property-card-details'}>
            <AddPropertyDetailFragment
                isVisible={controller.isDetailFormVisible}
                onCancel={controller.onDetailCanceled}
                onFinish={controller.onDetailUpdated}
            />
            <Descriptions
                title={translate({ key: 'properties.property-detail' })}
                column={1}
                labelStyle={{ fontWeight: 'bold' }}
                extra={<EditOutlined className={'link-icon'} onClick={controller.onEditDetailPressed} />}
            >
                <Descriptions.Item label={translate({ key: 'properties.rooms' })}>
                    <Text>{controller.roomsCountLabel}</Text>
                </Descriptions.Item>
                <Descriptions.Item label={translate({ key: 'properties.bathrooms' })}>
                    <Text>{controller.bathCountLabel}</Text>
                </Descriptions.Item>
                <Descriptions.Item label={translate({ key: 'properties.furnished' })}>
                    <Text>{controller.isFurnishedLabel}</Text>
                </Descriptions.Item>
                <Descriptions.Item label={translate({ key: 'properties.description' })} className={'long-description'}>
                    <ParagraphCollapsible>{controller.description}</ParagraphCollapsible>
                </Descriptions.Item>
            </Descriptions>
        </div>
    );
};
