import React from 'react';

import { Divider, Form, Input, InputNumber, Select, Space } from 'antd';

import 'fragments/add-person/add-person.scss';
import ModalForm from 'components/modal-form/modal-form.component';
import { AddPersonFragmentProps } from 'fragments/add-person/interfaces';
import { useAddPersonController } from 'fragments/add-person/add-person.controller';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import CreatePersonsFields from 'components/create-persons-fields/create-persons-fields.component';

export const AddPersonFragment: React.FC<AddPersonFragmentProps> = (props) => {
    const { useController = useAddPersonController } = props;
    const controller = useController(props.onPersonAdded);
    const { translate } = useTranslator();

    // Render
    return (
        <ModalForm
            className={'add-person'}
            onFinish={controller.onFormSubmit}
            form={controller.form}
            isvisible={props.isVisible}
            onCancel={props.onCancel}
            isLoading={controller.isLoading}
            layout={'vertical'}
            title={translate({ key: 'persons.add-person' })}
        >
            <CreatePersonsFields provincesOptions={controller.provincesOptions} />
        </ModalForm>
    );
};
