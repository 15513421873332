import React from 'react';
import 'components/delete-modal-button/delete-modal-button.scss';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Image, Modal } from 'antd';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { ReactComponent as IconDelete } from 'assets/icons-svg/icons-delete.svg';

export interface DeleteModalButtonProps {
    id: number;
    onClickDelete: (id: number) => void;
    title: string;
}

const DeleteModalButton: React.FC<DeleteModalButtonProps> = (props) => {
    const { translate } = useTranslator();

    return (
        <Button
            style={{
                padding: '10px',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                marginRight: '8px',
                backgroundColor: '#7258db',
            }}
            onClick={() => {
                Modal.confirm({
                    title: props.title,
                    icon: <QuestionCircleOutlined />,
                    onOk() {
                        props.onClickDelete(props.id);
                    },
                });
            }}
        >
            <IconDelete />
        </Button>
    );
};

export default DeleteModalButton;
