import { PersonDto } from 'services/persons/person.dto';
import { DocType } from 'services/persons/person.dto';
import { ProvinceDto } from 'services/settings/settings.dto';

export class CompletePersonDto extends PersonDto {
    id?: number;
    name?: string;
    typeDoc?: DocType;
    numberDoc?: string;
    cuit?: number | string;
    phone?: number | string;
    email?: string;
    district?: string;
    postalCode?: string;
    street?: string;
    number?: number | string;
    floorDepartment?: string;
    province?: ProvinceDto;
    city?: string;
    cbu?: string;
    rolPersonStatus?: string;
    activesRoles?: string[];
    isVisible?: boolean;
}
