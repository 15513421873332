import React, { Dispatch, SetStateAction } from 'react';
import { CompletePropertyDto } from 'services/properties/property-complete.dto';

export interface PropertyContextInterface {
    property: CompletePropertyDto;
    setProperty: Dispatch<SetStateAction<CompletePropertyDto>>;
}

export const PropertyContext = React.createContext<PropertyContextInterface>({
    property: {},
    setProperty: () => {
        // Nothing to do
    },
});
