import React from 'react';
import 'components/delete-tooltip-button/delete-tooltip-button.scss';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Image } from 'antd';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import iconDelete from 'assets/icons-svg/icons-delete.svg';

export interface DeleteTooltipButtonProps {
    id: number;
    onClickDelete: (id: number) => void;
}

const DeleteTooltipButton: React.FC<DeleteTooltipButtonProps> = (props) => {
    const { translate } = useTranslator();

    return (
        <Popconfirm
            title={translate({ key: 'general.sure-to-delete' })}
            onConfirm={() => {
                props.onClickDelete(props.id);
            }}
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
        >
            <Button
                style={{
                    padding: '10px',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    marginRight: '8px',
                    backgroundColor: 'gray',
                }}
            >
                <Image preview={false} src={iconDelete} />
            </Button>
        </Popconfirm>
    );
};

export default DeleteTooltipButton;
