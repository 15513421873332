import { useLocalSession } from 'auth/helpers/session.hooks';
import { ApiPdfFreeReceiptService } from './api-pdf-free-receipt.service';
import { CreatePdfFreeReceiptDto } from './complete-pdf-free-receipt.dto';
import { PdfFreeReceiptDto } from './pdf-free-receipt.dto';

export interface PdfFreeReceiptService {
    generatePdfFreeReceipt(body: CreatePdfFreeReceiptDto): Promise<PdfFreeReceiptDto>;
}

export const useAPIPdfFreeReceiptService = (): PdfFreeReceiptService => {
    const [getSession] = useLocalSession();
    const session = getSession();
    return new ApiPdfFreeReceiptService(session);
};
