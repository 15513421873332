import { ApiMembershipsService } from './api-memberships.service';
import { useLocalSession } from 'auth/helpers/session.hooks';
import { CurrentMembershipDetailsDto } from './membership.dto';

export interface MembershipsService {
    getCurrentMembershipDetail(): Promise<CurrentMembershipDetailsDto>;
    upgradeMembership(): Promise<any>;
    cancelMembership(): Promise<any>;
}

export const useAPIMembershipService = (): MembershipsService => {
    const [getSession] = useLocalSession();
    const session = getSession();
    return new ApiMembershipsService(session);
};
