import { useLocalSession } from 'auth/helpers/session.hooks';
import { PersonDto } from './person.dto';
import { CompletePersonDto } from './person-complete.dto';
import { ApiPersonsService } from './api-persons.service';

export interface PersonsService {
    getAllPersonsVisible(): Promise<CompletePersonDto[]>;
    getAllPersons(): Promise<CompletePersonDto[]>;
    getOnePerson(id: number): Promise<CompletePersonDto>;
    addPerson(input: PersonDto): Promise<CompletePersonDto>;
    searchPersons(input: { param: string }): Promise<CompletePersonDto[]>;
    updatePerson(id: number, input: PersonDto): Promise<CompletePersonDto>;
    deletePerson(id: number): Promise<void>;
}

export const useAPIPersonsService = (): PersonsService => {
    const [getSession] = useLocalSession();
    const session = getSession();
    return new ApiPersonsService(session);
};
