import React from 'react';
import 'components/download-settlements-modal/download-settlements-modal.scss';
import { DownloadableItem } from '../../services/rentalPayments/receipt.dto';
import { Button, Modal, Typography } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useTranslator } from '../../tools/view-hooks/translator-hook';

export interface DownloadSettlementsModalProps {
    dto: {
        selectedFollowUpsByOwner: DownloadableItem[];
        currentMonthFollowUpsByOwner: DownloadableItem[];
    };
    isVisible: boolean;
    onCancel?: () => void;
}

const DownloadSettlementsModal: React.FC<DownloadSettlementsModalProps> = (props) => {
    // State
    const { Text } = Typography;
    const { translate } = useTranslator();

    return (
        <Modal
            title={translate({ key: 'follow-ups.liquidations' })}
            visible={props.isVisible}
            onCancel={props.onCancel}
            onOk={props.onCancel}
            className={'download-settlements-modal'}
        >
            {props.dto.selectedFollowUpsByOwner.map((item, index) => {
                return (
                    <div key={item.label} className="settlement-container">
                        <Text className={'settlement-name'}>{item.label} </Text>{' '}
                        <Button
                            className={'settlement-btn'}
                            type="primary"
                            shape="round"
                            href={item.url}
                            icon={<DownloadOutlined />}
                        >
                            <Text style={{ color: 'white' }}>{translate({ key: 'follow-ups.download-selected' })}</Text>
                        </Button>
                        <Button
                            className={'settlement-btn'}
                            type="primary"
                            shape="round"
                            href={props.dto.currentMonthFollowUpsByOwner[index].url}
                            icon={<DownloadOutlined />}
                        >
                            <Text style={{ color: 'white' }}>
                                {translate({ key: 'follow-ups.download-current-month' })}
                            </Text>
                        </Button>
                    </div>
                );
            })}
            <Typography.Text className="footer-text">
                <p style={{ marginTop: '20px' }}>{translate({ key: 'follow-ups.download-settlement' })}</p>
            </Typography.Text>
        </Modal>
    );
};

export default DownloadSettlementsModal;
