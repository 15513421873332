import React from 'react';
import 'fragments/property-card/add-property-detail/add-property-detail.scss';
import { AddPropertyDetailFragmentProps } from 'fragments/property-card/add-property-detail/interfaces';
import { useAddPropertyDetailController } from 'fragments/property-card/add-property-detail/add-property-detail.controller';
import { Form, Input, InputNumber, Space, Switch, Row, Col } from 'antd';
import ModalForm from 'components/modal-form/modal-form.component';
import { useTranslator } from 'tools/view-hooks/translator-hook';

export const AddPropertyDetailFragment: React.FC<AddPropertyDetailFragmentProps> = (props) => {
    const { useController = useAddPropertyDetailController } = props;
    const controller = useController(props.onFinish);
    const { translate } = useTranslator();
    const { TextArea } = Input;
    // Render
    return (
        <ModalForm
            className={'add-property-detail'}
            onFinish={controller.onFormSubmit}
            form={controller.form}
            isvisible={props.isVisible}
            onCancel={props.onCancel}
            isLoading={controller.isLoading}
            initialValues={controller.initialValues}
            layout={'vertical'}
            title={translate({ key: 'properties.property-detail' })}
        >
            <Row gutter={10}>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item label={translate({ key: 'properties.number-of-rooms' })} name="rooms">
                        <InputNumber min={0} type="number" />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item label={translate({ key: 'properties.number-of-bathrooms' })} name="bathRooms">
                        <InputNumber min={0} style={{ flex: 1 }} type="number" />
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item valuePropName="checked" label={translate({ key: 'properties.is-furnished' })} name="isFurnished">
                <Switch
                    checkedChildren={translate({ key: 'general.yes' })}
                    unCheckedChildren={translate({ key: 'general.no' })}
                />
            </Form.Item>
            <Form.Item label={translate({ key: 'properties.description' })} name="description">
                <TextArea />
            </Form.Item>
        </ModalForm>
    );
};
