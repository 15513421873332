import { PersonDto } from 'services/persons/person.dto';
import { PropertyDto } from 'services/properties/property.dto';

export class ContractDto {
    initialDate?: Date;
    durationMonths?: number;
    finishDate?: Date;
    startPrice?: number;
    newProperty?: PropertyDto;
    newOwner?: PersonDto;
    newRenter?: PersonDto;
    newGuarantor?: PersonDto;
    percentageByAdministration?: number;
    updateFrequency?: number;
    propertyId?: number;
    rentersId?: number[];
    guarantorsId?: number[];
    observationRescind?: string;
    dateResition?: Date;
    fileId?: number;
    amountsActualizations?: any[];
    typeOfCharge?: string;
    debtCollection?: number;
    expiredDay?: number;
    initialDayInterest?: number;
    dontCharge?: boolean;
}

export class InformationInterestDto {
    typeOfCharge?: string;
    debtCollection?: number;
    expiredDay?: number;
    initialDayInterest?: number;
    dontCharge?: boolean;
}
