import { PropertiesController } from 'fragments/properties/interfaces';
import { useEffect, useState } from 'react';
import { PropertiesService, useAPIPropertieService } from 'services/properties/properties.service';
import { useMessenger } from 'tools/view-hooks/messenger-hook';
import { CompletePropertyDto } from 'services/properties/property-complete.dto';
import { PropertyCardFragmentProps } from 'fragments/property-card/interfaces';

export const usePropertiesController = (
    propertiesService: PropertiesService = useAPIPropertieService(),
    messenger = useMessenger(),
): PropertiesController => {
    const [isLoading, setIsLoading] = useState(false);
    const [isAddPropertyVisible, setIsAddPropertyVisible] = useState(false);
    const [cardProps, setCardProps] = useState<PropertyCardFragmentProps[]>([]);

    /* Listeners */

    useEffect(() => {
        fetchCardProperties();
    }, []);

    /* View Events */
    const removeCardProp = (id: number) => {
        setCardProps((actualState) => {
            return actualState.filter((item) => {
                return item.propertyDto.id != id;
            });
        });
    };

    const onDeleteProperty = (id: number) => {
        propertiesService
            .deleteProperty(Number(id))
            .then(() => {
                removeCardProp(id);
            })
            .catch(() => {
                messenger.showErrorMessage({ key: 'properties.remove-property-error' });
            });
    };

    const onAddPropertyPressed = () => {
        setIsAddPropertyVisible(true);
    };

    const onAddPropertyCanceled = () => {
        setIsAddPropertyVisible(false);
    };

    /* Private Methods */
    const mapDtoToCardProps = (dto: CompletePropertyDto): PropertyCardFragmentProps => {
        return {
            key: `${dto.id}`,
            propertyDto: dto,
            onDelete: onDeleteProperty,
        };
    };

    const fetchCardProperties = () => {
        setIsLoading(true);
        propertiesService
            .getAllProperties()
            .then((dtos) => {
                const cardPropsMapped = dtos.map(mapDtoToCardProps);
                setCardProps(cardPropsMapped);
            })
            .catch(() => {
                messenger.showErrorMessage({ key: 'properties.get-properties-error' });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const onPropertyAdded = (dto: CompletePropertyDto) => {
        const cardProp = mapDtoToCardProps(dto);
        setCardProps((prevState) => {
            return [cardProp, ...prevState];
        });
        setIsAddPropertyVisible(false);
    };

    const onSearch = (query: string) => {
        propertiesService
            .searchProperty({ param: query })
            .then((resp) => {
                const cardPropsMapped = resp.map(mapDtoToCardProps);
                setCardProps(cardPropsMapped);
            })
            .catch((err) => {
                messenger.showErrorMessage({ key: 'persons.remove-person-error' });
            });
    };

    return {
        /* State */
        isLoading,
        propertiesCardProps: cardProps,
        isAddPropertyVisible: isAddPropertyVisible,
        /* Events */
        onSearch,
        onAddPropertyPressed: onAddPropertyPressed,
        onAddPropertyCanceled: onAddPropertyCanceled,
        onPropertyAdded: onPropertyAdded,
    };
};
