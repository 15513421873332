import { FollowUpService } from './followUp.service';
import axios from 'axios';
import { Session } from 'auth/models/session';
import { CompleteFollowUpDto, PaginationDto, priceDto } from 'services/followUps/followUp.dto';

export class ApiFollowUpService implements FollowUpService {
    constructor(private session: Session) {}
    async getAllFollowUps(input?: { offset: number; limit: number }): Promise<PaginationDto<CompleteFollowUpDto>> {
        let query;
        if (input) {
            query = `?offset=${input?.offset}&limit=${input?.limit}`;
        } else {
            query = '';
        }
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/followup/${query}`, {
            headers: { Authorization: `Bearer ${this.session.getToken()}` },
        });
        return {
            total: response.data.total,
            data: response.data.data,
        };
    }
    async updatePrice(input: priceDto): Promise<CompleteFollowUpDto> {
        const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/followup/${Number(input.id)}`, input, {
            headers: { Authorization: `Bearer ${this.session.getToken()}` },
        });
        return response.data as CompleteFollowUpDto;
    }

    async updateCharge(input: { charge: boolean }, id: number): Promise<CompleteFollowUpDto> {
        const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/followup/charge/${id}`, input, {
            headers: { Authorization: `Bearer ${this.session.getToken()}` },
        });
        return response.data as CompleteFollowUpDto;
    }

    async applyFilters(inputQuery: any): Promise<PaginationDto<CompleteFollowUpDto>> {
        const createQuery = new URLSearchParams(inputQuery);

        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/followup/filter/?${createQuery}`, {
            headers: { Authorization: `Bearer ${this.session.getToken()}` },
        });
        return {
            total: response.data.total,
            data: response.data.data,
        };
    }
}
