import React from 'react';

import { Button, Col, Row, Table, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import 'fragments/properties/properties.scss';
import { usePropertiesController } from 'fragments/properties/properties.controller';
import { PropertiesFragmentProps } from 'fragments/properties/interfaces';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { AddPropertyFragment } from 'fragments/add-property/add-property.fragment';
import { PropertyCardFragment } from 'fragments/property-card/property-card.fragment';
import { PropertyCardFragmentProps } from 'fragments/property-card/interfaces';
import FlexLoader from '../../components/flex-loader/flex-loader.component';
import InputSearch from 'components/input-search/input-search.component';

export const PropertiesFragment: React.FC<PropertiesFragmentProps> = (props) => {
    const { useController = usePropertiesController } = props;
    const controller = useController();

    const { translate } = useTranslator();
    const { Column } = Table;

    return (
        <div className="properties-fragment">
            <AddPropertyFragment
                isVisible={controller.isAddPropertyVisible}
                onCancel={controller.onAddPropertyCanceled}
                onPropertyAdded={controller.onPropertyAdded}
            />
            {controller.isLoading ? (
                <FlexLoader />
            ) : (
                <div>
                    <Row gutter={10} justify="space-between" className={'properties-header'}>
                        <Col xs={24} lg={10}>
                            <Button
                                type="primary"
                                className={'add-property-btn'}
                                icon={<PlusOutlined />}
                                onClick={controller.onAddPropertyPressed}
                            >
                                <Typography.Text className="text-button">
                                    {translate({ key: 'properties.add-property' })}
                                </Typography.Text>
                            </Button>
                        </Col>
                        <Col xs={24} lg={12} xl={8}>
                            <InputSearch onSearch={controller.onSearch} />
                        </Col>
                    </Row>
                    <Table
                        pagination={{ defaultPageSize: 6 }}
                        dataSource={controller.propertiesCardProps}
                        showHeader={false}
                    >
                        <Column
                            render={(text: string, propertyDto: PropertyCardFragmentProps) => (
                                <PropertyCardFragment {...propertyDto} />
                            )}
                        />
                    </Table>
                </div>
            )}
        </div>
    );
};
