import { useContext, useState } from 'react';
import { ContractCardDetailsController } from './interface';
import { ContractContext } from '../contract-card.component';
import { ContractsService, useAPIContractService } from 'services/contracts/contracts.service';
import { useMessenger } from 'tools/view-hooks/messenger-hook';
import { currencyFormatter } from 'components/currency-input/currency-input.component';

export const useContractCardDetailsController = (
    contractsService: ContractsService = useAPIContractService(),
    messenger = useMessenger(),
): ContractCardDetailsController => {
    /* State */
    const [isDetailFormVisible, setIsDetailFormVisible] = useState(false);
    const { contract } = useContext(ContractContext);
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);

    /* Listeners */

    /* View Events */

    const onEditContractPressed = () => {
        setIsDetailFormVisible(true);
    };

    const onDetailCanceled = () => {
        setIsDetailFormVisible(false);
    };

    const onDetailUpdated = () => {
        setIsDetailFormVisible(false);
    };

    const onEditContractDetailsPressed = () => {
        setIsEditModalVisible(true);
    };

    const onEditCanceled = () => {
        setIsEditModalVisible(false);
    };

    const onFinish = () => {
        setIsEditModalVisible(false);
    };

    const onDeleteContractFile = (id: number) => {
        contractsService
            .deleteContractFile(id)
            .then(() => {
                // Nothing yet
            })
            .catch(() => {
                messenger.showErrorMessage({ key: 'contracts.remove-contract-file-error' });
            });
    };

    let guarantorName;
    if (contract.guarantors && contract.guarantors[0] && !contract.guarantors[0].isVisible) {
        guarantorName = contract.guarantors[0].name + ' ' + contract.guarantors[0].lastName + ' ' + '(eliminado/a)';
    } else if (contract.guarantors && contract.guarantors[0]) {
        guarantorName = contract.guarantors[0].name + ' ' + contract.guarantors[0].lastName;
    }

    /* Private Methods */

    // Return state and events

    const initialDateContract = new Date(contract.initialDate ? contract.initialDate : '');

    const startDateContract = initialDateContract.getMonth() + 1 + '/' + initialDateContract.getFullYear();

    const durationContract = contract.durationMonths ? Math.round(contract.durationMonths / 12) : 0;

    const startPrice = currencyFormatter(contract.startPrice);
    return {
        onDeleteContractFile,
        id: contract.id ?? 0,
        startDateContract,
        periodicity: contract.updateFrequency ? `${contract.updateFrequency}` : '',
        fileUrl: contract.contractFile?.file.fileUrl ?? '',
        durationContract,
        rentalPrice: contract.startPrice ?? 0,
        percentageByAdministration: contract.percentageByAdministration ?? 0,
        guarantors: contract.guarantors ?? [],
        isDetailFormVisible,
        onEditContractPressed,
        onDetailUpdated,
        onDetailCanceled,
        isEditModalVisible,
        onEditCanceled,
        onEditContractDetailsPressed,
        onFinish,
        guarantorName: guarantorName ?? '',
        startPrice,
    };
};
