import React, { useContext } from 'react';
import { SiderNavBar, SiderNavBarProps } from 'components/sider-nav-bar/sider-nav-bar.component';
import { PropertiesFragment } from 'fragments/properties/properties.fragment';
import { PersonsFragment } from 'fragments/persons/persons.fragment';
import { ContractsFragment } from 'fragments/contracts/contracts.fragment';
import { SettingsFragment } from 'fragments/settings/settings.fragment';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { FollowUpsListFragment } from 'fragments/follow-ups-list/follow-ups-list.fragment';
import { SessionContext } from 'auth/helpers/session.context';
import { Alert, Button } from 'antd';
import { NotificationsFragment } from 'fragments/notifications/notifications.fragment';
import { ReactComponent as IconNotification } from 'assets/icons-svg/icons-navegaci-n-notificaciones.svg';
import { ReactComponent as IconHome } from 'assets/icons-svg/icons-navegaci-n-home.svg';
import { ReactComponent as IconPersonas } from 'assets/icons-svg/icons-navegaci-n-personas.svg';
import { ReactComponent as IconPropiedades } from 'assets/icons-svg/icons-navegaci-n-propiedades.svg';
import { ReactComponent as IconContratos } from 'assets/icons-svg/icons-navegaci-n-contratos.svg';
import { ReactComponent as IconConfiguracion } from 'assets/icons-svg/icons-navegaci-n-configuraci-n.svg';
import { useAPIMembershipService } from 'services/memberships/memberships.service';
import imageCam from 'assets/image@3x.png';

export const MainNavigator: React.FC = () => {
    const membershipsService = useAPIMembershipService();
    const { translate } = useTranslator();
    const { sessionData, session } = useContext(SessionContext);

    const onClickUpdate = () => {
        membershipsService
            .upgradeMembership()
            .then((url) => {
                window.open(url);
            })
            .catch((err) => {
                console.log('error catch', err.response.data);
            });
    };

    const config: SiderNavBarProps = {
        avatar: sessionData.avatarUrl ?? imageCam,
        admin: sessionData.realEstate?.admin ?? '',
        realEstate: sessionData.realEstate?.name ?? '',
        email: sessionData.realEstate?.email ?? '',
        contentTop:
            sessionData.currentMembership?.remainingContracts != undefined &&
            sessionData.currentMembership?.remainingContracts < 0 ? (
                <Alert
                    className={sessionData.mustUpgradePlan ? 'alert' : 'hidden-alert'}
                    type="error"
                    message={
                        <div>
                            Posees más contratos activos que lo permitido por tu plan actual, debes
                            <Button type="link" size="small" onClick={onClickUpdate}>
                                actualizar tu plan
                            </Button>
                            o
                            <Button type="link" size="small" href={'/contratos'}>
                                deshabilitar contratos activos.
                            </Button>
                            Si no lo haces durante los próximos 30 días, no podrás agregar contratos ni marcar
                            alquileres como pagos
                        </div>
                    }
                />
            ) : null,
        sections: [
            {
                screens: {
                    inicio: {
                        title: translate({ key: 'follow-ups.title' }),
                        icon: <IconHome />,
                        component: <FollowUpsListFragment />,
                    },
                },
            },
            {
                screens: {
                    contratos: {
                        title: translate({ key: 'contracts.title' }),
                        icon: <IconContratos />,
                        component: <ContractsFragment />,
                    },
                    personas: {
                        title: translate({ key: 'persons.title' }),
                        icon: <IconPersonas />,
                        component: <PersonsFragment />,
                    },
                    propiedades: {
                        title: translate({ key: 'properties.title' }),
                        icon: <IconPropiedades />,
                        component: <PropertiesFragment />,
                    },
                },
            },
            {
                screens: {
                    notificaciones: {
                        title: translate({ key: 'notifications.title' }),
                        icon: <IconNotification />,
                        component: <NotificationsFragment />,
                    },
                    configuracion: {
                        title: translate({ key: 'settings.title' }),
                        icon: <IconConfiguracion />,
                        component: <SettingsFragment />,
                    },
                },
            },
        ],
        footer: translate({ key: 'footer.copyright-message' }),
        showSider: true,
    };

    return <SiderNavBar {...config} />;
};
