import React from 'react';
import { Button, Descriptions } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import ObservationCard from 'components/observations-card/obsevation-card.component';
import { useContractsObservationsController } from './contract-observations.controller';
import { ContractsObservationsFragmentProps } from './interfaces';
import { ContractAddObservationFragment } from '../contract-add-observation/contract-add-observation.fragment';
import { useTranslator } from 'tools/view-hooks/translator-hook';

export const ContractsObservationsFragment: React.FC<ContractsObservationsFragmentProps> = (props) => {
    const { useController = useContractsObservationsController } = props;
    const controller = useController();
    const { translate } = useTranslator();

    return (
        <div className={'contract-observations'}>
            {controller.isAddObservationModalVisible && (
                <ContractAddObservationFragment
                    onObservationAdded={controller.onObservationAdded}
                    onCancel={controller.onAddObservationCanceled}
                    isVisible={controller.isAddObservationModalVisible}
                />
            )}
            <Descriptions
                title={translate({ key: 'contracts.observations' })}
                column={1}
                labelStyle={{ fontWeight: 'bold' }}
                extra={<EditOutlined className={'link-icon'} onClick={controller.onAddObservationPressed} />}
            >
                <Descriptions.Item>
                    {controller.observationViewModel[0] ? (
                        <div>
                            <ObservationCard {...controller.observationViewModel[0]} />
                        </div>
                    ) : (
                        '-'
                    )}
                </Descriptions.Item>
            </Descriptions>
            {controller.observationViewModel.length > 1 ? (
                <Button
                    type="link"
                    style={{ background: 'none', border: 'none' }}
                    onClick={controller.onAddObservationPressed}
                >
                    {translate({ key: 'observations.see-more' })}
                </Button>
            ) : null}
        </div>
    );
};
