import { useContext, useEffect, useState } from 'react';
import {
    LiabilityItemView,
    ListPropertyServicesController,
} from 'fragments/property-card/list-property-services/interfaces';
import { Form } from 'antd';
import { TypeServicesService, useAPITypeServicesService } from 'services/typeServices/type-services.service';
import { TypeServiceDto } from 'services/typeServices/type-services.dto';
import { PropertyContext } from 'fragments/property-card/property.context';
import {
    AritmeticOperation,
    CreateContractLiability,
    LiabilitiesService,
    LiabilityDto,
    LiabilityInstance,
    LiabilityPaymentTerm,
    useAPILiabilitiesService,
} from 'services/liabilities/liabilities.service';
import {
    EditLiabilityFormInputs,
    SelectableTypeServiceOptions,
} from 'fragments/property-card/create-edit-liability/interfaces';
import moment from 'moment';
import { LabelValue } from 'components/view-models';

export const useListPropertyServicesController = (
    executeOnCancelProp?: () => void,
    liabilitiesService: LiabilitiesService = useAPILiabilitiesService(),
    typeServicesService: TypeServicesService = useAPITypeServicesService(),
): ListPropertyServicesController => {
    /* State */
    const [isEditSingleServiceVisible, setIsEditSingleServiceVisible] = useState(false);
    const [isCreateNewServiceVisible, setIsCreateNewServiceVisible] = useState(false);
    const [formInitialValues, setFormInitialValues] = useState<EditLiabilityFormInputs>();
    const [typeServices, setTypeServices] = useState<SelectableTypeServiceOptions[]>([]);
    const [editInstance, setEditInstance] = useState<LiabilityInstance>(LiabilityInstance.PROPERTY_LIABILITY);
    const { property, setProperty } = useContext(PropertyContext);
    const [formInstance] = Form.useForm();

    /* Listeners */
    useEffect(() => {
        fetchLiabilities();
        fetchTypeServices();
    }, []);

    /* View Events */

    const onClickEdit = (liabilityId: number) => {
        fillEditForm(liabilityId).catch(() => {
            // TODO: Show error message
        });
    };

    const fetchLiabilities = () => {
        if (!property.id) return;
        liabilitiesService
            .getAllLiabilitiesByPropertyId(property.id)
            .then((allLiabilities) => {
                setProperty((prevstate) => {
                    return { ...prevstate, services: allLiabilities };
                });
            })
            .catch(() => {
                // TODO: Show erro message
            });
    };

    const fillEditForm = async (liabilityId: number) => {
        const liability = await liabilitiesService.getLiability(liabilityId);

        if (!liability.liabilityType || !liability.id || !liability.type?.id) {
            return;
        }

        setEditInstance(liability.liabilityType);

        const initialValues = {
            id: liability.id,
            name: liability.name,
            uniqueOrRecurrent: 'recurrent',
            payerType: liability.payerType,
            paymentTerm: liability.paymentTerm ?? LiabilityPaymentTerm.CURRENT_MONTH,
            serviceTypeId: liability.type.id,
            showInReceipt: liability.showInReceipt ?? false,
            showInSettlement: liability.showInSettlement ?? false,
            defaultAmount: liability.defaultAmount,
            observation: liability.observation,
            receiptOperation: liability.receiptOperation ?? AritmeticOperation.SUBSTRACT,
            settlementOperation: liability.settlementOperation ?? AritmeticOperation.SUBSTRACT,
        };

        if (liability.liabilityType == LiabilityInstance.CONTRACT_LIABILITY) {
            const contractLiability = liability as CreateContractLiability;
            const fromDate = moment().set({ year: contractLiability.fromYear, month: contractLiability.fromMonth - 1 });
            const toDate = moment().set({ year: contractLiability.toYear, month: contractLiability.toMonth - 1 });
            setFormInitialValues({
                ...initialValues,
                indeterminateTime: false,
                uniqueOrRecurrent: 'recurrent',
                monthDuration: [fromDate, toDate],
            });
        } else if (liability.liabilityType == LiabilityInstance.PROPERTY_LIABILITY) {
            setFormInitialValues({
                ...initialValues,
                indeterminateTime: true,
                uniqueOrRecurrent: 'recurrent',
            });
        } else if (liability.liabilityType == LiabilityInstance.FOLLOW_UP_LIABILITY) {
            setFormInitialValues({
                ...initialValues,
                uniqueOrRecurrent: 'unique',
            });
        }

        setIsEditSingleServiceVisible(true);
    };

    const onCancel = () => {
        cleanFormAndHide();
        if (executeOnCancelProp) executeOnCancelProp();
    };

    const onCreateEditBack = () => {
        cleanFormAndHide();
    };

    const cleanFormAndHide = () => {
        formInstance.resetFields();
        setIsCreateNewServiceVisible(false);
        setIsEditSingleServiceVisible(false);
    };

    const onClickDelete = (liabilityId: number) => {
        liabilitiesService
            .deleteLiability(liabilityId)
            .then((successful) => {
                if (successful) {
                    setProperty((prevValue) => {
                        const filteredPropLiabilities = prevValue.services?.filter((s) => s.id != liabilityId);
                        return { ...prevValue, services: filteredPropLiabilities };
                    });

                    setTypeServices((prevValue) => {
                        console.log(prevValue);
                        return prevValue.filter((o) => o.id != liabilityId);
                    });
                } else {
                    // TODO: Show error message
                }
            })
            .catch(() => {
                // TODO: Show error message
            });
    };

    const onClickCreate = () => {
        setIsCreateNewServiceVisible(true);
    };

    /* Private Methods */
    const fetchTypeServices = () => {
        typeServicesService.getAllTypeServices().then((dtos) => {
            setTypeServices(dtos.map(mapDtoToSelectableTypeServicesOptions));
        });
    };

    const mapDtoToSelectableTypeServicesOptions = (dto: TypeServiceDto): SelectableTypeServiceOptions => {
        return {
            id: dto.id,
            typeServiceTitle: dto.title,
        };
    };

    const onFormSubmit = () => {
        formInstance.submit();
        setIsEditSingleServiceVisible(false);
        setIsCreateNewServiceVisible(false);
    };

    const mapDtoToItemView = (dto: LiabilityDto): LiabilityItemView => {
        let subtitle;
        if (dto.contractId) {
            subtitle = `Aplica desde ${dto.fromMonth}/${dto.fromYear} hasta ${dto.toMonth}/${dto.toYear}`;
        }
        if (dto.followUpId) {
            subtitle = `Aplica solo a este mes`;
        }
        return { id: dto.id ?? 0, title: dto.name, subtitle };
    };

    // Return state and events
    return {
        isEditSingleServiceVisible,
        form: formInstance,
        editInstance,
        liabilities: property.services?.map(mapDtoToItemView),
        typeServiceList: typeServices,
        isCreateNewServiceVisible,
        formInitialValues,
        propertyId: property.id ?? 0,
        onClickEdit,
        onClickDelete,
        onFormSubmit,
        onClickCreate,
        onCancel,
        onCreateEditBack,
    };
};
