import React, { useState, useEffect } from 'react';
import 'components/select-or-create-item/select-or-create-item.scss';
import { Form, FormItemProps, Select } from 'antd';
import { Divider, Typography } from 'antd';
import { LabelValue } from 'components/view-models';
import { fixSelectOnScroll } from 'tools/view-hooks/fix-autocomplete';

export interface SelectOrCreateItemProps extends FormItemProps {
    selectPlaceholder?: string;
    selectOptions: LabelValue[];
    selectMode?: 'multiple' | 'tags';
    createFormItemName: string;
    optionFilterProp: string;
    createComponent: React.ReactNode;
    createTitle: string;
    labelItem?: string;
    requiredForm: boolean;
    onClickSelect?: () => void;
    onBlurSelect?: () => void;
    isOpen?: boolean;
    rulesComponent?: any;
}

const SelectOrCreateItem: React.FC<SelectOrCreateItemProps> = (props) => {
    // State
    const [isCreateVisible, setIsCreateVisible] = useState(false);
    const [allowSearch, setAllowSearch] = useState(false);

    const onSelectChange = (value: number | number[]) => {
        if (Array.isArray(value)) {
            setIsCreateVisible(value.includes(-1));
            return;
        }
        setIsCreateVisible(value == -1);
    };

    useEffect(() => {
        if (props.selectOptions.length > 3) {
            setAllowSearch(true);
        }
    });

    return (
        <div className={'select-or-create-item'}>
            <Form.Item label={props.label} required={props.requiredForm} {...props}>
                <Select
                    allowClear
                    showSearch={allowSearch}
                    placeholder={props.selectPlaceholder}
                    options={[{ label: 'Crear Nuevo', value: -1 }, ...props.selectOptions]}
                    optionFilterProp={props.optionFilterProp}
                    onChange={onSelectChange}
                    mode={props.selectMode}
                    open={props.isOpen}
                    onClick={props.onClickSelect}
                    onBlur={props.onBlurSelect}
                    getPopupContainer={fixSelectOnScroll}
                />
            </Form.Item>
            {isCreateVisible && (
                <div>
                    <Divider />
                    <Form.List name={props.createFormItemName} rules={props.rulesComponent}>
                        {(fields, actions, { errors }) => (
                            <div>
                                <Typography.Title level={5}>{props.createTitle}</Typography.Title>
                                {props.createComponent}
                                {errors ? (
                                    <Typography.Text style={{ color: 'red' }}>{errors.toString()}</Typography.Text>
                                ) : null}
                            </div>
                        )}
                    </Form.List>
                </div>
            )}
        </div>
    );
};

export default SelectOrCreateItem;
