import { useLocalSession } from 'auth/helpers/session.hooks';
import { ApiLiabilitiesService } from 'services/liabilities/api-liabilities.service';
import { TypeServiceDto } from 'services/typeServices/type-services.dto';

export interface LiabilitiesService {
    editLiability(id: number, input: LiabilityDto): Promise<LiabilityDto>;
    getAllFollowUpLiabilities(id: number): Promise<FollowupLiabilityDto[]>;
    getAllLiabilitiesByPropertyId(id: number): Promise<LiabilityDto[]>;
    getLiability(id: number): Promise<LiabilityDto>;
    payLiabilities(followUpId: number, inputs: PayLiabilityInput[]): Promise<LiabilityPaymentDto[]>;
    deleteLiability(id: number): Promise<boolean>;
    addPropertyLiability(input: CreatePropertyLiability): Promise<LiabilityDto>;
    addContractLiability(input: CreateContractLiability): Promise<LiabilityDto>;
    addFollowUpLiability(input: CreateFollowUpLiability): Promise<FollowupLiabilityDto>;
}

export const useAPILiabilitiesService = (): LiabilitiesService => {
    const [getSession] = useLocalSession();
    const session = getSession();
    return new ApiLiabilitiesService(session);
};

export enum LiabilityPayerType {
    OWNER = 'owner',
    RENTER = 'renter',
}

export enum LiabilityInstance {
    CONTRACT_LIABILITY = 'ContractLiability',
    FOLLOW_UP_LIABILITY = 'FollowUpLiability',
    PROPERTY_LIABILITY = 'PropertyLiability',
}

export enum LiabilityPaymentTerm {
    CURRENT_MONTH = 'current-month',
    EXPIRED_MONTH = 'expired-month',
}

export enum AritmeticOperation {
    ADD = 'add',
    SUBSTRACT = 'substract',
}

export interface LiabilityDto {
    id?: number;
    type?: TypeServiceDto;
    name: string;
    payerType: LiabilityPayerType;
    paymentTerm?: LiabilityPaymentTerm;
    defaultAmount?: number;
    observation?: string;
    settlementOperation?: AritmeticOperation;
    receiptOperation?: AritmeticOperation;
    showInSettlement?: boolean;
    showInReceipt?: boolean;
    liabilityType?: LiabilityInstance;

    // ContractLiabilities
    contractId?: number;
    fromMonth?: number;
    toMonth?: number;
    fromYear?: number;
    toYear?: number;

    // FollowUpLiabilities
    followUpId?: number;

    // PropertyLiabilities
    propertyId?: number;

    // CalculatedValues
    needAmount?: boolean;
}

export interface FollowupLiabilityDto extends LiabilityDto {
    id: number;
    payment?: any;
    period: string;
}

export interface CreateContractLiability extends Omit<LiabilityDto, 'needAmount'> {
    contractId: number;
    fromMonth: number;
    toMonth: number;
    fromYear: number;
    toYear: number;
}

export interface CreatePropertyLiability extends LiabilityDto {
    propertyId: number;
}

export interface CreateFollowUpLiability extends LiabilityDto {
    followUpId: number;
}

export interface LiabilityPaymentDto {
    id: number;
    amount?: number;
    isPaid: boolean;
    liabilityId: number;
}

export interface PayLiabilityInput {
    liabilityId: number;
    isPaid?: boolean;
    amount?: number;
}

export interface PayLiabilityFormInput {
    isServicePaid?: boolean;
    amount?: number;
}
