import { FormInstance } from 'antd';
import {
    CreateEditLiabilityController,
    EditLiabilityFormInputs,
    LiabilityFormInputs,
    SelectableTypeServiceOptions,
} from 'fragments/property-card/create-edit-liability/interfaces';
import { PropertyContext } from 'fragments/property-card/property.context';
import moment, { Moment } from 'moment';
import { useContext, useEffect, useState } from 'react';
import { CompleteFollowUpDto } from 'services/followUps/followUp.dto';
import {
    AritmeticOperation,
    CreateContractLiability,
    CreateFollowUpLiability,
    CreatePropertyLiability,
    LiabilitiesService,
    LiabilityDto,
    LiabilityPayerType,
    LiabilityPaymentTerm,
    useAPILiabilitiesService,
} from 'services/liabilities/liabilities.service';
import { TypeServiceDto } from 'services/typeServices/type-services.dto';
import { TypeServicesService, useAPITypeServicesService } from 'services/typeServices/type-services.service';

export const useCreateEditLiabilityController = (
    form: FormInstance<LiabilityFormInputs>,
    editValues?: EditLiabilityFormInputs,
    createForPropertyId?: number,
    createForFollowUp?: CompleteFollowUpDto,
    onLiabilityAdded?: (liabilityAdded: LiabilityDto) => void,
    typeServicesService: TypeServicesService = useAPITypeServicesService(),
    liabilitiesService: LiabilitiesService = useAPILiabilitiesService(),
): CreateEditLiabilityController => {
    const { property, setProperty } = useContext(PropertyContext);

    const [typeServices, setTypeServices] = useState<SelectableTypeServiceOptions[]>([]);
    const [isIndeterminateTimeChecked, setIsIndeterminateTimeChecked] = useState(false);
    const [isLiabilityUnique, setIsLiabilityUnique] = useState(true);

    const [isRangeTimeDisabled, setIsRangeTimeDisabled] = useState(false);
    const [isPaymentTermDisabled, setIsPaymentTermDisabled] = useState(false);

    const [formInitialValues, setFormInitialValues] = useState<LiabilityFormInputs>({
        uniqueOrRecurrent: createForPropertyId ? 'recurrent' : 'unique',
        serviceTypeId: typeServices[0]?.id ?? 1,
        name: '',
        payerType: LiabilityPayerType.RENTER,
        showInReceipt: false,
        receiptOperation: AritmeticOperation.SUBSTRACT,
        showInSettlement: false,
        settlementOperation: AritmeticOperation.SUBSTRACT,
        paymentTerm: LiabilityPaymentTerm.CURRENT_MONTH,
    });

    useEffect(() => {
        if (createForPropertyId) {
            setIsIndeterminateTimeChecked(true);
            setIsRangeTimeDisabled(true);
            setIsLiabilityUnique(false);
        }

        if (editValues) {
            setIsIndeterminateTimeChecked(editValues.indeterminateTime == true);
            setIsLiabilityUnique(editValues.uniqueOrRecurrent == 'unique');

            setIsRangeTimeDisabled(true);
            setIsPaymentTermDisabled(true);
        }

        if (editValues) {
            setFormInitialValues(editValues);
        }
        fetchTypeServices();
    }, []);

    useEffect(() => {
        if (editValues) return;
        setIsRangeTimeDisabled(isIndeterminateTimeChecked);
    }, [isIndeterminateTimeChecked]);

    useEffect(() => {
        if (!formInitialValues) return;
        form.resetFields();
    }, [formInitialValues]);

    const fetchTypeServices = () => {
        typeServicesService.getAllTypeServices().then((dtos) => {
            setTypeServices(dtos.map(mapDtoToSelectableTypeServicesOptions));
        });
    };

    const onFormSubmit = (input: LiabilityFormInputs) => {
        if (editValues) {
            editLiability(input);
            return;
        }

        const isFollowUpLiability = isLiabilityUnique;
        const isContractLiability = !isLiabilityUnique && !isIndeterminateTimeChecked;
        const isPropertyLiability = !isLiabilityUnique && isIndeterminateTimeChecked;

        const propertyId = createForPropertyId || createForFollowUp?.contract?.property?.id;

        if (isIndeterminateTimeChecked) {
            input.monthDuration = undefined;
        }

        if (isContractLiability) {
            if (!createForFollowUp?.contract?.id) return;

            if (!input.monthDuration || input.monthDuration.length != 2) {
                // TODO: Show error message
            }

            const monthDuration = input.monthDuration as [Moment, Moment];
            createContractLiability({
                ...input,
                fromMonth: moment(monthDuration[0]).month() + 1,
                toMonth: moment(monthDuration[1]).month() + 1,
                fromYear: moment(monthDuration[0]).year(),
                toYear: moment(monthDuration[1]).year(),
                contractId: createForFollowUp?.contract?.id ?? 0,
            });
            return;
        }

        if (isPropertyLiability) {
            if (!propertyId) return;
            createPropertyLiability({
                ...input,
                propertyId,
            });
            return;
        }

        if (isFollowUpLiability) {
            if (!createForFollowUp?.id) return;

            createFollowUpLiability({
                ...input,
                followUpId: createForFollowUp?.id as number,
            });
            return;
        }
    };

    const createContractLiability = (reqBody: CreateContractLiability) => {
        liabilitiesService
            .addContractLiability(reqBody)
            .then((data) => {
                if (onLiabilityAdded) {
                    onLiabilityAdded(data);
                }
            })
            .catch(() => {
                //TODO: Show error message
            });
    };

    const editLiability = (input: LiabilityFormInputs) => {
        if (!editValues) return;
        liabilitiesService
            .editLiability(editValues.id, { ...input })
            .then((editedLiability) => {
                const newPropertyServices = property.services?.map((oldLiability) => {
                    if (oldLiability.id == editedLiability.id) {
                        return editedLiability as LiabilityDto;
                    }
                    return oldLiability;
                });

                setProperty((prevValue) => {
                    return {
                        ...prevValue,
                        services: newPropertyServices,
                    };
                });
            })
            .catch(() => {
                // TODO: Show error message
            });
    };

    const createPropertyLiability = (reqBody: CreatePropertyLiability) => {
        const allLiabilities = property.services;
        liabilitiesService
            .addPropertyLiability(reqBody)
            .then((data) => {
                allLiabilities?.push(data);
                setProperty((prevValue) => {
                    return {
                        ...prevValue,
                        services: allLiabilities,
                    };
                });
                if (onLiabilityAdded) onLiabilityAdded(data);
            })
            .catch(() => {
                // TODO: Show error message
            });
    };

    const createFollowUpLiability = (reqBody: CreateFollowUpLiability) => {
        liabilitiesService
            .addFollowUpLiability(reqBody)
            .then((data) => {
                if (onLiabilityAdded) onLiabilityAdded(data);
            })
            .catch(() => {
                // TODO: Show error message
            });
    };

    const handleUniqueOrRecurrentChange = (value: string) => {
        setIsLiabilityUnique(value === 'unique');
    };

    const handleIndeterminateTimeChange = (isChecked: boolean) => {
        setIsIndeterminateTimeChecked(isChecked);
    };

    const mapDtoToSelectableTypeServicesOptions = (dto: TypeServiceDto): SelectableTypeServiceOptions => {
        return {
            id: dto.id,
            typeServiceTitle: dto.title,
        };
    };

    return {
        formInitialValues,
        typeServiceList: typeServices,
        isRangeTimeDisabled,
        isPaymentTermDisabled,
        isUniqueOrRecurrentDisabled: createForPropertyId != undefined || editValues != undefined,
        isIndeterminateTimeDisabled: createForPropertyId != undefined || editValues != undefined,
        showRecurrentInputs: !isLiabilityUnique,
        isIndeterminateTimeChecked,
        onFormSubmit,
        handleIndeterminateTimeChange,
        handleUniqueOrRecurrentChange,
    };
};
