import { plainToClass } from 'class-transformer';
import axios from 'axios';
import { Session } from 'auth/models/session';
import { PersonDto } from 'services/persons/person.dto';
import { CompletePersonDto } from 'services/persons/person-complete.dto';
import { PersonsService } from './persons.service';

export class ApiPersonsService implements PersonsService {
    constructor(private session: Session) {}
    async getAllPersons(): Promise<CompletePersonDto[]> {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/persons/visible`, {
            headers: { Authorization: `Bearer ${this.session.getToken()}` },
        });
        return response.data.map((item: any) => plainToClass(CompletePersonDto, item));
    }
    async getAllPersonsVisible(): Promise<CompletePersonDto[]> {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/persons/visible`, {
            headers: { Authorization: `Bearer ${this.session.getToken()}` },
        });
        return response.data.map((item: any) => plainToClass(CompletePersonDto, item));
    }
    async getOnePerson(id: number): Promise<CompletePersonDto> {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/persons/${id}`, {
            headers: { Authorization: `Bearer ${this.session.getToken()}` },
        });
        return plainToClass(CompletePersonDto, response.data);
    }

    async addPerson(input: PersonDto): Promise<CompletePersonDto> {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/persons`, input, {
            headers: { Authorization: `Bearer ${this.session.getToken()}` },
        });
        return plainToClass(CompletePersonDto, response.data);
    }

    async searchPersons(input: { param: string }): Promise<CompletePersonDto[]> {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/persons/search`, input, {
            headers: { Authorization: `Bearer ${this.session.getToken()}` },
        });
        return response.data.map((item: any) => plainToClass(CompletePersonDto, item));
    }

    async updatePerson(id: number, input: PersonDto): Promise<CompletePersonDto> {
        const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/persons/${id}`, input, {
            headers: { Authorization: `Bearer ${this.session.getToken()}` },
        });
        return plainToClass(CompletePersonDto, response.data);
    }

    async deletePerson(id: number): Promise<void> {
        return axios.delete(`${process.env.REACT_APP_API_BASE_URL}/persons/${id}`, {
            headers: { Authorization: `Bearer ${this.session.getToken()}` },
        });
    }
}
