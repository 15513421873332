import axios from 'axios';
import { plainToClass } from 'class-transformer';
import { Session } from 'auth/models/session';
import { SettingsService } from './settings.service';
import { ProvinceDto, RealEstateDto, RealEstateInputDto } from './settings.dto';

export class ApiSettingsService implements SettingsService {
    constructor(private session: Session) {}

    async editSettings(inputs: RealEstateInputDto): Promise<RealEstateDto> {
        const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/realEstate`, inputs, {
            headers: { Authorization: `Bearer ${this.session.getToken()}` },
        });
        return plainToClass(RealEstateDto, response.data);
    }

    async getAllProvinces(): Promise<ProvinceDto[]> {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/configuration/provinces`, {
            headers: { Authorization: `Bearer ${this.session.getToken()}` },
        });
        return response.data;
    }

    async getOneProvince(provinceId: number): Promise<ProvinceDto> {
        const response = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/configuration/provinces/${provinceId}`,
            {
                headers: { Authorization: `Bearer ${this.session.getToken()}` },
            },
        );
        return response.data as ProvinceDto;
    }

    async getRealEstate(): Promise<RealEstateDto> {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/realEstate`, {
            headers: { Authorization: `Bearer ${this.session.getToken()}` },
        });
        return response.data;
    }
}
