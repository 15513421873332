import moment from 'moment';

export interface DateHelper {
    getReadableDate(date: Date): string;
    getReadableDateFromUnix(date: number): string;
    getReadableDateFromFormat(date: string, format: string): string;
}

export const useDateHelper = (): DateHelper => {
    const getReadableDate = (date: Date): string => {
        return moment(date).format('DD/MM/YYYY');
    };

    const getReadableDateFromUnix = (date: number): string => {
        return moment.unix(date).format('DD/MM/YYYY');
    };

    const getReadableDateFromFormat = (date: string, format: string): string => {
        return moment(date, format).format('DD/MM/YYYY');
    };

    return { getReadableDate, getReadableDateFromUnix, getReadableDateFromFormat };
};
