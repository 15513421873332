//system imports
import { plainToClass } from 'class-transformer';
//third party imports
import axios from 'axios';
//custom
import { PropertiesService } from './properties.service';
import { Session } from 'auth/models/session';
import { PropertyDto } from 'services/properties/property.dto';
import { CompletePropertyDto } from 'services/properties/property-complete.dto';

export class ApiPropertieService implements PropertiesService {
    constructor(private session: Session) {}
    async getAllProperties(): Promise<CompletePropertyDto[]> {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/properties`, {
            headers: { Authorization: `Bearer ${this.session.getToken()}` },
        });
        return response.data.map((item: any) => plainToClass(CompletePropertyDto, item));
    }
    async getOneProperty(propertyId: number): Promise<CompletePropertyDto> {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/properties/${propertyId}`, {
            headers: { Authorization: `Bearer ${this.session.getToken()}` },
        });
        return plainToClass(CompletePropertyDto, response.data);
    }
    async getOnePropertyByContractId(contractId: number): Promise<CompletePropertyDto> {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/properties/contract/${contractId}`, {
            headers: { Authorization: `Bearer ${this.session.getToken()}` },
        });
        return plainToClass(CompletePropertyDto, response.data);
    }

    async addProperty(input: PropertyDto): Promise<CompletePropertyDto> {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/properties`, input, {
            headers: { Authorization: `Bearer ${this.session.getToken()}` },
        });
        return plainToClass(PropertyDto, response.data);
    }

    async searchProperty(input: { param: string }): Promise<CompletePropertyDto[]> {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/properties/search`, input, {
            headers: { Authorization: `Bearer ${this.session.getToken()}` },
        });
        return response.data.map((item: any) => plainToClass(CompletePropertyDto, item));
    }

    async deleteProperty(id: number): Promise<void> {
        return axios.delete(`${process.env.REACT_APP_API_BASE_URL}/properties/${id}`, {
            headers: { Authorization: `Bearer ${this.session.getToken()}` },
        });
    }
    async updateProperty(inputs: PropertyDto, id: number): Promise<CompletePropertyDto> {
        const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/properties/${id}`, inputs, {
            headers: { Authorization: `Bearer ${this.session.getToken()}` },
        });
        return response.data as CompletePropertyDto;
    }
}
