import React, { useState } from 'react';

import { EditOutlined } from '@ant-design/icons';
import { Button, Col, Descriptions, Row, Space, Typography } from 'antd';

import 'fragments/contract-card/contract-card.scss';
import { EditContractFragment } from './edit-contract/edit-contract.fragment';
import { useContractCardController } from './contract-card.controller';
import { ContractCardDetailsFragment } from './contract-details/contract-details.fragment';
import { ContractContext } from './contract-card.component';
import { ContractsObservationsFragment } from './contracts-observations/contract-observations.fragment';
import { ContractCardFragmentProps } from 'fragments/contract-card/interfaces';
import { RescindContractFragment } from 'fragments/rescind-contract/rescind-contract.fragment';
import DeleteModalButton from 'components/delete-modal-button/delete-modal-button.component';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { ModalInformationInterestFragment } from './modal-information-interest/modal-information-interest.fragment';

export const ContractCardFragment: React.FC<ContractCardFragmentProps> = (props) => {
    const { useController = useContractCardController } = props;
    const controller = useController(props.contractDto);
    const { translate } = useTranslator();

    const [isRescindContractVisible, setIsRescindContractVisible] = useState(false);
    const onRescindContractPressed = () => setIsRescindContractVisible(true);
    const onRescindContractCanceled = () => setIsRescindContractVisible(false);

    return (
        <ContractContext.Provider value={{ contract: controller.contract, setContract: controller.setContract }}>
            <div className={'contract-card-details'} key={controller.id}>
                <RescindContractFragment
                    contractId={Number(props.contractDto.id)}
                    isVisible={isRescindContractVisible}
                    onCancel={onRescindContractCanceled}
                    onFinish={() => {
                        setIsRescindContractVisible(false);
                    }}
                />
                <EditContractFragment
                    isVisible={controller.isEditModalVisible}
                    onCancel={controller.onEditCanceled}
                    onFinish={controller.onFinish}
                />
                <ModalInformationInterestFragment
                    isVisible={controller.isInformationModal}
                    onCancel={controller.onInfromationModalCanceled}
                    onFinish={controller.onCancelInformationModal}
                    onContractInformationAdded={controller.onInfromationModalCanceled}
                />
                <Space align={'start'}>
                    <Typography.Title level={5}>{controller.title}</Typography.Title>
                    <EditOutlined className={'link-icon'} onClick={controller.onEditContractPressed} />
                </Space>
                <Row gutter={10}>
                    <Col xs={24} lg={7}>
                        <Descriptions className={'contract-card-header'} column={1} labelStyle={{ fontWeight: 'bold' }}>
                            <Descriptions.Item>
                                <Typography.Text>{controller.subtitle}</Typography.Text>
                            </Descriptions.Item>
                            <Descriptions.Item label={translate({ key: 'persons.owner' })}>
                                <Typography.Text>{controller.ownerName}</Typography.Text>
                            </Descriptions.Item>
                            <Descriptions.Item label={translate({ key: 'persons.renter' })}>
                                <Typography.Text>{controller.renterName}</Typography.Text>
                            </Descriptions.Item>
                            {controller.contractIsCurrent || controller.contract.dateResition ? (
                                <div className={'contracts-links'}>
                                    <Descriptions.Item
                                        className={
                                            controller.contract.observationRescind || controller.contract.dateResition
                                                ? 'hidden-rescind-button'
                                                : 'rescind-button'
                                        }
                                    >
                                        <Button
                                            onClick={onRescindContractPressed}
                                            className={
                                                controller.contract.dateResition ? 'hide-rescind-btn' : 'rescind-btn'
                                            }
                                            type="link"
                                        >
                                            {translate({ key: 'contracts.rescind-contract' })}{' '}
                                        </Button>
                                    </Descriptions.Item>
                                </div>
                            ) : (
                                <div className={'contracts-links'}>
                                    <Descriptions.Item>
                                        <div
                                            className={
                                                !controller.contractIsCurrent ? 'observations' : 'hidden-observations'
                                            }
                                        >
                                            <Typography.Title level={5}>Contrato finalizado</Typography.Title>
                                            <Typography.Text>
                                                {`${translate({
                                                    key: 'contracts.finalization-date',
                                                })}: ${
                                                    controller.finishDate.getMonth() + 1
                                                }/${controller.finishDate.getFullYear()}`}
                                            </Typography.Text>
                                        </div>
                                    </Descriptions.Item>
                                </div>
                            )}

                            <div className={'contracts-links'}>
                                <Descriptions.Item>
                                    <div
                                        className={
                                            controller.contract.dateResition ? 'observations' : 'hidden-observations'
                                        }
                                    >
                                        <Typography.Title level={5}>Resolución anticipada</Typography.Title>
                                        <Typography.Text>
                                            {`${translate({
                                                key: 'contracts.rescition-date',
                                            })}: ${
                                                controller.dateResition.getMonth() + 2
                                            }/${controller.dateResition.getFullYear()}`}
                                        </Typography.Text>
                                        <br />
                                        <Typography.Text>
                                            {translate({ key: 'contracts.observations' })}:{' '}
                                        </Typography.Text>
                                        <Typography.Text>{controller.contract.observationRescind}</Typography.Text>
                                    </div>
                                </Descriptions.Item>
                            </div>
                            <div className={'contracts-links'}>
                                <Descriptions.Item>
                                    <Button
                                        onClick={controller.onInformationModalPressed}
                                        className={'rescind-btn'}
                                        type="link"
                                    >
                                        {translate({ key: 'contracts.information' })}{' '}
                                    </Button>
                                </Descriptions.Item>
                            </div>
                        </Descriptions>
                    </Col>
                    <Col xs={24} lg={7}>
                        <ContractCardDetailsFragment />
                    </Col>
                    <Col xs={24} lg={8}>
                        <ContractsObservationsFragment contractId={controller.id} />
                    </Col>
                    <Col xs={24} lg={2}>
                        <DeleteModalButton
                            id={controller.id}
                            onClickDelete={props.onDelete}
                            title={`${translate({ key: 'contracts.sure-delete-contract' })} ${controller.title}?`}
                        />
                    </Col>
                </Row>
            </div>
        </ContractContext.Provider>
    );
};
