import { useState } from 'react';
import { plainToClass } from 'class-transformer';
import { Form } from 'antd';
import { useMessenger } from 'tools/view-hooks/messenger-hook';
import { GenerateFreeReceiptController } from './interfaces';
import { CreatePdfFreeReceiptDto } from 'services/pdfFreeReceipt/complete-pdf-free-receipt.dto';
import { useAPIPdfFreeReceiptService } from 'services/pdfFreeReceipt/pdf-free-receipt.service';
import { PdfFreeReceiptDto } from 'services/pdfFreeReceipt/pdf-free-receipt.dto';

export const useGenerateFreeReceiptController = (
    onGeneratePdfFreeReceiptAdded: (dto: PdfFreeReceiptDto) => void,
    generatePdfFreeReceipt = useAPIPdfFreeReceiptService(),
    messenger = useMessenger(),
): GenerateFreeReceiptController => {
    /* State */
    const [isLoading, setIsLoading] = useState(false);
    const [formInstance] = Form.useForm();

    /* Listeners */

    /* View Events */
    const onFormSubmit = (inputs: any) => {
        setIsLoading(true);
        const dto = plainToClass(CreatePdfFreeReceiptDto, inputs);
        generatePdfFreeReceipt
            .generatePdfFreeReceipt(dto)
            .then((dto) => {
                formInstance.resetFields();
                onGeneratePdfFreeReceiptAdded(dto);
                window.open(dto.url);
            })
            .catch(() => {
                messenger.showErrorMessage({ key: 'add-persons.default-error' });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    /* Private Methods */

    // Return state and events
    return {
        form: formInstance,
        isLoading,
        onFormSubmit,
    };
};
