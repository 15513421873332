import React, { useState } from 'react';
import { Button, Divider, Form, Input, InputNumber, Modal, Typography, Upload, Row, Col } from 'antd';
import { DatePicker } from 'antd';
import 'fragments/add-contract/add-contract.scss';
import { useLocalSession } from 'auth/helpers/session.hooks';
import { AddContractFragmentProps } from 'fragments/add-contract/interfaces';
import { useAddContractController } from 'fragments/add-contract/add-contract.controller';
import StepperForm from 'components/stepper-form/stepper-form.component';
import SelectOrCreateItem from 'components/select-or-create-item/select-or-create-item.component';
import NewOrOldContract from 'components/new-or-old-contract/new-or-old-contract.component';
import CreatePropertyFields from 'components/create-property-fields/create-property-fields.component';
import CreatePersonsFields from 'components/create-persons-fields/create-persons-fields.component';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import InterestOwed from 'components/interest-owed/interest-owed.component';
import 'moment/locale/es';
import CurrencyInput from 'components/currency-input/currency-input.component';
import { fixSelectOnScroll } from 'tools/view-hooks/fix-autocomplete';

export const AddContractFragment: React.FC<AddContractFragmentProps> = (props) => {
    const { useController = useAddContractController } = props;
    const controller = useController(props.onContractAdded);
    const [getSession] = useLocalSession();
    const session = getSession();
    const { translate } = useTranslator();

    const Step1Component = (
        <>
            <Typography.Title className="title-data" level={4}>
                {translate({ key: 'contracts.title-data' })}
            </Typography.Title>
            <SelectOrCreateItem
                createFormItemName={'newProperty'}
                name={'propertyId'}
                selectOptions={controller.propertiesOptions}
                optionFilterProp={'label'}
                createComponent={
                    <CreatePropertyFields
                        personsOptions={controller.personsOptions}
                        provincesOptions={controller.provincesOptions}
                    />
                }
                createTitle={translate({ key: 'contracts.property-data' })}
                label={translate({ key: 'contracts.properties' })}
                requiredForm={true}
                rules={[
                    { required: true },
                    {
                        validator: controller.validateProperty,
                    },
                ]}
                rulesComponent={[
                    {
                        validator: (_: any, value: any, callback: any) => {
                            if (
                                !value ||
                                !value.street ||
                                !value.district ||
                                !value.provinceId ||
                                !value.city ||
                                !value.owners ||
                                (value.owners === -1 &&
                                    (!value.newOwner ||
                                        !value.newOwner.name ||
                                        !value.newOwner.lastName ||
                                        !value.newOwner.numberDoc))
                            ) {
                                callback('Complete los datos requeridos');
                                return;
                            }
                            callback();
                        },
                    },
                ]}
            />
            <SelectOrCreateItem
                name={'rentersId'}
                createFormItemName={'newRenter'}
                selectOptions={controller.personsOptions}
                selectMode={'multiple'}
                optionFilterProp={'label'}
                createComponent={
                    <CreatePersonsFields showOptionalFields={false} provincesOptions={controller.provincesOptions} />
                }
                createTitle={translate({ key: 'contracts.renter-data' })}
                onClickSelect={controller.onClickSelect}
                isOpen={controller.selectIsOpen}
                onBlurSelect={controller.onBlurSelect}
                label={translate({ key: 'contracts.renters' })}
                requiredForm={true}
                rules={[
                    { required: true },
                    {
                        validator: controller.validateRenters,
                    },
                ]}
                rulesComponent={[
                    {
                        validator: (_: any, value: any, callback: any) => {
                            if (!value || !value.name || !value.lastName || !value.numberDoc) {
                                callback('Complete los datos requeridos');
                                return;
                            }
                            callback();
                        },
                    },
                ]}
            />
        </>
    );

    const Step2Component = (
        <>
            <Row gutter={10}>
                <Col xs={{ span: 24 }}>
                    <Typography.Title className="title-data" level={4}>
                        {translate({ key: 'contracts.title-details' })}
                    </Typography.Title>
                </Col>
                <Col xs={{ span: 24 }} xl={{ span: 12 }}>
                    <Form.Item
                        name="date"
                        label={translate({ key: 'contracts.date' })}
                        labelAlign="left"
                        rules={[{ required: true }]}
                    >
                        <DatePicker
                            format={'DD/MM/YYYY'}
                            onChange={controller.initialDateChange}
                            getPopupContainer={fixSelectOnScroll}
                        />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} xl={{ span: 12 }}>
                    <Form.Item
                        name="durationMonths"
                        label={translate({ key: 'contracts.duration' })}
                        labelAlign="left"
                        rules={[{ required: true }]}
                    >
                        <InputNumber minLength={0} onChange={controller.durationChange} type="number" />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} xl={{ span: 12 }}>
                    <Form.Item>
                        <DatePicker
                            format={'DD/MM/YYYY'}
                            value={controller.finishDate}
                            placeholder={translate({ key: 'contracts.end-date' })}
                            disabled={true}
                            getPopupContainer={fixSelectOnScroll}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={10}>
                <Col xs={{ span: 24 }} xl={{ span: 12 }}>
                    <Form.Item
                        name="startPrice"
                        label={translate({ key: 'contracts.start-price' })}
                        labelAlign="left"
                        rules={[
                            {
                                required: true,
                                validator: (_, value, callback) => {
                                    if (!value || value === 0) {
                                        callback('Ingrese precio');
                                        return;
                                    }
                                    callback();
                                },
                            },
                        ]}
                    >
                        <CurrencyInput size={'large'} className="input-rent-amount" placeholder={''} />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} xl={{ span: 12 }}>
                    <Form.Item
                        name="percentageByAdministration"
                        label={translate({ key: 'contracts.percentage-per-administration' })}
                        labelAlign="left"
                        rules={[{ required: true }]}
                    >
                        <Input type='number' addonAfter="%" />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 24 }}>
                    <NewOrOldContract
                        validate={controller.validateUpdateFrequency}
                        durationMonths={controller.duration}
                        initialDate={controller.initialDate}
                    />
                </Col>
                <Col xs={{ span: 24 }} style={{ marginTop: '30px' }}>
                    <Upload
                        name="file"
                        action={`${process.env.REACT_APP_API_BASE_URL}/configuration/file`}
                        headers={{ Authorization: `Bearer ${session.getToken()}` }}
                        onChange={controller.handleChange}
                        multiple={false}
                    >
                        {controller.fileUrl != '' ? null : (
                            <Button type="link">{translate({ key: 'contracts.upload-file' })}</Button>
                        )}
                    </Upload>
                </Col>
            </Row>
            <Divider />
            <Row gutter={10}>
                <Col xs={{ span: 24 }}>
                    <SelectOrCreateItem
                        name={'guarantorsId'}
                        createFormItemName={'newGuarantor'}
                        selectOptions={controller.personsOptions}
                        selectMode={'multiple'}
                        optionFilterProp={'label'}
                        createComponent={
                            <CreatePersonsFields
                                showOptionalFields={false}
                                provincesOptions={controller.provincesOptions}
                            />
                        }
                        createTitle={translate({ key: 'contracts.guarantor-data' })}
                        label={translate({ key: 'contracts.guarantors' })}
                        requiredForm={false}
                        onClickSelect={controller.onClickSelect}
                        isOpen={controller.selectIsOpen}
                        onBlurSelect={controller.onBlurSelect}
                    />
                </Col>
            </Row>
        </>
    );

    const Step3Component = (
        <>
            <Typography.Title className="title-data" level={4}>
                {translate({ key: 'contracts.title-interest' })}
            </Typography.Title>
            <InterestOwed />
        </>
    );

    return (
        <div>
            <Modal
                visible={props.isVisible}
                onCancel={() => {
                    controller.onCancel();
                    if (props.onCancel) {
                        props.onCancel();
                    }
                }}
                footer={null}
                destroyOnClose={true}
                maskClosable={false}
            >
                <StepperForm
                    title={translate({ key: 'contracts.add-contract' })}
                    form={controller.form}
                    onFinish={controller.onFormSubmit}
                    isLoading={controller.isLoading}
                    className={'add-contract'}
                    layout={'vertical'}
                    initialValues={controller.initialValues}
                    steps={[
                        {
                            key: 0,
                            validateFields: [
                                'propertyId',
                                'rentersId',
                                'newRenter',
                                'newOwner',
                                'ownersId',
                                'newProperty',
                                'newRenter.lastName',
                                'name',
                                'typeDoc',
                                'numberDoc',
                            ],
                            title: translate({ key: 'contracts.general-data' }),
                            component: Step1Component,
                            isValid: true,
                        },
                        {
                            key: 1,
                            validateFields: [
                                'date',
                                'startPrice',
                                'durationMonths',
                                'percentageByAdministration',
                                'amountsActualizations',
                                'amountsActualizationsNew',
                                'updateFrequency',
                            ],
                            title: translate({ key: 'contracts.contract-detail' }),
                            component: Step2Component,
                            isValid: true,
                        },
                        {
                            key: 2,
                            validateFields: ['debtCollection', 'typeOfCharge', 'initialDay', 'expiredDay'],
                            title: translate({ key: 'contracts.expired' }),
                            component: Step3Component,
                            isValid: true,
                        },
                    ]}
                    previousButtonLabel={translate({ key: 'contracts.back' })}
                    nextButtonLabel={translate({ key: 'contracts.next' })}
                    lastButtonLabel={translate({ key: 'contracts.add' })}
                />
            </Modal>
        </div>
    );
};
