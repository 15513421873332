import { ApiFollowUpService } from './api-followUp.service';
import { useLocalSession } from 'auth/helpers/session.hooks';
import { CompleteFollowUpDto, PaginationDto, priceDto } from 'services/followUps/followUp.dto';

export interface FollowUpService {
    getAllFollowUps(input?: { offset: number; limit: number }): Promise<PaginationDto<CompleteFollowUpDto>>;
    updatePrice(input: priceDto): Promise<CompleteFollowUpDto>;
    applyFilters(inputQuery: any): Promise<PaginationDto<CompleteFollowUpDto>>;
    updateCharge(body: { charge: boolean }, id: number): Promise<CompleteFollowUpDto>;
}

export const useApiFollowUpService = (): FollowUpService => {
    const [getSession] = useLocalSession();
    const session = getSession();
    return new ApiFollowUpService(session);
};
