import { useState } from 'react';
import { FilterInicioController } from 'fragments/filter-inicio/interfaces';
import { Form } from 'antd';
import moment from 'moment';

export const useFilterInicioController = (
    offset: number,
    limit: number,
    onFilterChange: (filters: any) => void,
    onCleanFilter: () => void,
): FilterInicioController => {
    const [isLoading, setIsLoading] = useState(false);
    const [isFilterApplied, setIsFilterApplied] = useState(false);
    const [formInstance] = Form.useForm();
    const [filterSelected, setFilterSelected] = useState('');

    // const query = useQuery();

    /* Listeners */

    /* View Events */

    /* Private Methods */

    const onFormSubmit = (inputs: any) => {
        const firstDate = inputs.date && inputs.date[0] && moment(inputs.date[0]);
        const secondDate = inputs.date && inputs.date[1] && moment(inputs.date[1]);

        const dateStart = firstDate ? firstDate.month() + 1 + '/' + firstDate.year() : '';
        const dateFinish = secondDate ? secondDate.month() + 1 + '/' + secondDate.year() : '';

        const searchText = inputs.searchText ?? '';

        let status = [];
        if (Array.isArray(inputs.followUpStatus) && inputs.followUpStatus[0]) {
            status = inputs.followUpStatus;
        } else if (inputs.followUpStatus && inputs.followUpStatus !== '') {
            status.push(inputs.followUpStatus);
        } else {
            status = [];
        }

        const filters = {
            searchText: searchText,
            dateStart,
            dateFinish,
            status,
            servicesNotPaid: inputs.servicesNotPaid,
        };
        setIsFilterApplied(true);
        onFilterChange(filters);
    };

    const calculateInitialValues = (values: any) => {
        const date = [];
        const status = [];
        if (values.dateStart && values.dateFinish) {
            const firstDate = values.dateStart.split('/');
            const secondDate = values.dateFinish.split('/');

            date.push(moment([firstDate[1], firstDate[0] - 1]));
            date.push(moment([secondDate[1], secondDate[0] - 1]));
        }
        if (values.status && values.status !== '') {
            status.push(values.status.split(','));
        }

        return {
            date,
            followUpStatus: status[0],
            servicesNotPaid: values.servicesNotPaid === 'true' ? true : false,
        };
    };

    const filterSelectedFunction = (value: any) => {
        if (value.target.value && value.target.checked) {
            setFilterSelected(value.target.value);
        } else {
            setFilterSelected('');
        }
    };

    const cleanFilter = () => {
        setIsFilterApplied(false);
        formInstance.resetFields();
        onCleanFilter();
    };

    // Return state and events
    return {
        form: formInstance,
        isLoading,
        onFormSubmit,
        cleanFilter,
        filterSelectedFunction,
        filterSelected,
        initialValues: calculateInitialValues({}),
        isCleanFilterVisible: isFilterApplied,
    };
};
