import React from 'react';
import 'fragments/persons/persons.scss';
import { PersonsFragmentProps } from 'fragments/persons/interfaces';
import { usePersonsController } from 'fragments/persons/persons.controller';
import { Button, Table, Image, Row, Col } from 'antd';
import { AddPersonFragment } from 'fragments/add-person/add-person.fragment';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { PersonCardFragment } from 'fragments/person-card/person-card.fragment';
import { PersonCardFragmentProps } from 'fragments/person-card/interfaces';
import FlexLoader from 'components/flex-loader/flex-loader.component';
import iconNewPerson from 'assets/icons-svg/icons-persona-nueva.svg';
import InputSearch from 'components/input-search/input-search.component';

export const PersonsFragment: React.FC<PersonsFragmentProps> = (props) => {
    const { useController = usePersonsController } = props;
    const controller = useController();
    const { translate } = useTranslator();
    const { Column } = Table;

    // Render
    return (
        <div className="persons-fragment">
            <AddPersonFragment
                isVisible={controller.isAddPersonVisible}
                onCancel={controller.onAddPersonCanceled}
                onPersonAdded={controller.onPersonAdded}
            />
            {controller.isLoading ? (
                <FlexLoader />
            ) : (
                <div>
                    <Row gutter={10} justify="space-between">
                        <Col xs={24} lg={10}>
                            <Button type="primary" className={'add-person-btn'} onClick={controller.onAddPersonPressed}>
                                <Image preview={false} src={iconNewPerson} />
                                {translate({ key: 'persons.add-person' })}
                            </Button>
                        </Col>
                        <Col xs={24} lg={12} xl={8}>
                            <InputSearch onSearch={controller.onSearch} />
                        </Col>
                    </Row>
                    <Table pagination={false} dataSource={controller.personsCardProps} showHeader={false}>
                        <Column
                            render={(text: string, props: PersonCardFragmentProps) => <PersonCardFragment {...props} />}
                        />
                    </Table>
                </div>
            )}
        </div>
    );
};
