import React from 'react';

import { Button, DatePicker, Divider, Form, Input, InputNumber, Select, Space, Upload, Row, Col } from 'antd';

import 'fragments/contract-card/contract-details/edit-contracts-details/edit-contracts-details.scss';
import { useLocalSession } from 'auth/helpers/session.hooks';
import ModalForm from 'components/modal-form/modal-form.component';
import SelectOrCreateItem from 'components/select-or-create-item/select-or-create-item.component';
import DeleteModalButton from 'components/delete-modal-button/delete-modal-button.component';
import CreatePersonsFields from 'components/create-persons-fields/create-persons-fields.component';
import { EditContractsDetailsFragmentProps } from 'fragments/contract-card/contract-details/edit-contracts-details/interfaces';
import { useEditContractsDetailsController } from 'fragments/contract-card/contract-details/edit-contracts-details/edit-contracts-details.controller';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import CurrencyInput from 'components/currency-input/currency-input.component';
import DeleteTooltipButton from 'components/delete-tooltip-button/delete-tooltip-button.component';
import { fixSelectOnScroll } from 'tools/view-hooks/fix-autocomplete';

export const EditContractsDetailsFragment: React.FC<EditContractsDetailsFragmentProps> = (props) => {
    const { useController = useEditContractsDetailsController } = props;
    const controller = useController(props.onFinish);
    const [getSession] = useLocalSession();
    const session = getSession();
    const { translate } = useTranslator();
    const { Option } = Select;

    // Render
    return (
        <div className={'edit-contracts-details'}>
            <ModalForm
                className={'edit-contract'}
                onFinish={controller.onFormSubmit}
                form={controller.form}
                isvisible={props.isVisible}
                onCancel={props.onCancel}
                isLoading={controller.isLoading}
                layout={'vertical'}
                initialValues={controller.initialValues}
                title={translate({ key: 'contracts.edit-contract' })}
            >
                <Row gutter={10}>
                    <Col xs={{ span: 24 }} xl={{ span: 12 }}>
                        <Form.Item
                            name="date"
                            label={translate({ key: 'contracts.date' })}
                            style={{ display: 'flex', flexDirection: 'column' }}
                            labelAlign="left"
                            rules={[{ required: true }]}
                        >
                            <DatePicker
                                format={'DD/MM/YYYY'}
                                onChange={controller.initialDateChange}
                                getPopupContainer={fixSelectOnScroll}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} xl={{ span: 12 }}>
                        <Form.Item
                            name="durationMonths"
                            label={translate({ key: 'contracts.duration' })}
                            style={{ display: 'flex', flexDirection: 'column' }}
                            labelAlign="left"
                            rules={[{ required: true }]}
                        >
                            <InputNumber
                                minLength={0}
                                onChange={controller.durationChange}
                                disabled={true}
                                type="number"
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} xl={{ span: 12 }}>
                        <Form.Item>
                            <DatePicker
                                format={'DD/MM/YYYY'}
                                value={controller.finishDate}
                                placeholder={translate({ key: 'contracts.end-date' })}
                                disabled={true}
                                getPopupContainer={fixSelectOnScroll}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Divider />
                <Row gutter={10}>
                    <Col xs={{ span: 24 }} xl={{ span: 12 }}>
                        <Form.Item
                            name="startPrice"
                            label={translate({ key: 'contracts.start-price' })}
                            style={{ display: 'flex', flexDirection: 'column' }}
                            labelAlign="left"
                            rules={[{ required: true }]}
                        >
                            <CurrencyInput size={'large'} className="input-rent-amount" placeholder={''} />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} xl={{ span: 12 }}>
                        <Form.Item
                            name="percentageByAdministration"
                            label={translate({ key: 'contracts.percentage-per-administration' })}
                            style={{ display: 'flex', flexDirection: 'column' }}
                            labelAlign="left"
                            rules={[{ required: true }]}
                        >
                            <Input addonAfter="%" />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} xl={{ span: 12 }}>
                        <Form.Item
                            name="updateFrequency"
                            label={translate({ key: 'contracts.update-periodicity' })}
                            style={{ display: 'flex', flexDirection: 'column' }}
                            labelAlign="left"
                            rules={[{ required: true }, { validator: controller.validateUpdateFrequency }]}
                        >
                            <Select
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                size={'middle'}
                                allowClear
                                disabled={true}
                                getPopupContainer={fixSelectOnScroll}
                            >
                                {Array.from({ length: 12 }, (_, k) => {
                                    return (
                                        <Option value={k + 1} key={k + 1}>
                                            {k + 1} {translate({ key: 'contracts.months' })}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                {controller.isOldContract &&
                    controller.initialValues?.amountsActualizationsNew.map((actualization: any) => (
                        <Form.Item
                            key={actualization.id}
                            label={`${translate({ key: 'contracts.update' })} ${actualization.order + 1}`}
                        >
                            <CurrencyInput
                                size={'large'}
                                className="input-rent-amount"
                                placeholder={''}
                                defaultValue={actualization.price}
                                disabled={true}
                            />
                        </Form.Item>
                    ))}
                {!controller.isOldContract &&
                    controller.initialValues?.amountsActualizations.map((actualization: any) => (
                        <Form.Item
                            key={actualization.id}
                            label={`${translate({ key: 'contracts.update' })} ${actualization.order + 1}`}
                        >
                            <CurrencyInput
                                size={'large'}
                                className="input-rent-amount"
                                placeholder={''}
                                defaultValue={actualization.price}
                                disabled={true}
                            />
                        </Form.Item>
                    ))}
                {controller.fileUrl !== '' ? (
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Button className={'contract-pdf'} type="link" href={controller.initialValues?.file}>
                            {translate({ key: 'contracts.see-attached-pdf' })}
                        </Button>
                        <DeleteTooltipButton id={controller.fileId} onClickDelete={controller.onFileDeleted} />
                    </div>
                ) : (
                    <Upload
                        name="file"
                        action={`${process.env.REACT_APP_API_BASE_URL}/configuration/file`}
                        headers={{ Authorization: `Bearer ${session.getToken()}` }}
                        onChange={controller.handleChange}
                        multiple={false}
                    >
                        <Button type="link">{translate({ key: 'contracts.upload-file' })}</Button>
                    </Upload>
                )}

                <Divider />
                <SelectOrCreateItem
                    name={'guarantorsId'}
                    createFormItemName={'newGuarantor'}
                    selectOptions={controller.personsOptions}
                    selectMode={'multiple'}
                    optionFilterProp={'label'}
                    onClickSelect={controller.onClickSelect}
                    isOpen={controller.selectIsOpen}
                    onBlurSelect={controller.onBlurSelect}
                    createComponent={<CreatePersonsFields provincesOptions={controller.provincesOptions} />}
                    createTitle={translate({ key: 'contracts.guarantor-data' })}
                    label={translate({ key: 'contracts.guarantors' })}
                    requiredForm={false}
                />
            </ModalForm>
        </div>
    );
};
