export class RealEstateDto {
    id?: number;
    name?: string;
    admin?: string;
    cuit?: number;
    attentionSchedule?: string;
    phone?: number;
    iconRealEstate?: string;
    webSite?: string;
    street?: string;
    number?: number;
    email?: string;
    floorDepartment?: string;
    district?: string;
    postalCode?: string;
    province?: ProvinceDto;
    city?: string;
}
export class RealEstateInputDto {
    name?: string;
    admin?: string;
    cuit?: number;
    attentionSchedule?: string;
    phone?: number;
    webSite?: string;
    street?: string;
    number?: number;
    floorDepartment?: string;
    district?: string;
    postalCode?: string;
    provinceId?: number;
    cityId?: number;
}

export class ProvinceDto {
    id?: number;
    name?: string;
}
