import React from 'react';
import 'fragments/settings/settings.scss';
import { SettingsFragmentProps } from 'fragments/settings/interfaces';
import { useSettingsController } from 'fragments/settings/settings.controller';
import {
    Button,
    Card,
    Divider,
    Form,
    Image,
    Input,
    Layout,
    Popconfirm,
    Select,
    Space,
    Typography,
    Upload,
    Row,
    Col,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import imageCam from 'assets/image@3x.png';
import iconAdd from 'assets/button-icon-add-24-px@3x.png';
import FlexLoader from '../../components/flex-loader/flex-loader.component';
import { QuestionCircleOutlined } from '@ant-design/icons';
import Meta from 'antd/lib/card/Meta';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { useLocalSession } from 'auth/helpers/session.hooks';
import { fixAutocomplete, fixSelectOnScroll } from 'tools/view-hooks/fix-autocomplete';

export const SettingsFragment: React.FC<SettingsFragmentProps> = (props) => {
    const { useController = useSettingsController } = props;
    const controller = useController();
    const [getSession] = useLocalSession();
    const session = getSession();
    const { Text } = Typography;
    const { translate } = useTranslator();

    const uploadButton = (
        <div>
            {controller.isImgLoading ? (
                <LoadingOutlined />
            ) : (
                <img alt={translate({ key: 'settings.real-estate-image' })} className="image-camera" src={imageCam} />
            )}
            {!controller.isImgLoading && <img alt="add-image" className="icon-add" src={iconAdd} />}
        </div>
    );

    // Render
    return (
        <Layout className="layout-settings-fragment-wrapper settings">
            {controller.isLoading ? (
                <FlexLoader />
            ) : (
                <Row>
                    <Col xs={24}>
                        <div className="first-container">
                            <Upload
                                name="image"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                action={`${process.env.REACT_APP_API_BASE_URL}/realEstate/image`}
                                onChange={controller.handleChange}
                                headers={{ Authorization: `Bearer ${session.getToken()}` }}
                            >
                                {!controller.imgUrl || controller.isImgLoading ? (
                                    uploadButton
                                ) : (
                                    <Image
                                        preview={false}
                                        src={controller.imgUrl}
                                        alt="avatar"
                                        style={{ width: '100%' }}
                                    />
                                )}
                            </Upload>
                            <div className="second-container">
                                <Form
                                    onFinish={controller.onFormSubmit}
                                    form={controller.form}
                                    className="form-style"
                                    layout="vertical"
                                    initialValues={controller.initialValues}
                                >
                                    <Divider />
                                    <Row gutter={10}>
                                        <Col xs={{ span: 24 }}>
                                            <Typography.Title level={4}>
                                                {translate({ key: 'settings.edit-settings-account' })}
                                            </Typography.Title>
                                        </Col>
                                        <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                            <Form.Item
                                                label={translate({ key: 'settings.real-estate-name' })}
                                                name="name"
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                            <Form.Item
                                                label={translate({ key: 'settings.administrator' })}
                                                name="admin"
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                            <Form.Item label={translate({ key: 'settings.cuit' })} name="cuit">
                                                <Input type="number" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                            <Form.Item
                                                label={translate({ key: 'settings.attention-schedule' })}
                                                name="attentionSchedule"
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                            <Form.Item label={translate({ key: 'settings.phone' })} name="phone">
                                                <Input type="tel" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                            <Form.Item label={translate({ key: 'settings.email' })} name="email">
                                                <Input disabled={true} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={{ span: 24 }}>
                                            <Form.Item label={translate({ key: 'settings.web-site' })} name="webSite">
                                                <Input />
                                            </Form.Item>
                                        </Col>

                                        <Divider> </Divider>
                                        <Col xs={{ span: 24 }}>
                                            <Typography.Title level={5}>
                                                {translate({ key: 'settings.address' })}{' '}
                                            </Typography.Title>
                                        </Col>

                                        <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                            <Form.Item label={translate({ key: 'settings.street' })} name="street">
                                                <Input autoComplete="none" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={{ span: 24 }} md={{ span: 6 }}>
                                            <Form.Item label={translate({ key: 'settings.number' })} name="number">
                                                <Input type="number" placeholder={'S/N'} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={{ span: 24 }} md={{ span: 6 }}>
                                            <Form.Item
                                                label={translate({ key: 'settings.floor' })}
                                                name="floorDepartment"
                                            >
                                                <Input style={{ textTransform: 'uppercase' }} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                            <Form.Item label={translate({ key: 'settings.district' })} name="district">
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                            <Form.Item
                                                label={translate({ key: 'settings.postal-code' })}
                                                name="postalCode"
                                            >
                                                <Input autoComplete="none" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={{ span: 24 }} md={{ span: 12 }} id="select-search">
                                            <Form.Item
                                                name="provinceId"
                                                label={translate({ key: 'settings.province' })}
                                            >
                                                <Select
                                                    allowClear
                                                    showSearch
                                                    options={controller.provincesOptions}
                                                    optionFilterProp="label"
                                                    style={{ textAlign: 'left' }}
                                                    onFocus={fixAutocomplete}
                                                    getPopupContainer={fixSelectOnScroll}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                            <Form.Item
                                                className="form-item-two"
                                                name="city"
                                                label={translate({ key: 'settings.city' })}
                                            >
                                                <Input autoComplete="none" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={{ span: 24 }}>
                                            <Form.Item>
                                                <Button type="primary" htmlType="submit">
                                                    <Text style={{ color: 'white' }}>
                                                        {translate({ key: 'settings.save' })}
                                                    </Text>
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </div>
                        <div className="current-plan-container">
                            <Card title={translate({ key: 'settings.your-current-plan' })} style={{ marginTop: 40 }}>
                                <Typography.Title level={2}>
                                    {translate({ key: 'settings.plan' })} {controller.planName}
                                </Typography.Title>
                                <Space>
                                    <Typography.Paragraph>
                                        {translate({ key: 'settings.till' })} {controller.maxContracts}{' '}
                                        {translate({ key: 'settings.active-contracts' })}
                                    </Typography.Paragraph>
                                    {controller.planPrice !== '0' ? (
                                        <div>
                                            <Popconfirm
                                                title={translate({ key: 'general.sure-to-cancel' })}
                                                onConfirm={controller.onCancelPlan}
                                                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                            >
                                                <Button
                                                    className="cancel-plan-button"
                                                    type="primary"
                                                    size="middle"
                                                    style={{ marginLeft: '124px', marginBottom: '3px' }}
                                                >
                                                    <Text style={{ color: 'white' }}>
                                                        {translate({ key: 'settings.cancel-plan' })}
                                                    </Text>
                                                </Button>
                                            </Popconfirm>
                                        </div>
                                    ) : null}
                                </Space>

                                <Meta
                                    title={controller.planPrice !== '0' ? `Precio $ ${controller.planPrice}` : 'Gratis'}
                                />
                            </Card>
                        </div>
                    </Col>
                </Row>
            )}
        </Layout>
    );
};
