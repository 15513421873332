import React, { useState } from 'react';
import 'components/create-persons-fields/create-persons-fields.scss';
import { Divider, Button, Form, Input, InputNumber, Select, Row, Col } from 'antd';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { DocType } from 'services/persons/person.dto';
import { LabelValue } from 'components/view-models';
import { fixAutocomplete, fixSelectOnScroll } from 'tools/view-hooks/fix-autocomplete';

export interface CreatePersonsFieldsProps {
    provincesOptions: LabelValue[];
    showOptionalFields?: boolean;
}

const CreatePersonsFields: React.FC<CreatePersonsFieldsProps> = (props) => {
    const { translate } = useTranslator();

    // State
    const [isOptVisible, setIsOptVisible] = useState(props.showOptionalFields);

    const { Option } = Select;
    const docTypesOptions = DocType;

    const toggleOptionalFields = () => {
        setIsOptVisible((prevCheck) => !prevCheck);
    };

    const selectTypeDoc = (
        <Form.Item rules={[{ message: translate({ key: 'persons.please-select-doc-type' }) }]} noStyle name="typeDoc">
            <Select style={{ minWidth: 90 }} defaultValue={docTypesOptions.DNI} getPopupContainer={fixSelectOnScroll}>
                <Option value={docTypesOptions.DNI}>{translate({ key: 'persons.dni' })}</Option>
                <Option value={docTypesOptions.PASSPORT}>{translate({ key: 'persons.passport' })}</Option>
                <Option value={docTypesOptions.IDENTIFICATION}>{translate({ key: 'persons.identification' })}</Option>
            </Select>
        </Form.Item>
    );

    return (
        <div className={'create-persons-fields'}>
            <Row gutter={10}>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                        label={translate({ key: 'persons.name' })}
                        name="name"
                        rules={[
                            {
                                required: true,
                                validator: (_, value, callback) => {
                                    if (!value || value === '') {
                                        callback('Ingrese nombre');
                                        return;
                                    }
                                    callback();
                                },
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                        label={translate({ key: 'persons.last-name' })}
                        name="lastName"
                        rules={[
                            {
                                required: true,
                                validator: (_, value, callback) => {
                                    if (!value || value === '') {
                                        callback('Ingrese apellido');
                                        return;
                                    }
                                    callback();
                                },
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 24 }}>
                    <Form.Item
                        label={translate({ key: 'persons.document' })}
                        name="numberDoc"
                        rules={[
                            {
                                required: true,
                                validator: (_, value, callback) => {
                                    if (!value || value === '') {
                                        callback('Ingrese documento');
                                        return;
                                    }
                                    callback();
                                },
                            },
                        ]}
                    >
                        <Input addonBefore={selectTypeDoc} />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 24 }}>
                    <Button onClick={toggleOptionalFields} type="link">
                        {isOptVisible
                            ? translate({ key: 'general.hide-opt-fields' })
                            : translate({ key: 'general.show-opt-fields' })}
                    </Button>
                </Col>
                {isOptVisible && (
                    <>
                        <Col xs={{ span: 24 }}>
                            <Form.Item
                                label={translate({ key: 'persons.email' })}
                                name="email"
                                rules={[
                                    {
                                        required: false,
                                        type: 'email',
                                        message: `${translate({ key: 'persons.you-must-enter-a-valid-email' })}`,
                                    },
                                ]}
                            >
                                <Input placeholder={translate({ key: 'persons.necessary-only-for-notifications' })} />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }}>
                            <Form.Item
                                label={translate({ key: 'persons.cuit' })}
                                name="cuit"
                                rules={[{ required: false }]}
                            >
                                <Input type="number" placeholder={translate({ key: 'general.optional' })} />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }}>
                            <Form.Item
                                label={translate({ key: 'persons.cbu' })}
                                name="cbu"
                                rules={[{ required: false }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }}>
                            <Form.Item
                                label={translate({ key: 'persons.phone' })}
                                name="phone"
                                rules={[
                                    {
                                        required: false,
                                    },
                                    {
                                        pattern: /^(?:(?:00)?549?)?0?(?:11|[2368]\d)(?:(?=\d{0,2}15)\d{2})??\d{8}$/g,
                                        message: `${translate({ key: 'persons.you-must-enter-a-valid-phone-number' })}`,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Divider />
                        <Col xs={{ span: 24 }} xl={{ span: 12 }}>
                            <Form.Item
                                label={translate({ key: 'properties.street' })}
                                name="street"
                                rules={[{ required: false }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 12 }} md={{ span: 12 }} xl={{ span: 6 }}>
                            <Form.Item
                                label={translate({ key: 'properties.number' })}
                                name="number"
                                rules={[{ required: false }]}
                            >
                                <InputNumber
                                    placeholder={'S/N'}
                                    type="number"
                                    className="form-item-two"
                                    minLength={0}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 12 }} md={{ span: 12 }} xl={{ span: 6 }}>
                            <Form.Item
                                label={translate({ key: 'properties.floor-department' })}
                                name="floorDepartment"
                                rules={[{ required: false }]}
                            >
                                <Input style={{ textTransform: 'uppercase' }} />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }}>
                            <Form.Item
                                label={translate({ key: 'properties.district' })}
                                name="district"
                                rules={[{ required: false }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }}>
                            <Form.Item
                                label={translate({ key: 'properties.postal-code' })}
                                name="postalCode"
                                rules={[{ required: false }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }}>
                            <Form.Item
                                rules={[{ required: false }]}
                                name="provinceId"
                                label={translate({ key: 'settings.province' })}
                            >
                                <Select
                                    allowClear
                                    showSearch
                                    options={props.provincesOptions}
                                    optionFilterProp="label"
                                    onFocus={fixAutocomplete}
                                    getPopupContainer={fixSelectOnScroll}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }}>
                            <Form.Item
                                rules={[{ required: false }]}
                                name="city"
                                label={translate({ key: 'settings.city' })}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </>
                )}
                {/* <Divider /> */}
            </Row>
        </div>
    );
};

CreatePersonsFields.defaultProps = {
    provincesOptions: [],
    showOptionalFields: true,
};

export default CreatePersonsFields;
