import React from 'react';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import ModalForm from 'components/modal-form/modal-form.component';
import { EditPersonFragmentProps } from './interface';
import { useEditPersonController } from './persons-edit.controller';
import './persons-edit.scss';
import CreatePersonsFields from 'components/create-persons-fields/create-persons-fields.component';

export const EditPersonFragment: React.FC<EditPersonFragmentProps> = (props) => {
    const { useController = useEditPersonController } = props;
    const controller = useController(props.onPersonEdit);
    const { translate } = useTranslator();

    return (
        <div>
            <ModalForm
                className={'person-edit'}
                onFinish={controller.onFormSubmit}
                form={controller.form}
                isvisible={props.isVisible}
                onCancel={props.onCancel}
                isLoading={controller.isLoading}
                layout={'vertical'}
                initialValues={controller.initialValues}
                title={translate({ key: 'Editar Persona' })}
            >
                <CreatePersonsFields provincesOptions={controller.provincesOptions} />
            </ModalForm>
        </div>
    );
};
