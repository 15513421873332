import { RealEstateDto } from 'services/settings/settings.dto';
import { TypeNotifications } from './type-notifications.enum';

export class CompleteNotificationsDto {
    id?: number;
    type?: TypeNotifications;
    renters?: boolean;
    owners?: boolean;
    userRealEstate?: boolean;
    realEstate?: RealEstateDto;
}
