import React from 'react';
import 'components/liabilities-payment/liabilities-payment.scss';
import { Checkbox, Col, Divider, Form, Grid, Image, Input, Row, Space, Typography } from 'antd';

export interface LiabilityItemViewModel {
    id: number;
    title: string;
    subtitle?: string;
    needAmount: boolean;
    defaultAmount?: number;
    urlIconPaid?: string;
    urlIconUnpaid?: string;
}

export interface LiabilitiesPaymentProps {
    liabilities: LiabilityItemViewModel[] | undefined;
    payerType: string;
}

const LiabilitiesPayment: React.FC<LiabilitiesPaymentProps> = (props) => {
    let totalPayment = 0;
    const screens = Grid.useBreakpoint();
    return (
        <div className={'liabilities-payment'}>
            {props.liabilities?.map((liability, index: number) => {
                totalPayment += liability.defaultAmount ?? 0;
                return (
                    <Row
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginBottom: 10,
                        }}
                        key={liability.id}
                    >
                        <Col xs={24} md={16}>
                            <Space>
                                {screens.sm && (
                                    <Form.Item shouldUpdate noStyle>
                                        {(form) => {
                                            const amountValue = form.getFieldValue([liability.id, 'amount']);
                                            const isPaidValue = form.getFieldValue([liability.id, 'isServicePaid']);
                                            const isPaid = liability.needAmount ? amountValue > 0 : isPaidValue == true;
                                            return (
                                                <Image
                                                    style={{
                                                        width: 25,
                                                    }}
                                                    preview={false}
                                                    src={isPaid ? liability.urlIconPaid : liability.urlIconUnpaid}
                                                />
                                            );
                                        }}
                                    </Form.Item>
                                )}
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography.Text strong>{liability.title}</Typography.Text>
                                    <Typography.Text>{liability.subtitle}</Typography.Text>
                                </div>
                            </Space>
                        </Col>

                        <Col xs={24} md={8}>
                            {liability.needAmount ? (
                                <Form.Item name={[liability.id, 'amount']} style={{ marginBottom: 0 }}>
                                    <Input
                                        size={'middle'}
                                        type={'number'}
                                        addonBefore="$"
                                        placeholder={
                                            liability.defaultAmount ? `${liability.defaultAmount}` : 'Ingrese un monto'
                                        }
                                    />
                                </Form.Item>
                            ) : (
                                <Form.Item
                                    valuePropName={'checked'}
                                    name={[liability.id, 'isServicePaid']}
                                    style={{ marginBottom: 0 }}
                                >
                                    <Checkbox>Pagado</Checkbox>
                                </Form.Item>
                            )}
                        </Col>
                    </Row>
                );
            })}
            {/*<div*/}
            {/*    style={{*/}
            {/*        display: 'flex',*/}
            {/*        justifyContent: 'space-between',*/}
            {/*        alignItems: 'center',*/}
            {/*        marginTop: 10,*/}
            {/*    }}*/}
            {/*>*/}
            {/*    <Typography.Text strong>*/}
            {/*        {props.payerType === 'owner' ? `Total a pagar al propietario` : `Total a cobrar al inquilino`}*/}
            {/*    </Typography.Text>*/}

            {/*    <Typography.Text strong>${totalPayment}</Typography.Text>*/}
            {/*</div>*/}
        </div>
    );
};

export default LiabilitiesPayment;
