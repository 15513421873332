import { useEffect, useState } from 'react';
import { DataMail, NotificationsController } from 'fragments/notifications/interfaces';
import { NotificationsService, useAPINotificationsService } from 'services/notifications/notifications.service';
import { useMessenger } from 'tools/view-hooks/messenger-hook';
import { UpdateNotificationDto } from 'services/notifications/updateNotification.dto';
import { TypeNotifications } from 'services/notifications/type-notifications.enum';
import { CompleteNotificationsDto } from 'services/notifications/complete-notifications.dto';

export const useNotificationsController = (
    notificationService: NotificationsService = useAPINotificationsService(),
    messenger = useMessenger(),
): NotificationsController => {
    /* State */
    const [isLoading, setIsLoading] = useState(false);
    const [isMailHtmlVisible, setIsMailHtmlVisible] = useState(false);
    const [notifications, setNotifications] = useState<CompleteNotificationsDto[]>([]);
    const [notificationUser, setNotificationUser] = useState<CompleteNotificationsDto[]>([]);
    const [dataForMails, setDataForMails] = useState({ type: '', title: '' });

    /* Listeners */
    useEffect(() => {
        fetchNotifications();
    }, []);

    /* View Events */
    const onCheckBoxPressed = (data: { rol: string; checked: boolean; type: string }) => {
        // Example event
        const input: UpdateNotificationDto = {
            type: data.type,
            [data.rol]: data.checked,
        };
        notificationService
            .updateNotifications(input)
            .then(() => {
                fetchNotifications();
            })
            .catch(() => {
                messenger.showErrorMessage({ key: 'notifications.update-notification-error' });
            });
    };
    const onSwitchPressed = (data: { rol: string; checked: boolean; type: string }) => {
        const input: UpdateNotificationDto = {
            type: data.type,
            user: data.checked,
        };
        notificationService
            .updateNotifications(input)
            .then(() => {
                fetchNotifications();
            })
            .catch(() => {
                messenger.showErrorMessage({ key: 'notifications.update-notification-error' });
            });
    };

    /* Private Methods */
    const onMailHtmlPressed = (data: DataMail) => {
        setDataForMails({ type: data.type, title: data.title });
        setIsMailHtmlVisible(true);
    };

    const onMailHtmlAdded = () => {
        return dataForMails;
    };

    const onMailHtmlCanceled = () => {
        setIsMailHtmlVisible(false);
    };

    const fetchNotifications = () => {
        setIsLoading(true);
        notificationService
            .getAllNotifications()
            .then((dtos) => {
                const userNotification = dtos.filter(
                    (element) =>
                        element.type === TypeNotifications.NEWS || element.type === TypeNotifications.OBSERVATIONS,
                );
                setNotificationUser(userNotification);
                const ownerAndRenterNotification = dtos.filter(
                    (element) =>
                        element.type !== TypeNotifications.NEWS && element.type !== TypeNotifications.OBSERVATIONS,
                );
                setNotifications(ownerAndRenterNotification);
            })
            .catch(() => {
                messenger.showErrorMessage({ key: 'notifications.get-notifications-error' });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };
    // Return state and events
    return {
        isMailHtmlVisible,
        notifications,
        notificationUser,
        onCheckBoxPressed,
        onSwitchPressed,
        onMailHtmlCanceled,
        onMailHtmlPressed,
        onMailHtmlAdded,
        isLoading,
    };
};
