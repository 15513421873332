import React from 'react';

import { Button, Col, Row, Table, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import 'fragments/contracts/contracts.scss';
import { useContractsController } from 'fragments/contracts/contracts.controller';
import { ContractsFragmentProps } from 'fragments/contracts/interfaces';
import { AddContractFragment } from 'fragments/add-contract/add-contract.fragment';
import { ContractCardFragmentProps } from 'fragments/contract-card/interfaces';
import { ContractCardFragment } from 'fragments/contract-card/contract-card.fragment';
import { UpdateYourPlanFragment } from 'fragments/update-your-plan/update-your-plan.fragment';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import FlexLoader from 'components/flex-loader/flex-loader.component';
import InputSearch from 'components/input-search/input-search.component';

export const ContractsFragment: React.FC<ContractsFragmentProps> = (props) => {
    const { useController = useContractsController } = props;
    const controller = useController();
    const { translate } = useTranslator();

    const { Column } = Table;

    // Render
    return (
        <div className="contract-fragment">
            <AddContractFragment
                isVisible={controller.isAddContractModalVisible}
                onCancel={controller.onAddContractCanceled}
                onContractAdded={controller.onContractAdded}
            />
            <UpdateYourPlanFragment
                isVisible={controller.isUpdatePlanModalVisible}
                onCancel={controller.onUpdatePlanCanceled}
                handleOnClickUpdate={controller.onClickUpdate}
                isLoadingUpdateButton={controller.isLoadingUpdateButton}
            />
            {controller.isLoading ? (
                <FlexLoader />
            ) : (
                <div>
                    <Row gutter={10} justify="space-between" className={'contract-header'}>
                        <Col xs={24} lg={10}>
                            <Button
                                type="primary"
                                className={'add-property-btn'}
                                icon={<PlusOutlined />}
                                onClick={controller.onAddContractPressed}
                            >
                                <Typography.Text className="text-button">
                                    {translate({ key: 'contracts.add-contract' })}
                                </Typography.Text>
                            </Button>
                        </Col>
                        <Col xs={24} lg={12} xl={8}>
                            <InputSearch onSearch={controller.onSearch} />
                        </Col>
                    </Row>
                    <Table
                        pagination={{ defaultPageSize: 6 }}
                        dataSource={controller.contractCardProps}
                        showHeader={false}
                    >
                        <Column
                            render={(text: string, contractDto: ContractCardFragmentProps) => (
                                <ContractCardFragment {...contractDto} />
                            )}
                        />
                    </Table>
                </div>
            )}
        </div>
    );
};
