import { useContext, useEffect, useState } from 'react';

import { Form } from 'antd';
import { FollowUpContext } from 'fragments/follow-up-card/follow-up.context';

import { FollowUpCardPaymentController } from 'fragments/follow-up-card/follow-up-card-payment/interfaces';
import { RentalPaymentService, useApiRentalPaymentService } from 'services/rentalPayments/rentalPayment.service';
import { FollowUpService, useApiFollowUpService } from 'services/followUps/followUp.service';
import { useAPIMembershipService } from 'services/memberships/memberships.service';
import { useMessenger } from 'tools/view-hooks/messenger-hook';
import { ContractsService, useAPIContractService } from 'services/contracts/contracts.service';
import moment from 'moment';
import { currencyFormatter } from 'components/currency-input/currency-input.component';

export const useFollowUpCardPaymentController = (
    followUpService: FollowUpService = useApiFollowUpService(),
    contractsService: ContractsService = useAPIContractService(),
    rentalPaymentService: RentalPaymentService = useApiRentalPaymentService(),
    messenger = useMessenger(),
): FollowUpCardPaymentController => {
    /* State */
    const [formInstance] = Form.useForm();
    const [rentalForm] = Form.useForm();
    const { followUp, setFollowUp, canPay } = useContext(FollowUpContext);
    const [isUpdateModalFormVisible, setIsUpdateModalFormVisible] = useState(false);
    const [isUpdateFormLoading, setIsUpdateFormLoading] = useState(false);
    const [isInputDisabled, setIsInputDisabled] = useState(false);
    const [charge, setCharge] = useState(followUp.dontCharge);
    const [valueInput, setValueInput] = useState('');

    /* Listeners */
    useEffect(() => {
        setIsInputDisabled(!canPay);
        rentalForm.resetFields();
    }, [followUp.amountPaid]);

    /* View Events */

    const onPayRentFormSubmit = async (amount: number) => {
        const paymentInput = { idFollowUp: followUp.id, amount };
        rentalPaymentService
            .onPaymentChange(paymentInput)
            .then((dto) => {
                setFollowUp((prevState) => {
                    return { ...prevState, ...dto };
                });
            })
            .catch(() => {
                messenger.showErrorMessage({ key: 'follow-ups.payment-error' });
            });
    };

    const onBlurPayRent = async (event: any) => {
        const value = event.target.value;
        const parserValue = value.replace('$', '').replace('.', '').replace(',', '.');
        const parserNumber = Number(parserValue);
        const amount = Number.isNaN(parserNumber) ? 0 : parserNumber;
        await onPayRentFormSubmit(amount);
    };

    const onEnterPayRent = async (inputs: any) => {
        const newAmount = Number(Object.values(inputs)[0]);
        await onPayRentFormSubmit(newAmount);
    };

    const onUpdateFormSubmit = (inputs: any) => {
        setIsUpdateFormLoading(true);
        const newAmount = Number(Object.values(inputs)[0]);
        followUpService
            .updatePrice({ id: followUp.id, price: newAmount })
            .then((dto) => {
                setIsUpdateModalFormVisible(false);
                setFollowUp((prevState) => {
                    return { ...prevState, ...dto };
                });
            })
            .catch(() => {
                messenger.showErrorMessage({ key: 'common-errors.web-error-message' });
            })
            .finally(() => {
                setIsUpdateFormLoading(false);
            });
    };
    const onClickEditButton = () => {
        setIsUpdateModalFormVisible(true);
    };
    const onUpdateFormCancel = () => {
        setIsUpdateModalFormVisible(false);
    };

    const onChangeInput = (event: any) => {
        const value = valueInput.concat(event.target.value);
        setValueInput(value);
    };

    const ignoreCharge = (event: any) => {
        followUpService.updateCharge({ charge: event.target.checked }, followUp.id ?? 0).then((resp) => {
            setCharge(resp.dontCharge);
        });
    };

    const interest = followUp.interestPrice ?? 0;
    const rentalPrice = followUp.rentalPrice ?? 0;
    let realRentalPrice;
    if (!charge && followUp.followUpStatus === 'expired') {
        realRentalPrice = Number(rentalPrice) + Number(interest);
    } else {
        realRentalPrice = Number(rentalPrice);
    }

    const currentYear = moment().year();
    const currentMonth = moment().month() + 1;
    const year = followUp.year ?? moment().year();
    const month = followUp.month ?? moment().month() + 1;
    const isOldFollowUp = year < currentYear || (month < currentMonth && year === currentYear);
    /* Private Methods */

    // Return state and events
    return {
        form: formInstance,
        isInputDisabled,
        rentalForm,
        rentalPrice: realRentalPrice ?? 0,
        nextRentalPrice: followUp.nextPrice,
        paymentStatus: followUp.paymentStatus ?? '',
        upgradeable: followUp.upgradeable ?? false,
        amountPaid: followUp.amountPaid ?? 0,
        followUpStatus: followUp.followUpStatus ?? '',
        isUpdateModalFormVisible,
        isUpdateFormLoading,
        isOldContract: followUp.contract?.isNewLawContract ?? false,
        onClickEditButton,
        onUpdateFormCancel,
        onUpdateFormSubmit,
        interestPrice: currencyFormatter(interest),
        charge: charge ?? false,
        ignoreCharge,
        isOldFollowUp,
        onChangeInput,
        valueInput,
        onBlurPayRent,
        onEnterPayRent,
    };
};
