import React from 'react';
import 'components/create-property-fields/create-property-fields.scss';
import { Divider, Form, Input, Select, Row, Col } from 'antd';
import SelectOrCreateItem from 'components/select-or-create-item/select-or-create-item.component';
import CreatePersonsFields from 'components/create-persons-fields/create-persons-fields.component';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { LabelValue } from 'components/view-models';
import { fixAutocomplete, fixSelectOnScroll } from 'tools/view-hooks/fix-autocomplete';

export interface CreatePropertyFieldsProps {
    personsOptions: LabelValue[];
    provincesOptions: LabelValue[];
}

const CreatePropertyFields: React.FC<CreatePropertyFieldsProps> = (props) => {
    const { translate } = useTranslator();

    return (
        <div className={'create-property-fields'}>
            <Row gutter={10}>
                <Col xs={{ span: 24 }} xl={{ span: 12 }}>
                    <Form.Item
                        label={translate({ key: 'properties.street' })}
                        name="street"
                        rules={[{ required: true }]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 12 }} md={{ span: 12 }} xl={{ span: 6 }}>
                    <Form.Item
                        label={translate({ key: 'properties.number' })}
                        name="number"
                        rules={[{ required: false }]}
                    >
                        <Input minLength={0} type="number" placeholder={'S/N'} />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 12 }} md={{ span: 12 }} xl={{ span: 6 }}>
                    <Form.Item
                        label={translate({ key: 'properties.floor-department' })}
                        name="floorDepartment"
                        rules={[{ required: false }]}
                    >
                        <Input style={{ textTransform: 'uppercase' }} />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                        label={translate({ key: 'properties.district' })}
                        name="district"
                        rules={[{ required: true }]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                        label={translate({ key: 'properties.postal-code' })}
                        name="postalCode"
                        rules={[{ required: false }]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }} id="select-search">
                    <Form.Item
                        rules={[{ required: true }]}
                        name="provinceId"
                        label={translate({ key: 'settings.province' })}
                    >
                        <Select
                            allowClear
                            showSearch
                            options={props.provincesOptions}
                            optionFilterProp="label"
                            onFocus={fixAutocomplete}
                            getPopupContainer={fixSelectOnScroll}
                        />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item rules={[{ required: true }]} name="city" label={translate({ key: 'settings.city' })}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <SelectOrCreateItem
                        name={'owners'}
                        createFormItemName={'newOwner'}
                        selectOptions={props.personsOptions}
                        optionFilterProp={'label'}
                        createComponent={<CreatePersonsFields provincesOptions={props.provincesOptions} />}
                        createTitle={translate({ key: 'properties.title-data-owner' })}
                        label={translate({ key: 'properties.owners' })}
                        requiredForm={true}
                        rulesComponent={[
                            {
                                validator: (_: any, value: any, callback: any) => {
                                    if (!value || !value.name || !value.lastName || !value.numberDoc) {
                                        callback('Complete los datos requeridos');
                                        return;
                                    }
                                    callback();
                                },
                            },
                        ]}
                    />
                </Col>
                <Divider />
            </Row>
        </div>
    );
};

export default CreatePropertyFields;
