import React from 'react';
import 'fragments/notifications/mail-html/mail-html.scss';
import { MailHtmlFragmentProps } from 'fragments/notifications/mail-html/interfaces';
import Modal from 'antd/lib/modal/Modal';
import { useMailHtmlController } from './mail-html.controller';

export const MailHtmlFragment: React.FC<MailHtmlFragmentProps> = (props) => {
    const { useController = useMailHtmlController } = props;
    const controller = useController(props.onMailHtmlAdded);
    // Render
    return (
        <Modal
            className="mail-html"
            visible={props.isVisible}
            onCancel={props.onCancel}
            width="80%"
            style={{ height: '400px' }}
            title={controller.data.title}
        >
            <iframe
                src={`${process.env.REACT_APP_API_BASE_URL}/configuration/${controller.data.type}`}
                width="100%"
                height="100%"
                frameBorder={0}
            ></iframe>
        </Modal>
    );
};
