import { useEffect, useState } from 'react';
import { PersonsController } from 'fragments/persons/interfaces';
import { useMessenger } from 'tools/view-hooks/messenger-hook';
import { PersonsService, useAPIPersonsService } from 'services/persons/persons.service';
import { CompletePersonDto } from 'services/persons/person-complete.dto';
import { PersonCardFragmentProps } from 'fragments/person-card/interfaces';

export const usePersonsController = (
    personsService: PersonsService = useAPIPersonsService(),
    messenger = useMessenger(),
): PersonsController => {
    /* State */
    const [isLoading, setIsLoading] = useState(false);
    const [cardProps, setCardProps] = useState<PersonCardFragmentProps[]>([]);
    const [isAddPersonVisible, setIsAddPersonVisible] = useState(false);

    /* Listeners */
    useEffect(() => {
        fetchPersonsCardProps();
    }, []);

    /* View Events */

    /* Private Methods */
    const mapDtoToCardProps = (dto: CompletePersonDto): PersonCardFragmentProps => {
        const street = dto.street ? dto.street : '';
        const postalCode = dto.postalCode ? dto.postalCode : '';
        const number = dto.number ? dto.number : '';
        const city = dto.city ? dto.city : '';
        const floorDepartment = dto.floorDepartment ? dto.floorDepartment : '';
        const district = dto.district ? dto.district : '';
        return {
            key: `${dto.id}`,
            completePersonDto: { ...dto, street, postalCode, number, city, floorDepartment, district },
            onDelete: onDeletePerson,
        };
    };

    const onSearch = (query: string) => {
        personsService
            .searchPersons({ param: query })
            .then((resp) => {
                const cardPropsMapped = resp.map(mapDtoToCardProps);
                setCardProps(cardPropsMapped);
            })
            .catch((err) => {
                messenger.showErrorMessage({ key: 'persons.remove-person-error' });
            });
    };

    const onDeletePerson = (id: number) => {
        personsService
            .deletePerson(id)
            .then(() => {
                removeCardProp(id);
            })
            .catch(() => {
                messenger.showErrorMessage({ key: 'persons.remove-person-error' });
            });
    };
    const removeCardProp = (id: number) => {
        setCardProps((actualState) => {
            return actualState.filter((item) => {
                return Number(item.key) != id;
            });
        });
    };
    const fetchPersonsCardProps = () => {
        setIsLoading(true);
        personsService
            .getAllPersonsVisible()
            .then((dtos) => {
                const cardPropsMapped = dtos.map(mapDtoToCardProps);
                setCardProps(cardPropsMapped);
            })
            .catch(() => {
                messenger.showErrorMessage({ key: 'persons.get-persons-error' });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };
    const onPersonAdded = (dto: CompletePersonDto) => {
        const cardProp = mapDtoToCardProps(dto);
        setCardProps([cardProp, ...cardProps]);
        setIsAddPersonVisible(false);
    };
    const onAddPersonPressed = () => {
        setIsAddPersonVisible(true);
    };

    const onAddPersonCanceled = () => {
        setIsAddPersonVisible(false);
    };

    // Return state and events
    return {
        isLoading,
        personsCardProps: cardProps,
        isAddPersonVisible: isAddPersonVisible,
        onSearch,
        onAddPersonCanceled: onAddPersonCanceled,
        onAddPersonPressed: onAddPersonPressed,
        onPersonAdded: onPersonAdded,
    };
};
