import { PropertyDto } from 'services/properties/property.dto';
import { ProvinceDto } from 'services/settings/settings.dto';
import { LiabilityDto } from 'services/liabilities/liabilities.service';

export class CompletePropertyDto extends PropertyDto {
    id?: number;
    alias?: string;
    services?: LiabilityDto[];
    province?: ProvinceDto;
    city?: string;
    isVisible?: boolean;
}
