import React, { useEffect } from 'react';

import { Col, Descriptions, Row, Space, Tag, Typography } from 'antd';

import 'fragments/follow-up-card/follow-up-card.scss';
import { FollowUpCardFragmentProps } from 'fragments/follow-up-card/interfaces';
import { FollowUpContext } from 'fragments/follow-up-card/follow-up.context';
import { useFollowUpCardController } from 'fragments/follow-up-card/follow-up-card.controller';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { FollowUpCardPaymentFragment } from 'fragments/follow-up-card/follow-up-card-payment/follow-up-card-payment.fragment';
import { FollowUpCardTaxesFragment } from 'fragments/follow-up-card/follow-up-card-taxes/follow-up-card-taxes.fragment';

export const FollowUpCardFragment: React.FC<FollowUpCardFragmentProps> = (props) => {
    const { useController = useFollowUpCardController } = props;
    const controller = useController(props.dto);
    const { translate } = useTranslator();

    useEffect(() => {
        controller.onUpdate(props.dto);
    }, [props.dto]);

    // Render
    return (
        <FollowUpContext.Provider
            value={{ followUp: controller.followUp, setFollowUp: controller.setFollowUp, canPay: props.canPay }}
        >
            <div className={'follow-up-card'}>
                <Space align={'start'}>
                    <Typography.Title className={'follow-up-alias'} level={5}>
                        {controller.title}
                    </Typography.Title>
                    <Tag className={controller.tagClassName} color={controller.tagColor}>
                        {controller.tagName}
                    </Tag>
                </Space>
                <Row gutter={10}>
                    <Col xs={24} md={8}>
                        <Descriptions column={1} labelStyle={{ fontWeight: 'bold' }}>
                            <Descriptions.Item label={translate({ key: 'persons.renter' })}>
                                {controller.renterName}
                            </Descriptions.Item>
                            <Descriptions.Item label={translate({ key: 'properties.owner' })}>
                                {controller.ownerName}
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                    <Col xs={24} md={8}>
                        <FollowUpCardPaymentFragment />
                    </Col>
                    <Col xs={24} md={8}>
                        <FollowUpCardTaxesFragment />
                    </Col>
                </Row>
            </div>
        </FollowUpContext.Provider>
    );
};
