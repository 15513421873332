import React, { ReactNode, useState } from 'react';
import 'components/stepper-form/stepper-form.scss';
import { Button, Divider, Form, FormProps, Layout, Row, Space, Steps, Typography } from 'antd';
import { FormInstance } from 'antd/lib/form';

export interface StepperFormProps<Values = []> extends FormProps {
    form: FormInstance<Values>;
    steps: {
        key: number;
        validateFields: string[];
        title?: string;
        description?: string;
        component: ReactNode;
        isValid: boolean;
    }[];
    previousButtonLabel: string;
    nextButtonLabel: string;
    lastButtonLabel: string;
    isLoading: boolean;
}

const StepperForm: React.FC<StepperFormProps> = (props) => {
    const [currentStepIndex, setCurrentStepIndex] = useState(0);

    const currentStep = props.steps[currentStepIndex];
    const nextButtonText = currentStepIndex < props.steps.length - 1 ? props.nextButtonLabel : props.lastButtonLabel;

    const moveForward = () => {
        const nexStepIndex = currentStepIndex + 1;
        if (nexStepIndex < props.steps.length) {
            props.form.validateFields(currentStep.validateFields).then(() => {
                setCurrentStepIndex(nexStepIndex);
            });
        } else if (nexStepIndex === props.steps.length) {
            props.form.submit();
        }
    };

    const moveBackwards = () => {
        const nexStepIndex = currentStepIndex - 1;
        if (nexStepIndex >= 0) {
            setCurrentStepIndex(nexStepIndex);
        }
    };

    const StepperButtons = (
        <Row justify={'end'} className={'step-navigator'}>
            <Space>
                {currentStepIndex !== 0 ? (
                    <Button onClick={moveBackwards} disabled={props.isLoading}>
                        {props.previousButtonLabel}
                    </Button>
                ) : null}

                <Button
                    disabled={!currentStep.isValid}
                    type={'primary'}
                    onClick={moveForward}
                    loading={props.isLoading}
                >
                    <Typography.Text style={{ color: 'white' }}>{nextButtonText}</Typography.Text>
                </Button>
            </Space>
        </Row>
    );

    return (
        <Layout className={'stepper-form'}>
            <Steps current={currentStepIndex}>
                {props.steps.map((step) => (
                    <Steps.Step key={step.title} title={step.title} description={step.description} />
                ))}
            </Steps>

            <Form colon={false} {...props}>
                {props.steps.map((step) => {
                    return (
                        <div className={currentStep.key == step.key ? 'current-step' : 'hidden-step'} key={step.key}>
                            {step.component}
                        </div>
                    );
                })}
            </Form>
            {StepperButtons}
        </Layout>
    );
};

export default StepperForm;
