import { plainToClass } from 'class-transformer';
import axios from 'axios';
import { Session } from 'auth/models/session';
import {
    CreateContractLiability,
    CreateFollowUpLiability,
    CreatePropertyLiability,
    FollowupLiabilityDto,
    LiabilitiesService,
    LiabilityDto,
    LiabilityPaymentDto,
    PayLiabilityInput,
} from './liabilities.service';

export class ApiLiabilitiesService implements LiabilitiesService {
    constructor(private session: Session) {}

    async getAllFollowUpLiabilities(id: number): Promise<FollowupLiabilityDto[]> {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/followup/${id}/all-liabilities`, {
            headers: { Authorization: `Bearer ${this.session.getToken()}` },
        });
        return response.data as FollowupLiabilityDto[];
    }

    async editLiability(id: number, input: LiabilityDto): Promise<LiabilityDto> {
        const response = await axios.put(
            `${process.env.REACT_APP_API_BASE_URL}/liabilities/${id}`,
            { input },
            {
                headers: { Authorization: `Bearer ${this.session.getToken()}` },
            },
        );
        return response.data;
    }
    async getAllLiabilitiesByPropertyId(id: number): Promise<LiabilityDto[]> {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/properties/${id}/all-liabilities`, {
            headers: { Authorization: `Bearer ${this.session.getToken()}` },
        });
        return response.data as LiabilityDto[];
    }

    async getLiability(id: number): Promise<LiabilityDto> {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/liabilities/${id}`, {
            headers: { Authorization: `Bearer ${this.session.getToken()}` },
        });
        return response.data;
    }

    async payLiabilities(followUpId: number, inputs: PayLiabilityInput[]): Promise<LiabilityPaymentDto[]> {
        const response = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/followup/${followUpId}/pay-liabilities`,
            inputs,
            {
                headers: { Authorization: `Bearer ${this.session.getToken()}` },
            },
        );
        return response.data as LiabilityPaymentDto[];
    }

    async addPropertyLiability(input: CreatePropertyLiability): Promise<LiabilityDto> {
        const response = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/properties/${input.propertyId}/liabilities`,
            { input },
            {
                headers: { Authorization: `Bearer ${this.session.getToken()}` },
            },
        );
        return response.data;
    }
    async addContractLiability(input: CreateContractLiability): Promise<LiabilityDto> {
        const response = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/contracts/${input.contractId}/liabilities`,
            { input },
            {
                headers: { Authorization: `Bearer ${this.session.getToken()}` },
            },
        );
        return response.data;
    }
    async addFollowUpLiability(input: CreateFollowUpLiability): Promise<FollowupLiabilityDto> {
        const response = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/followup/${input.followUpId}/liabilities`,
            { input },
            {
                headers: { Authorization: `Bearer ${this.session.getToken()}` },
            },
        );
        return response.data;
    }
    async deleteLiability(id: number): Promise<boolean> {
        const response = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/liabilities/${id}`, {
            headers: { Authorization: `Bearer ${this.session.getToken()}` },
        });
        return response.data as boolean;
    }
}
