import { TypeServiceDto } from './type-services.dto';
import { ApiTypeServicesService } from './api-type-services.service';

export interface TypeServicesService {
    getAllTypeServices(): Promise<TypeServiceDto[]>;
}

export const useAPITypeServicesService = (): TypeServicesService => {
    return new ApiTypeServicesService();
};
