import { useContext, useState } from 'react';

import { Form } from 'antd';
import moment from 'moment';

import {
    ContractAddObservationController,
    ObservationFormValues,
} from 'fragments/contract-card/contract-add-observation/interfaces';
import { ContractContext } from '../contract-card.component';
import { ObservationService, useAPIObservationsService } from 'services/observations/observation.service';
import { useMessenger } from 'tools/view-hooks/messenger-hook';

export const useContractAddObservationController = (
    onObservationAdded: () => void,
    observationService: ObservationService = useAPIObservationsService(),
    messenger = useMessenger(),
): ContractAddObservationController => {
    /* State */
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { contract, setContract } = useContext(ContractContext);
    const [formInstance] = Form.useForm();

    /* Listeners */

    /* View Events */

    /* Private Methods */
    const onFormSubmit = (inputs: unknown) => {
        if (!contract.id) {
            return;
        }
        setIsLoading(true);
        const parsedInputs = inputs as ObservationFormValues;
        const body = parsedInputs.observations?.map((ob) => {
            return { description: ob.description, status: ob.status, date: ob.date?.toDate() };
        });
        observationService
            .addMultiObservations(contract.id, body ?? [])
            .then((dto) => {
                setContract((prevState) => {
                    return { ...prevState, observations: dto };
                });
                onObservationAdded();
            })
            .catch(() => {
                messenger.showErrorMessage({ key: 'observations.add-observations-error' });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const mapDtoToInitialValues = (): ObservationFormValues => {
        if (!contract.observations) {
            return { observations: [] };
        }
        const observationViewModel = contract.observations
            .sort((a, b) => {
                return moment(a.date).isBefore(moment(b.date)) ? -1 : 1;
            })
            .map((ob) => {
                return { description: ob.description, status: ob.status, date: moment(ob.date) };
            });
        return { observations: observationViewModel };
    };

    return {
        initialValues: mapDtoToInitialValues(),
        form: formInstance,
        isLoading,
        onFormSubmit,
    };
};
