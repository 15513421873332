import { ApiContractsService } from './api-contracts.service';
import { useLocalSession } from 'auth/helpers/session.hooks';
import { ContractDto, InformationInterestDto } from './contract.dto';
import { CompleteContractDto } from './contract-complete.dto';
import { RescindContractDto } from './rescind-contract.dto';

export interface ContractsService {
    addContract(input: ContractDto): Promise<CompleteContractDto>;
    deleteContract(id: number): Promise<void>;
    deleteContractFile(id: number): Promise<void>;
    getAllContracts(): Promise<CompleteContractDto[]>;
    rescindContract(input: RescindContractDto): Promise<ContractDto>;
    searchContract(input: { param: string }): Promise<CompleteContractDto[]>;
    updateContract(input: ContractDto, id: number): Promise<CompleteContractDto>;
    updateContractDetails(input: ContractDto, id: number): Promise<CompleteContractDto>;
    updateInformationInterest(input: InformationInterestDto, id: number): Promise<InformationInterestDto>;
}

export const useAPIContractService = (): ContractsService => {
    const [getSession] = useLocalSession();
    const session = getSession();
    return new ApiContractsService(session);
};
