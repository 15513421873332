import React, { useState } from 'react';
import 'components/download-receipts-modal/download-receipts-modal.scss';
import { Button, Modal, Typography } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import { DownloadableItem } from '../../services/rentalPayments/receipt.dto';
import { useTranslator } from '../../tools/view-hooks/translator-hook';

export interface DownloadReceiptsModalProps {
    dto: DownloadableItem[];
    isVisible: boolean;
    onCancel?: () => void;
}

const DownloadReceiptsModal: React.FC<DownloadReceiptsModalProps> = (props) => {
    // State
    const { Text } = Typography;
    const { translate } = useTranslator();

    return (
        <Modal
            title={translate({
                key: 'follow-ups.rental-receipts',
            })}
            visible={props.isVisible}
            onCancel={props.onCancel}
            onOk={props.onCancel}
        >
            {props.dto.map((item) => {
                return (
                    <div key={item.url} className="receip-container">
                        <Text className={'receip-name'}>{item.label} </Text>
                        <Button
                            className={'receip-btn'}
                            type="primary"
                            shape="round"
                            href={item.url}
                            target="_blank"
                            icon={<DownloadOutlined />}
                        >
                            <Text style={{ color: 'white' }}>{translate({ key: 'followUp.download' })}</Text>
                        </Button>
                    </div>
                );
            })}
            <Text>
                {translate({
                    key: 'follow-ups.download-receipt',
                })}
            </Text>
        </Modal>
    );
};

export default DownloadReceiptsModal;
