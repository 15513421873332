import { CompleteContractDto } from '../contracts/contract-complete.dto';
import { FollowupLiabilityDto, LiabilityDto } from 'services/liabilities/liabilities.service';

export class PaginationDto<T> {
    total?: number;
    data?: T[];
}

export class FollowUpDto {
    id?: number;
    month?: number;
    year?: number;
    rentalPrice?: number;
    upgradeable?: boolean;
    paymentStatus?: FollowUpPaymentStatusEnum;
    payment?: any;
    amountPaid?: number;
    expirationDay?: number;
    followUpStatus?: string;
    total?: number;
}

export class priceDto {
    id?: number;
    price?: number;
}
export interface CompleteFollowUpDto extends FollowUpDto {
    contract?: CompleteContractDto;
    alias?: string;
    renterName?: string;
    ownerName?: string;
    nextPrice?: number;
    total?: number;
    payment?: any;
    interestPrice?: number;
    dontCharge?: boolean;
    paymentStatus?: FollowUpPaymentStatusEnum;
    liabilities?: FollowupLiabilityDto[];
}

export enum FollowUpPaymentStatusEnum {
    COMPLETE = 'complete',
    PARTIAL = 'partial',
    INCOMPLETE = 'incomplete',
    UNKNOWN = 'unknown',
}
