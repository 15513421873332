import { useEffect, useState } from 'react';
import { PropertyCardController } from 'fragments/property-card/interfaces';
import { CompletePropertyDto } from 'services/properties/property-complete.dto';
import { useAPISettingsService } from 'services/settings/settings.service';
import { ProvinceDto } from 'services/settings/settings.dto';

export const usePropertyCardController = (propertyDto: CompletePropertyDto): PropertyCardController => {
    /* State */
    const [property, setProperty] = useState(propertyDto);
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);

    /* Listeners */

    /* View Events */
    const onEditPropertyPressed = () => {
        setIsEditModalVisible(true);
    };

    const onEditCanceled = () => {
        setIsEditModalVisible(false);
    };

    const onFinish = () => {
        setIsEditModalVisible(false);
    };

    /* Private Methods */

    // Return state and events
    const firstOwnerName =
        property.owners && property.owners[0] && !property.owners[0].isVisible
            ? `${property.owners[0].name} ${property.owners[0].lastName} (eliminado)`
            : property.owners && property.owners[0]
            ? `${property.owners[0].name} ${property.owners[0].lastName}`
            : undefined;

    const province = property.province && property.province.name ? property.province.name : '';
    return {
        id: property.id ?? 0,
        title: property.alias ?? '',
        subtitle: `${property.district} ` + `${property.city} - ` + `${province}`,
        ownerName: firstOwnerName ?? '-',
        property,
        setProperty,
        onEditPropertyPressed,
        onEditCanceled,
        onFinish,
        isEditModalVisible,
    };
};
