import { useContext, useState } from 'react';
import { PropertyCardTaxesController } from 'fragments/property-card/property-card-taxes/interfaces';
import { PropertyContext } from 'fragments/property-card/property.context';
import { LabelValue } from 'components/view-models';

export const usePropertyCardTaxesController = (): PropertyCardTaxesController => {
    /* State */
    const [isServiceFormVisible, setIsServiceFormVisible] = useState(false);
    const { property } = useContext(PropertyContext);

    /* Listeners */

    /* View Events */
    const onEditServicesPressed = () => {
        setIsServiceFormVisible(true);
    };

    const onEditServicesCanceled = () => {
        setIsServiceFormVisible(false);
    };

    const onServicesUpdated = () => {
        setIsServiceFormVisible(false);
    };

    /* Private Methods */
    const mapDtoToViewModel = (): LabelValue[] => {
        return (
            property.services?.map((service) => {
                return {
                    label: service.type?.title ?? '',
                    value: service.name ?? '',
                };
            }) ?? []
        );
    };

    // Return state and events
    return {
        isServiceFormVisible,
        servicesDescriptions: mapDtoToViewModel(),
        onEditServicesPressed,
        onServicesUpdated,
        onEditServicesCanceled,
    };
};
