import { useContext, useEffect, useState } from 'react';
import { FollowUpCardServicesPaymentController } from 'fragments/follow-up-card/follow-up-card-services-payment/interfaces';
import { Form } from 'antd';
import { FollowUpContext } from 'fragments/follow-up-card/follow-up.context';
import { useMessenger } from 'tools/view-hooks/messenger-hook';
import {
    FollowupLiabilityDto,
    LiabilitiesService,
    LiabilityPayerType,
    LiabilityPaymentDto,
    LiabilityPaymentTerm,
    PayLiabilityFormInput,
    PayLiabilityInput,
    useAPILiabilitiesService,
} from 'services/liabilities/liabilities.service';
import { LiabilityItemViewModel } from 'components/liabilities-payment/liabilities-payment.component';

export const useFollowUpCardServicesPaymentController = (
    liabilitiesService: LiabilitiesService = useAPILiabilitiesService(),
    messenger = useMessenger(),
): FollowUpCardServicesPaymentController => {
    const { followUp, setFollowUp } = useContext(FollowUpContext);

    const [isLoading, setIsLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [renterLiabilities, setRenterLiabilities] = useState<FollowupLiabilityDto[]>([]);
    const [ownerLiabilities, setOwnerLiabilities] = useState<FollowupLiabilityDto[]>([]);
    const [formInitialValues, setFormInitialValues] = useState<[key: number, value: PayLiabilityFormInput]>();
    const [formInstance] = Form.useForm();

    useEffect(() => {
        if (!followUp?.liabilities) return;
        updateLiabilities(followUp.liabilities);
    }, [followUp]);

    const updateLiabilities = (liabilitiesDtos: FollowupLiabilityDto[]) => {
        const ownerLiabilities = liabilitiesDtos.filter((service) => {
            return service.payerType == LiabilityPayerType.OWNER;
        });
        setOwnerLiabilities(ownerLiabilities);

        const renterLiabilities = liabilitiesDtos.filter((service) => {
            return service.payerType == LiabilityPayerType.RENTER;
        });
        setRenterLiabilities(renterLiabilities);

        const initialValues = liabilitiesDtos.map(mapDtoIntoFormValues);
        const parsedInitialValues = Object.assign({}, ...initialValues);

        setFormInitialValues(parsedInitialValues);
    };

    const mapDtoIntoFormValues = (dto: FollowupLiabilityDto): any => {
        const body: any = {};
        const amount = dto.payment?.amount;
        const useAmount = dto.needAmount;
        if (useAmount) {
            body.amount = amount;
            body.isServicePaid = amount ? amount > 0 : false;
        } else {
            body.isServicePaid = dto.payment?.isPaid ?? false;
        }

        return {
            [dto.id]: body,
        };
    };

    const orderServices = (services?: FollowupLiabilityDto[]): FollowupLiabilityDto[] => {
        if (!services) {
            return [];
        }
        return services?.sort((a, b) => {
            if (!a.type?.title || !b.type?.title) {
                return -1;
            }
            return a.type.title > b.type.title ? 1 : -1;
        });
    };

    const onOk = () => {
        formInstance.submit();
    };

    const onCancel = () => {
        setIsModalVisible(false);
    };

    const onClickService = () => {
        setIsModalVisible(true);
    };

    const onFormFinish = async (values: [key: number, value: PayLiabilityFormInput]) => {
        if (!followUp.id) {
            return;
        }
        const request = buildRequestFromValues(values);
        setIsLoading(true);
        liabilitiesService
            .payLiabilities(followUp.id, request)
            .then((payments) => {
                updateServicePayments(payments);
                setIsModalVisible(false);
                // refresh
            })
            .catch(() => {
                messenger.showErrorMessage({ key: 'error' });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const updateServicePayments = (payments: LiabilityPaymentDto[]): void => {
        const liabilities = followUp?.liabilities;
        if (!liabilities) return;
        for (const li of liabilities) {
            const payment = payments.find((p) => p.liabilityId == li.id);
            if (payment) {
                li.payment = payment;
            }
        }
        setFollowUp((prevState) => {
            return { ...prevState, liabilities };
        });
        updateLiabilities(liabilities);
    };

    const buildRequestFromValues = (values: [key: number, value: PayLiabilityFormInput]): PayLiabilityInput[] => {
        const request: PayLiabilityInput[] = [];
        for (const key in values) {
            const parsedValue = values[key] as PayLiabilityFormInput;
            const isPaid = parsedValue.isServicePaid;

            const parsedAmount =
                typeof parsedValue.amount === 'string' && parsedValue.amount === ''
                    ? undefined
                    : Number(parsedValue.amount);

            const amount = typeof parsedAmount === 'number' && parsedAmount >= 0 ? parsedAmount : undefined;

            const input: PayLiabilityInput = {
                liabilityId: +key,
                amount,
                isPaid: !isPaid && !(typeof amount === 'number') ? false : isPaid,
            };
            request.push(input);
        }
        return request;
    };

    const mapDtoToItemView = (dto: FollowupLiabilityDto): LiabilityItemViewModel => {
        return {
            id: dto.id ?? 0,
            title: dto.name,
            subtitle: `Correspondiente a ${dto.period}`,
            defaultAmount: dto.defaultAmount,
            needAmount: dto.needAmount ?? false,
            urlIconPaid: dto.type?.urlIconPaid,
            urlIconUnpaid: dto.type?.urlIconNotPaid,
        };
    };

    // Return state and events
    return {
        formInstance,
        isLoading,
        isModalVisible,
        liabilities: followUp.liabilities ?? [],
        renterLiabilities: renterLiabilities.map(mapDtoToItemView),
        ownerLiabilities: ownerLiabilities.map(mapDtoToItemView),
        formInitialValues,
        onFormFinish,
        onOk,
        onCancel,
        onClickService,
    };
};
