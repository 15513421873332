import React, { useState } from 'react';
import 'components/new-or-old-contract/new-or-old-contract.scss';
import { Divider, Form, Input, Select, Typography, Checkbox, Row, Col } from 'antd';
import { InputValidator } from 'fragments/add-property/interfaces';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import moment from 'moment';
import CurrencyInput from 'components/currency-input/currency-input.component';
import { fixSelectOnScroll } from 'tools/view-hooks/fix-autocomplete';

export interface NewOrOldContractProps {
    validate: InputValidator;
    durationMonths: number;
    initialDate: any;
}

const NewOrOldContract: React.FC<NewOrOldContractProps> = (props) => {
    // State
    const { translate } = useTranslator();
    const [dontKnowAmount, setDontKnowAmount] = useState(true);
    const [periodicity, setPeriodicity] = useState(0);
    const { Option } = Select;
    const onNewContractSelect = (event: any) => {
        setDontKnowAmount(event.target.checked);
    };

    const calculateAmountInputs = (value: number) => {
        setPeriodicity(value);
    };

    const calculateMonthsIncrease = (errors?: React.ReactNode[]): { element: React.ReactNode; size: number } => {
        const initialDateContract = moment(props.initialDate);
        const finishDateContract = moment(props.initialDate).add(props.durationMonths, 'M').subtract(1, 'd');
        const increaseInputs = [];

        let isLast = true;
        let iteration = -1;

        while (initialDateContract.isBefore(finishDateContract)) {
            initialDateContract.add(periodicity, 'M');
            const nextDate = moment(initialDateContract).add(periodicity - 1, 'M');

            if (!nextDate.isBefore(finishDateContract)) {
                isLast = false;
                continue;
            }
            iteration = iteration + 1;
            const label = `${initialDateContract.month() + 1}/${initialDateContract.year()} al ${
                nextDate.month() + 1
            }/${nextDate.year()}`;

            increaseInputs.push(
                <div key={initialDateContract.milliseconds()}>
                    <Form.Item
                        name={iteration}
                        label={`${translate({ key: 'contracts.update-old' })} ${label}`}
                        labelAlign="right"
                        rules={[{ required: true }]}
                    >
                        <CurrencyInput size={'large'} className="input-rent-amount" placeholder={''} />
                    </Form.Item>
                </div>,
            );
        }
        return {
            element: (
                <div>
                    {increaseInputs}
                    <Typography.Text style={{ color: 'red', marginTop: 0 }}>{errors?.toString()}</Typography.Text>
                </div>
            ),
            size: increaseInputs.length,
        };
    };

    const calculateMonthsIncreaseWithLaw = (errors?: React.ReactNode[]): { element: React.ReactNode; size: number } => {
        const increaseInputs: React.ReactNode[] = [];

        let iteration = -1;
        const initialDateContract = moment(props.initialDate);
        const actualDate = moment();

        while (initialDateContract.isBefore(actualDate)) {
            initialDateContract.add(periodicity, 'M');
            const nextDate = moment(initialDateContract).add(periodicity - 1, 'M');

            iteration = iteration + 1;
            const label = `${initialDateContract.month() + 1}/${initialDateContract.year()} al ${
                nextDate.month() + 1
            }/${nextDate.year()}`;

            increaseInputs.push(
                <div key={initialDateContract.milliseconds()}>
                    <Form.Item
                        name={iteration}
                        label={`${translate({ key: 'contracts.update-new' })} ${label}`}
                        labelAlign="right"
                        rules={[{ required: true }]}
                    >
                        <CurrencyInput size={'large'} className="input-rent-amount" placeholder={''} />
                    </Form.Item>
                </div>,
            );
        }
        increaseInputs.pop();
        return {
            element: (
                <div>
                    {increaseInputs}
                    <Typography.Text style={{ color: 'red', marginTop: 0 }}>{errors?.toString()}</Typography.Text>
                </div>
            ),
            size: increaseInputs.length,
        };
    };

    const monthLimit = 12;

    return (
        <div className={'new-or-old-contract'}>
            <Row gutter={10} align="bottom">
                <Col xs={{ span: 24 }} xl={{ span: 12 }}>
                    <Form.Item
                        name="updateFrequency"
                        label={translate({ key: 'contracts.update-periodicity' })}
                        labelAlign="left"
                        rules={[{ required: true }, { validator: props.validate }]}
                    >
                        <Select
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            size={'middle'}
                            allowClear
                            onChange={calculateAmountInputs}
                            getPopupContainer={fixSelectOnScroll}
                        >
                            {Array.from({ length: monthLimit }, (_, k) => {
                                return (
                                    <Option value={k + 1}>
                                        {k + 1} {translate({ key: 'contracts.months' })}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} xl={{ span: 12 }}>
                    <Checkbox style={{ marginBottom: '15px' }} defaultChecked={true} onChange={onNewContractSelect}>
                        <Typography.Text>{translate({ key: 'contracts.apply-law' })}</Typography.Text>
                    </Checkbox>
                </Col>
            </Row>
            {!dontKnowAmount ? (
                <div>
                    <Divider />
                    <Form.List
                        name="amountsActualizations"
                        rules={[
                            {
                                validator: (_, values, callback) => {
                                    if (
                                        !values ||
                                        !Array.isArray(values) ||
                                        values.length === calculateMonthsIncrease().size
                                    ) {
                                        callback();
                                    }
                                    callback('Ingrese todos los valores');
                                },
                            },
                        ]}
                    >
                        {(fields, actions, { errors }) => {
                            return periodicity > 0 && calculateMonthsIncrease(errors).element;
                        }}
                    </Form.List>
                </div>
            ) : (
                <div>
                    <Row gutter={10}>
                        <Col xs={{ span: 24 }}>
                            <Form.List
                                name="amountsActualizationsNew"
                                rules={[
                                    {
                                        validator: (rule, values, callback) => {
                                            const fildsCount = calculateMonthsIncreaseWithLaw().size;
                                            if (fildsCount === 0) {
                                                callback();
                                                return;
                                            }
                                            if (!values || !Array.isArray(values) || values.length < fildsCount) {
                                                callback('Ingrese todos los valores');
                                                return;
                                            }
                                            values.forEach((val) => {
                                                if (Number.isNaN(val) || val === 0) {
                                                    callback('Ingrese todos los valores');
                                                    return;
                                                }
                                            });
                                            callback();
                                        },
                                    },
                                ]}
                            >
                                {(fields, actions, { errors }) => {
                                    return periodicity > 0 && calculateMonthsIncreaseWithLaw(errors).element;
                                }}
                            </Form.List>
                        </Col>
                    </Row>
                    <div
                        style={{
                            textAlign: 'center',
                            backgroundColor: '#ededed',
                            padding: '10px',
                            lineHeight: '1.3em',
                            borderRadius: '8px',
                        }}
                    >
                        <Typography.Text>{translate({ key: 'contracts.reminder' })}</Typography.Text>
                    </div>
                </div>
            )}
        </div>
    );
};

export default NewOrOldContract;
