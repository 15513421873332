import { useEffect, useState } from 'react';
import { plainToClass } from 'class-transformer';

import { Form } from 'antd';

import { AddPropertyController, SelectorOption } from 'fragments/add-property/interfaces';
import { PropertyDto } from 'services/properties/property.dto';
import { PersonsService, useAPIPersonsService } from 'services/persons/persons.service';
import { CompletePropertyDto } from 'services/properties/property-complete.dto';
import { useAPIPropertieService } from 'services/properties/properties.service';
import { CompletePersonDto } from 'services/persons/person-complete.dto';
import { ProvinceDto } from 'services/settings/settings.dto';
import { useAPISettingsService } from 'services/settings/settings.service';
import { useMessenger } from 'tools/view-hooks/messenger-hook';

export const useAddPropertyController = (
    onPropertyAdded: (dto: CompletePropertyDto) => void,
    propertiesService = useAPIPropertieService(),
    personsService: PersonsService = useAPIPersonsService(),
    settingsService = useAPISettingsService(),
    messenger = useMessenger(),
): AddPropertyController => {
    /* State */
    const [isLoading, setIsLoading] = useState(false);
    const [personsOptions, setPersonsOptions] = useState<SelectorOption[]>([]);
    const [formInstance] = Form.useForm();
    const [provincesOptions, setProvincesOptions] = useState<SelectorOption[]>([]);
    const [selectIsOpen, setSelectIsOpen] = useState(false);

    /* Listeners */
    useEffect(() => {
        fetchPersons();
        fetchProvinces();
    }, []);

    /* View Events */
    const onFormSubmit = (inputs: unknown) => {
        setIsLoading(true);
        const input = plainToClass(PropertyDto, inputs);
        propertiesService
            .addProperty(input)
            .then((output) => {
                formInstance.resetFields();
                const parsedResult = plainToClass(CompletePropertyDto, output);
                onPropertyAdded(parsedResult);
            })
            .catch(() => {
                messenger.showErrorMessage({ key: 'add-properties.default-error' });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };
    const mapDtoPersonsToSelectorOptions = (dto: CompletePersonDto): SelectorOption => {
        return {
            value: dto.id ?? 0,
            label: `${dto.name} ${dto.lastName}` ?? '',
        };
    };
    const fetchPersons = () => {
        personsService.getAllPersons().then((dtos) => {
            setPersonsOptions(dtos.map(mapDtoPersonsToSelectorOptions));
        });
    };

    /* Private Methods */
    const validateOwners = (rule: any, value: any, callback: any) => {
        if (value.length > 3 || value.length <= 0) {
            callback('Permitidos: máximo 3 - mínimo uno');
        } else {
            callback();
        }
        return;
    };
    const fetchProvinces = () => {
        settingsService.getAllProvinces().then((dtos) => {
            setProvincesOptions(dtos.map(mapDtoProvincesToSelectorOptions));
        });
    };

    const mapDtoProvincesToSelectorOptions = (dto: ProvinceDto): SelectorOption => {
        return {
            value: dto.id ?? 0,
            label: dto.name ?? '',
        };
    };
    const onClickSelect = () => {
        setSelectIsOpen(!selectIsOpen);
    };

    const onBlurSelect = () => {
        setSelectIsOpen(false);
    };

    // Return state and events
    return {
        /* State */
        form: formInstance,
        personsOptions,
        provincesOptions,
        isLoading,
        selectIsOpen,
        /* Events */
        onFormSubmit,
        validateOwners,
        onClickSelect,
        onBlurSelect,
    };
};
