import React, { useState } from 'react';
import 'fragments/follow-up-card/follow-up-card-payment/follow-up-card-payment.scss';
import { FollowUpCardPaymentFragmentProps } from 'fragments/follow-up-card/follow-up-card-payment/interfaces';
import { useFollowUpCardPaymentController } from 'fragments/follow-up-card/follow-up-card-payment/follow-up-card-payment.controller';
import { Button, Form, Space, Tooltip, Typography } from 'antd';
import { ArrowUpOutlined, CheckCircleFilled, InfoCircleFilled } from '@ant-design/icons';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import ModalForm from '../../../components/modal-form/modal-form.component';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import CurrencyInput from 'components/currency-input/currency-input.component';

export const FollowUpCardPaymentFragment: React.FC<FollowUpCardPaymentFragmentProps> = (props) => {
    const { useController = useFollowUpCardPaymentController } = props;
    const controller = useController();
    const { translate } = useTranslator();
    const { Text } = Typography;
    // Render
    return (
        <div className={'follow-up-card-payment'}>
            <ModalForm
                className={'update-rental-price-modal-form'}
                onFinish={controller.onUpdateFormSubmit}
                form={controller.form}
                isvisible={controller.isUpdateModalFormVisible}
                isLoading={controller.isUpdateFormLoading}
                onCancel={controller.onUpdateFormCancel}
                initialValues={{ price: controller.nextRentalPrice }}
                title={translate({ key: 'follow-ups.update-rental-price' })}
            >
                <Form.Item
                    label={translate({ key: 'follow-ups.price' })}
                    labelAlign="left"
                    labelCol={{ span: '15', offset: '0' }}
                    name="price"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <CurrencyInput size={'middle'} className="input-rent-amount" placeholder={``} />
                </Form.Item>
            </ModalForm>
            <Typography.Text strong className="title-card">
                {translate({ key: 'followUp.rental' })}
            </Typography.Text>
            <br />
            <Space>
                <Tooltip
                    key={1}
                    color={'white'}
                    className={controller.upgradeable ? 'upgradeable-follow-up' : 'hidden-upgradeable-follow-up'}
                    title={
                        <div
                            className={controller.upgradeable ? 'upgradeable-text-warn' : 'hidden-upgradable-text-warn'}
                        >
                            <Text strong>
                                <span className="no-capitalize">
                                    {!controller.isOldContract || controller.isOldFollowUp
                                        ? translate({ key: 'follow-ups.default-upgrade-price' })
                                        : translate({ key: 'follow-ups.you-are-able-to-update-rental-price' })}
                                </span>
                            </Text>
                            <br />
                            {!controller.isOldContract || controller.isOldFollowUp ? (
                                <div>
                                    <Text>{translate({ key: 'follow-ups.price-from-next-month' })}</Text>
                                    <Text> ${controller.nextRentalPrice}</Text>
                                </div>
                            ) : (
                                <Text>{translate({ key: 'follow-ups.update-price' })}</Text>
                            )}
                            <br />
                            {controller.isOldContract && !controller.isOldFollowUp ? (
                                <Button onClick={() => controller.onClickEditButton()} type="link">
                                    {translate({ key: 'follow-ups.update' })}
                                </Button>
                            ) : null}
                        </div>
                    }
                >
                    <ArrowUpOutlined
                        className={
                            controller.upgradeable && controller.isOldContract && !controller.isOldFollowUp
                                ? 'edit-button'
                                : 'hidden-edit-button'
                        }
                    />
                </Tooltip>
                <Form
                    form={controller.rentalForm}
                    onFinish={controller.onEnterPayRent}
                    onBlur={controller.onBlurPayRent}
                >
                    <Form.Item
                        name="amountPaid"
                        initialValue={controller.amountPaid == 0 ? undefined : controller.amountPaid}
                    >
                        <CurrencyInput
                            size={'middle'}
                            className="input-rent-amount"
                            disabled={controller.isInputDisabled}
                            placeholder={`${controller.rentalPrice}`}
                        />
                    </Form.Item>
                </Form>

                {controller.followUpStatus === 'expired' ? (
                    <Tooltip
                        key={1}
                        color={'white'}
                        className={
                            controller.followUpStatus === 'expired'
                                ? 'upgradeable-follow-up'
                                : 'hidden-upgradeable-follow-up'
                        }
                        title={
                            <div>
                                <Text strong>
                                    {controller.charge ? (
                                        <div>
                                            <span className="no-capitalize">
                                                <Text>{translate({ key: 'follow-ups.dont-charge' })}</Text>
                                            </span>
                                        </div>
                                    ) : (
                                        <div>
                                            <span className="no-capitalize">
                                                <Text>{translate({ key: 'follow-ups.interest-price' })}</Text>
                                            </span>
                                            <br />
                                            <Text>{controller.interestPrice}</Text>
                                            <br />
                                        </div>
                                    )}
                                </Text>
                                <Checkbox defaultChecked={controller.charge} onChange={controller.ignoreCharge}>
                                    <Text>{translate({ key: 'follow-ups.ignore-for-this-month' })}</Text>
                                </Checkbox>
                            </div>
                        }
                    >
                        <InfoCircleFilled
                            className={
                                controller.paymentStatus === 'incomplete' ? 'not-paid-icon' : 'hidden-not-paid-icon'
                            }
                        />
                    </Tooltip>
                ) : (
                    <InfoCircleFilled
                        className={controller.paymentStatus === 'incomplete' ? 'not-paid-icon' : 'hidden-not-paid-icon'}
                    />
                )}
                <CheckCircleFilled
                    className={controller.paymentStatus === 'complete' ? 'is-paid-icon' : 'hidden-is-paid-icon'}
                />

                <InfoCircleFilled
                    className={
                        controller.paymentStatus === 'partial' ? 'partially-paid-icon' : 'hidden-partially-paid-icon'
                    }
                />
            </Space>
        </div>
    );
};
