import { useEffect, useState } from 'react';
import { CompleteContractDto } from 'services/contracts/contract-complete.dto';
import { ProvinceDto } from 'services/settings/settings.dto';
import { useAPISettingsService } from 'services/settings/settings.service';
import { ContractCardController } from './interfaces';
import { InformationInterestModal } from './modal-information-interest/interfaces';

export const useContractCardController = (
    contractDto: CompleteContractDto,
    settingsService = useAPISettingsService(),
): ContractCardController => {
    /* State */
    const [contract, setContract] = useState(contractDto);
    const [province, setProvince] = useState<ProvinceDto>({});
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [isInformationModal, setIsInformationModal] = useState(false);

    useEffect(() => {
        fetchProvinceById();
    }, []);

    const onEditContractPressed = () => {
        setIsEditModalVisible(true);
    };

    const onEditCanceled = () => {
        setIsEditModalVisible(false);
    };

    const onFinish = () => {
        setIsEditModalVisible(false);
    };

    const onInformationModalPressed = () => {
        setIsInformationModal(true);
    };

    const onInfromationModalCanceled = () => {
        setIsInformationModal(false);
    };

    const onCancelInformationModal = () => {
        setIsInformationModal(false);
    };

    const fetchProvinceById = () => {
        if (contract.property) {
            settingsService.getOneProvince(contract.property.province?.id ?? 0).then((dto) => {
                setProvince(dto);
            });
        }
    };

    // Return state and events
    const title =
        contract.property?.isVisible && contract.property?.alias
            ? `${contract.property?.alias} `
            : !contract.property?.isVisible && contract.property?.alias
            ? `${contract.property?.alias} (eliminada) `
            : '';

    const firstOwnerName =
        contract.property &&
        contract.property.owners &&
        contract.property.owners[0] &&
        !contract.property.owners[0].isVisible
            ? `${contract.property.owners[0].name} ${contract.property.owners[0].lastName} (eliminado)`
            : contract.property && contract.property.owners && contract.property.owners[0]
            ? `${contract.property.owners[0].name} ${contract.property.owners[0].lastName}`
            : '';
    const firstRenterName =
        contract.renters && contract.renters[0] && !contract.renters[0].isVisible
            ? `${contract.renters[0].name} ${contract.renters[0].lastName} (eliminado)`
            : contract.renters && contract.renters[0]
            ? `${contract.renters[0].name} ${contract.renters[0].lastName}`
            : undefined;

    const dateResition = contract.dateResition ? new Date(contract.dateResition) : new Date();
    const contractIsCurrent = contract.contractIsCurrent ?? false;
    const finishDate = contract.finishDate ? new Date(contract.finishDate) : new Date();

    return {
        id: contract.id ?? 0,
        title: title,
        subtitle: ` ${contract.property?.district} ` + `${contract.property?.city} - ` + `${province.name}`,
        ownerName: firstOwnerName ?? '-',
        renterName: firstRenterName ?? '-',
        dateResition,
        contract,
        setContract,
        onFinish,
        onEditCanceled,
        onEditContractPressed,
        isEditModalVisible,
        onInformationModalPressed,
        onInfromationModalCanceled,
        onCancelInformationModal,
        isInformationModal,
        contractIsCurrent,
        finishDate,
    };
};
