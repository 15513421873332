import React, { Dispatch, SetStateAction } from 'react';
import { CompleteContractDto } from 'services/contracts/contract-complete.dto';

export interface ContractContextInterface {
    contract: CompleteContractDto;
    setContract: Dispatch<SetStateAction<CompleteContractDto>>;
}

export const ContractContext = React.createContext<ContractContextInterface>({
    contract: {},
    setContract: () => {
        // Nothing to do
    },
});
