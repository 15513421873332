import React, { useState } from 'react';
import 'components/paragraph-collapsible/paragraph-collapsible.scss';
import { Typography } from 'antd';
import { useTranslator } from 'tools/view-hooks/translator-hook';

export interface ParagraphCollapsibleProps {
    rows?: number;
}

const ParagraphCollapsible: React.FC<ParagraphCollapsibleProps> = (props) => {
    const { translate } = useTranslator();
    // State
    const [isExpanded, setIsExpanded] = useState(false);
    const [key, setKey] = useState(0);

    const onExpand = () => {
        setIsExpanded(true);
        setKey((prev) => (!isExpanded ? prev : prev + 1));
    };

    const onCollapse = () => {
        setIsExpanded(false);
        setKey((prev) => (!isExpanded ? prev : prev + 1));
    };

    const paragraph = props.children as string;
    return (
        <div className={'paragraph-collapsible'}>
            <div key={key}>
                <Typography.Paragraph
                    ellipsis={{
                        rows: props.rows ?? 2,
                        expandable: paragraph.length > 10,
                        onExpand: onExpand,
                        symbol: translate({ key: 'general.see-more' }),
                    }}
                >
                    {props.children}
                </Typography.Paragraph>
                {isExpanded && <a onClick={onCollapse}>{translate({ key: 'general.see-less' })}</a>}
            </div>
        </div>
    );
};

export default ParagraphCollapsible;
