import React from 'react';
import 'components/input-search/input-search.scss';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

export interface InputSearchProps {
    onSearch: (query: string) => void;
}

const InputSearch: React.FC<InputSearchProps> = (props) => {
    // State
    const { translate } = useTranslator();

    return (
        <div className={'input-search'}>
            <Input
                onPressEnter={(event: any) => event.target && props.onSearch(event.target.value)}
                placeholder={translate({ key: 'general.search' })}
                prefix={<SearchOutlined style={{ color: 'rgba(0, 0, 0, 0.45)' }} />}
            />
        </div>
    );
};

export default InputSearch;
