//system imports
import { useEffect, useState } from 'react';
import { plainToClass } from 'class-transformer';
//third party imports
import { Form } from 'antd';
import moment from 'moment';
//custom //user defined imports
import { useMessenger } from 'tools/view-hooks/messenger-hook';
import { AddContractController, SelectorOption } from 'fragments/add-contract/interfaces';
import { PropertiesService, useAPIPropertieService } from 'services/properties/properties.service';
import { ContractDto } from 'services/contracts/contract.dto';
import { ContractsService, useAPIContractService } from 'services/contracts/contracts.service';
import { PersonsService, useAPIPersonsService } from 'services/persons/persons.service';
import { CompletePropertyDto } from 'services/properties/property-complete.dto';
import { CompletePersonDto } from 'services/persons/person-complete.dto';
import { CompleteContractDto } from 'services/contracts/contract-complete.dto';
import { useAPISettingsService } from 'services/settings/settings.service';
import { ProvinceDto } from 'services/settings/settings.dto';
import { InformationInterestModal } from 'fragments/contract-card/modal-information-interest/interfaces';

export const useAddContractController = (
    onContractAdded: (dto: CompleteContractDto) => void,
    propertiesService: PropertiesService = useAPIPropertieService(),
    personsService: PersonsService = useAPIPersonsService(),
    settingsService = useAPISettingsService(),
    contractsService: ContractsService = useAPIContractService(),
    messenger = useMessenger(),
): AddContractController => {
    const [isLoading, setIsLoading] = useState(false);
    const [selectIsOpen, setSelectIsOpen] = useState(false);
    const [formInstance] = Form.useForm();
    const [propertiesOptions, setPropertiesOptions] = useState<SelectorOption[]>([]);
    const [personsOptions, setPersonsOptions] = useState<SelectorOption[]>([]);
    const [isFileLoading, setIsFileLoading] = useState<boolean>(false);
    const [fileUrl, setFileUrl] = useState<string>('');
    const [fileId, setFileId] = useState(0);
    const [duration, setDuration] = useState(0);
    const [initialDate, setInitialDate] = useState<any>();
    const [finishDate, setFinishDate] = useState<any>();
    const [provincesOptions, setProvincesOptions] = useState<SelectorOption[]>([]);

    useEffect(() => {
        fetchProperties();
        fetchPersons();
        fetchProvinces();
        const finishDate = moment(initialDate).add(duration, 'M').subtract(1, 'd');
        setFinishDate(finishDate);
    }, [duration, initialDate]);

    const onFormSubmit = (inputs: any) => {
        if (inputs.newProperty && inputs.newProperty.newOwner && inputs.newProperty.newOwner.numberDoc) {
            const dni = inputs.newProperty.newOwner.numberDoc.split('.');
            inputs.newProperty.newOwner.numberDoc = dni.join('');
        }
        setIsLoading(true);
        const propertyId = inputs.propertyId == -1 ? null : inputs.propertyId;
        [inputs.owners];
        delete inputs.date;
        const dto = plainToClass(ContractDto, inputs);
        dto.initialDate = initialDate;
        dto.finishDate = finishDate;
        dto.durationMonths = inputs.durationMonths;
        dto.propertyId = propertyId;
        dto.percentageByAdministration = Number(inputs.percentageByAdministration);
        dto.updateFrequency = Number(inputs.updateFrequency);
        if (fileId !== 0) {
            dto.fileId = fileId;
        }

        contractsService
            .addContract(dto)
            .then((response) => {
                formInstance.resetFields();
                setFileId(0);
                setFileUrl('');
                setIsFileLoading(false);
                onContractAdded(response);
            })
            .catch((err) => {
                if (err.response.data.detail && err.response.data.detail.indexOf('have') !== -1) {
                    messenger.showErrorMessage({ key: 'add-contracts.new-contract-error' });
                } else {
                    messenger.showErrorMessage({ key: 'add-contracts.default-error' });
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };
    const mapPropertiesDtoToSelectorOptions = (dto: CompletePropertyDto): SelectorOption => {
        return {
            value: dto.id ?? 0,
            label: dto.alias ?? '',
        };
    };
    const mapDtoPersonsToSelectorOptions = (dto: CompletePersonDto): SelectorOption => {
        return {
            value: dto.id ?? 0,
            label: `${dto.name} ${dto.lastName}` ?? '',
        };
    };

    const fetchProperties = () => {
        propertiesService.getAllProperties().then((dtos) => {
            setPropertiesOptions(dtos.map(mapPropertiesDtoToSelectorOptions));
        });
    };
    const fetchPersons = () => {
        personsService.getAllPersons().then((dtos) => {
            setPersonsOptions(dtos.map(mapDtoPersonsToSelectorOptions));
        });
    };
    const validateRenters = (rule: any, value: any, callback: any) => {
        if (value.length > 10 || value.length <= 0) {
            callback('Permitidos: máximo diez - minimo uno');
        } else {
            callback();
        }
        return;
    };
    const validateGuarantors = (rule: any, value: any, callback: any) => {
        if (value.length > 10 || value.length <= 0) {
            callback('Permitidos: máximo diez - minimo uno');
        } else {
            callback();
        }
        return;
    };
    const validateOwners = (rule: any, value: any, callback: any) => {
        if (value.length > 3 || value.length <= 0) {
            callback('Permitidos: máximo 3 - mínimo uno');
        } else {
            callback();
        }
        return;
    };

    const validateProperty = (rule: any, value: any, callback: any) => {
        if (value.length > 1 || value.length <= 0) {
            callback('Permitidos: máximo 3 - mínimo uno');
        } else {
            callback();
        }
        return;
    };

    const validateUpdateFrequency = (rule: any, value: any, callback: any) => {
        if (value.length > 1 || value.length <= 0) {
            callback('Solo se puede elegir un período de actualización');
        } else {
            callback();
        }
        return;
    };
    const handleChange = (info: any) => {
        if (info.file.status === 'uploading') {
            setIsFileLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            const newUrl = info.file.response;
            const newFileId = info.file.response.id;
            setFileId(newFileId);
            setFileUrl(newUrl);
            setIsFileLoading(false);
        } else {
            setFileId(0);
            setFileUrl('');
            setIsFileLoading(false);
        }
    };
    const initialDateChange = (value: any) => {
        setInitialDate(value);
    };

    const durationChange = (value: any) => {
        setDuration(value);
    };

    const onCancel = () => {
        setFileId(0);
        setFileUrl('');
        setIsFileLoading(false);
        formInstance.resetFields();
    };

    const fetchProvinces = () => {
        settingsService.getAllProvinces().then((dtos) => {
            setProvincesOptions(dtos.map(mapDtoProvincesToSelectorOptions));
        });
    };

    const mapDtoProvincesToSelectorOptions = (dto: ProvinceDto): SelectorOption => {
        return {
            value: dto.id ?? 0,
            label: dto.name ?? '',
        };
    };

    const initialValuesInformationInterest: InformationInterestModal = {
        expiredDay: 10,
        initialDayInterest: 1,
        typeOfCharge: 'percentage',
        debtCollection: 0,
    };

    const onClickSelect = () => {
        setSelectIsOpen(!selectIsOpen);
    };

    const onBlurSelect = () => {
        setSelectIsOpen(false);
    };

    return {
        form: formInstance,
        isLoading,
        propertiesOptions: propertiesOptions,
        personsOptions: personsOptions,
        isFileLoading,
        fileUrl,
        fileId,
        handleChange,
        onFormSubmit,
        validateRenters: validateRenters,
        validateGuarantors: validateGuarantors,
        validateUpdateFrequency: validateUpdateFrequency,
        validateProperty,
        onCancel,
        durationChange,
        duration,
        validateOwners,
        initialDateChange,
        initialDate,
        finishDate,
        provincesOptions,
        initialValues: initialValuesInformationInterest,
        onClickSelect,
        selectIsOpen,
        onBlurSelect,
    };
};
