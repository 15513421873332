import axios from 'axios';
import { plainToClass } from 'class-transformer';
import { Session } from 'auth/models/session';
import { UpdateNotificationDto } from './updateNotification.dto';
import { NotificationsService } from './notifications.service';
import { CompleteNotificationsDto } from './complete-notifications.dto';

export class ApiNotificationsService implements NotificationsService {
    constructor(private session: Session) {}
    async getAllNotifications(): Promise<CompleteNotificationsDto[]> {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/notifications/`, {
            headers: { Authorization: `Bearer ${this.session.getToken()}` },
        });
        return response.data.map((item: any) => plainToClass(CompleteNotificationsDto, item));
    }

    async updateNotifications(input: UpdateNotificationDto): Promise<CompleteNotificationsDto> {
        const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/notifications`, input, {
            headers: { Authorization: `Bearer ${this.session.getToken()}` },
        });
        return plainToClass(CompleteNotificationsDto, response.data);
    }
}
