import { useContext, useEffect, useState } from 'react';

import { Form } from 'antd';
import moment from 'moment';

import {
    EditContractDetailsFormValues,
    EditContractsDetailsController,
} from 'fragments/contract-card/contract-details/edit-contracts-details/interfaces';
import { ContractContext } from 'fragments/contract-card/contract-card.component';
import { SelectorOption } from 'fragments/add-property/interfaces';
import { CompletePersonDto } from 'services/persons/person-complete.dto';
import { useAPIContractService } from 'services/contracts/contracts.service';
import { PersonsService, useAPIPersonsService } from 'services/persons/persons.service';
import { ProvinceDto } from 'services/settings/settings.dto';
import { useAPISettingsService } from 'services/settings/settings.service';
import { useMessenger } from 'tools/view-hooks/messenger-hook';
export const useEditContractsDetailsController = (
    onFinish: () => void,
    contractService = useAPIContractService(),
    personsService: PersonsService = useAPIPersonsService(),
    messenger = useMessenger(),
    settingsService = useAPISettingsService(),
): /* <--Dependency Injections  like services hooks */
EditContractsDetailsController => {
    const [isLoading, setIsLoading] = useState(false);
    const { contract, setContract } = useContext(ContractContext);
    const [formInstance] = Form.useForm();
    const [personsOptions, setPersonsOptions] = useState<SelectorOption[]>([]);
    const [provincesOptions, setProvincesOptions] = useState<SelectorOption[]>([]);

    /* Listeners */
    const [isFileLoading, setIsFileLoading] = useState<boolean>(false);
    const [selectIsOpen, setSelectIsOpen] = useState<boolean>(false);
    const [fileUrl, setFileUrl] = useState<string>('');
    const [fileId, setFileId] = useState(0);
    const [duration, setDuration] = useState(contract.durationMonths ?? 0);
    const [initialDate, setInitialDate] = useState<any>(moment(contract.initialDate));
    const [finishDate, setFinishDate] = useState<any>(
        moment(contract.initialDate).add(contract.durationMonths, 'M').subtract(1, 'd'),
    );

    useEffect(() => {
        fetchPersons();
        fetchProvinces();
        const finishDate = moment(initialDate ?? contract.initialDate)
            .add(duration, 'M')
            .subtract(1, 'd');
        setFinishDate(finishDate);
        if (contract.contractFile?.file.fileUrl) {
            setFileUrl(contract.contractFile?.file.fileUrl);
        }
    }, [duration, initialDate]);

    /* View Events */
    const onFormSubmit = (inputs: EditContractDetailsFormValues) => {
        if (!contract.id) {
            return;
        }
        const guarantorsId = !inputs.guarantorsId[0] ? undefined : inputs.guarantorsId;
        setIsLoading(true);
        contractService
            .updateContractDetails(
                {
                    ...inputs,
                    fileId,
                    finishDate,
                    initialDate: inputs.date.toDate(),
                    guarantorsId,
                    percentageByAdministration: Number(inputs.percentageByAdministration),
                },
                contract.id,
            )
            .then((output) => {
                fetchPersons();
                setContract((prevState) => {
                    return {
                        ...prevState,
                        ...output,
                        guarantors: output.guarantors,
                        fileId: output.fileId,
                        contractFile: output.contractFile,
                    };
                });
                formInstance.resetFields();
                onFinish();
            })
            .catch(() => {
                messenger.showErrorMessage({ key: 'properties.add-property-detail-error' });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };
    /* Private Methods */

    const mapDtoPersonsToSelectorOptions = (dto: CompletePersonDto): SelectorOption => {
        return {
            value: dto.id ?? 0,
            label: `${dto.name} ${dto.lastName}` ?? '',
        };
    };

    const setPersonsOptionsDontVisible = (dtos: SelectorOption[]) => {
        const dontVisible = contract.guarantors
            ? contract.guarantors.filter((guarantor) => !guarantor.isVisible ?? 0)
            : [];
        if (dontVisible[0]) {
            for (const guarantor of dontVisible) {
                const name = guarantor.name ?? '';
                const lastName = guarantor.lastName ?? '';
                const label = name + ' ' + lastName + ' ' + '(eliminado/a)';
                const option = { value: guarantor.id ?? 0, label };
                const dto = [...dtos, option];
                setPersonsOptions(dto);
            }
        }
    };

    const fetchPersons = () => {
        personsService.getAllPersons().then((dtos) => {
            const dto = dtos.map(mapDtoPersonsToSelectorOptions);
            setPersonsOptions(dto);
            setPersonsOptionsDontVisible(dto);
        });
    };

    /* Private Methods */
    const validateGuarantors = (rule: any, value: any, callback: any) => {
        if (value.length > 10 || value.length <= 0) {
            callback('Permitidos: máximo diez - minimo uno');
        } else {
            callback();
        }
        return;
    };

    const initialDateChange = (value: any) => {
        setInitialDate(value);
    };

    const durationChange = (value: any) => {
        setDuration(value);
    };

    const onCancel = () => {
        setFileId(0);
        setFileUrl('');
        setIsFileLoading(false);
    };

    const validateUpdateFrequency = (rule: any, value: any, callback: any) => {
        if (value.length > 1 || value.length <= 0) {
            callback('Solo se puede elegir un período de actualización');
        } else {
            callback();
        }
        return;
    };
    const handleChange = (info: any) => {
        if (info.file.status === 'uploading') {
            setIsFileLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            const newUrl = info.file.response;
            const newFileId = info.file.response.id;
            setFileId(newFileId);
            setFileUrl(newUrl);
            setIsFileLoading(false);
        } else {
            setFileId(0);
            setFileUrl('');
            setIsFileLoading(false);
        }
    };

    const onFileDeleted = () => {
        contractService
            .deleteContractFile(contract.id ?? 0)
            .then((resp) => {
                setContract({ ...contract, contractFile: undefined });
                setFileId(0);
            })
            .catch((err) => console.log(err));
    };

    const mapToGuarantorsId =
        contract.guarantors && contract.guarantors[0]
            ? contract.guarantors.map((guarantors) => guarantors.id ?? 0)
            : [];

    const mapToReturnsInitialValues = () => {
        const initialDateContract = new Date(contract.initialDate ? contract.initialDate : '');
        const startDateContract = initialDateContract.getMonth() + 1 + '/' + initialDateContract.getFullYear();
        const input: EditContractDetailsFormValues = {
            id: contract.id ?? 0,
            percentageByAdministration: contract.percentageByAdministration ?? 0,
            startDateContract: startDateContract ?? '',
            durationMonths: contract.durationMonths ?? 0,
            periodicity: contract.updateFrequency ?? 0,
            startPrice: contract.startPrice ?? 0,
            file: contract.contractFile?.file.fileUrl ?? '',
            guarantorsId: mapToGuarantorsId ?? [],
            date: moment(initialDateContract),
            finishDate: moment(contract.finishDate),
            updateFrequency: contract.updateFrequency ?? 0,
            amountsActualizations: contract.rentalUpdates ?? null,
            amountsActualizationsNew: contract.rentalUpdates ?? null,
        };

        return input;
    };

    const fetchProvinces = () => {
        settingsService.getAllProvinces().then((dtos) => {
            setProvincesOptions(dtos.map(mapDtoProvincesToSelectorOptions));
        });
    };

    const mapDtoProvincesToSelectorOptions = (dto: ProvinceDto): SelectorOption => {
        return {
            value: dto.id ?? 0,
            label: dto.name ?? '',
        };
    };

    const onClickSelect = () => {
        setSelectIsOpen(!selectIsOpen);
    };

    const onBlurSelect = () => {
        setSelectIsOpen(false);
    };

    // Return state and events
    return {
        form: formInstance,
        isLoading,
        initialValues: mapToReturnsInitialValues(),
        onFormSubmit,
        personsOptions,
        validateGuarantors,
        isFileLoading,
        fileUrl: mapToReturnsInitialValues().file,
        fileId,
        handleChange,
        validateUpdateFrequency: validateUpdateFrequency,
        onCancel,
        durationChange,
        duration,
        initialDateChange,
        initialDate,
        finishDate,
        onFileDeleted,
        isOldContract: contract.isNewLawContract ?? false,
        provincesOptions,
        onClickSelect,
        selectIsOpen,
        onBlurSelect,
    };
};
