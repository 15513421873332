import React from 'react';
import { Button, Modal, Typography } from 'antd';
import 'fragments/follow-up-card/follow-up-card-taxes/follow-up-card-taxes.scss';
import { FollowUpCardTaxesFragmentProps } from 'fragments/follow-up-card/follow-up-card-taxes/interfaces';
import { useFollowUpCardTaxesController } from 'fragments/follow-up-card/follow-up-card-taxes/follow-up-card-taxes.controller';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { PlusOutlined } from '@ant-design/icons';
import { FollowUpCardServicesPaymentFragment } from 'fragments/follow-up-card/follow-up-card-services-payment/follow-up-card-services-payment.fragment';
import { CreateEditLiabilityFragment } from 'fragments/property-card/create-edit-liability/create-edit-liability.fragment';

export const FollowUpCardTaxesFragment: React.FC<FollowUpCardTaxesFragmentProps> = (props) => {
    const { useController = useFollowUpCardTaxesController } = props;
    const controller = useController();
    const { translate } = useTranslator();
    const { Text } = Typography;

    // Render
    return (
        <div className={'follow-up-card-taxes'}>
            <Text strong> {translate({ key: 'followUp.services-and-expenses' })} </Text>
            <Button
                icon={<PlusOutlined />}
                style={{ background: 'none', border: 'none', fontSize: 13, color: '#458be9' }}
                onClick={controller.onClickAddService}
            />
            <br />
            <Modal
                destroyOnClose
                title={'Agregar servicio o gasto'}
                visible={controller.isAddServiceVisible}
                onOk={controller.onClickOkayInAddServiceModal}
                onCancel={controller.onClickCancelInAddServiceModal}
            >
                <CreateEditLiabilityFragment
                    onLiabilityAdded={controller.onLiabilityAdded}
                    createForFollowUp={controller.followUp}
                    form={controller.form}
                />
            </Modal>
            <FollowUpCardServicesPaymentFragment />
        </div>
    );
};
