import React from 'react';
import 'fragments/property-card/list-property-services/list-property-services.scss';
import { Button, Col, Modal, PageHeader, Popconfirm, Row, Space, Typography } from 'antd';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useListPropertyServicesController } from './list-property-services.controller';
import { ListPropertyServicesFragmentProps } from './interfaces';
import { CreateEditLiabilityFragment } from 'fragments/property-card/create-edit-liability/create-edit-liability.fragment';

export const ListPropertyServicesFragment: React.FC<ListPropertyServicesFragmentProps> = (props) => {
    const { useController = useListPropertyServicesController } = props;
    const controller = useController(props.onCancel);

    const { translate } = useTranslator();

    const isCreatingOrEditing = controller.isCreateNewServiceVisible || controller.isEditSingleServiceVisible;

    const title = () => {
        if (controller.isCreateNewServiceVisible) {
            return translate({ key: 'property-services.add-service' });
        }
        if (controller.isEditSingleServiceVisible) {
            return translate({ key: 'property-services.edit-service' });
        }
        return translate({ key: 'property-services.services-and-expenses' });
    };

    const footer = () => {
        if (controller.isCreateNewServiceVisible || controller.isEditSingleServiceVisible) {
            return undefined;
        }
        return null;
    };

    // Render
    return (
        <Modal
            className={'edit-property-services'}
            destroyOnClose={true}
            visible={props.isVisible}
            onCancel={controller.onCancel}
            onOk={
                !(controller.isEditSingleServiceVisible || controller.isCreateNewServiceVisible)
                    ? props.onFinished
                    : controller.onFormSubmit
            }
            footer={footer()}
            title={
                <PageHeader
                    className={'liabilities-modal-header'}
                    onBack={isCreatingOrEditing ? controller.onCreateEditBack : undefined}
                    title={title()}
                    extra={
                        !isCreatingOrEditing
                            ? [
                                  <Button
                                      className={'create-liability-button'}
                                      key={1}
                                      onClick={controller.onClickCreate}
                                      type={'primary'}
                                  >
                                      Crear Nuevo
                                  </Button>,
                              ]
                            : []
                    }
                />
            }
        >
            {!(controller.isEditSingleServiceVisible || controller.isCreateNewServiceVisible) ? (
                controller.liabilities?.length == 0 ? (
                    <Typography.Text>{translate({ key: 'properties.no-services' })}</Typography.Text>
                ) : (
                    controller.liabilities?.map((itemView) => {
                        return (
                            <Row className={'liability-edit-item'} key={itemView.id}>
                                <Col xs={24} sm={18}>
                                    <Typography.Title className={'liability-title'} level={5}>
                                        {itemView.title}
                                    </Typography.Title>
                                    <Typography>{itemView.subtitle}</Typography>
                                </Col>

                                <Col xs={24} sm={6}>
                                    <Space>
                                        <Button
                                            icon={<EditOutlined />}
                                            onClick={() => {
                                                controller.onClickEdit(itemView.id ?? 0);
                                            }}
                                            className={'services-action-buttons'}
                                        />
                                        <Popconfirm
                                            title="Estas seguro que deseas eliminar este servicio?"
                                            onConfirm={() => {
                                                controller.onClickDelete(itemView.id ?? 0);
                                            }}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button className={'services-action-buttons'} icon={<DeleteOutlined />} />
                                        </Popconfirm>
                                    </Space>
                                </Col>
                            </Row>
                        );
                    })
                )
            ) : !controller.isEditSingleServiceVisible ? (
                <CreateEditLiabilityFragment createForPropertyId={controller.propertyId} form={controller.form} />
            ) : (
                <CreateEditLiabilityFragment editValues={controller.formInitialValues} form={controller.form} />
            )}
        </Modal>
    );
};
