import React from 'react';
import 'fragments/notifications/notifications.scss';
import { NotificationsFragmentProps } from 'fragments/notifications/interfaces';
import { useNotificationsController } from 'fragments/notifications/notifications.controller';
import { Button, Card, Switch, Typography, Row, Col } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { Header } from 'antd/lib/layout/layout';
import { MailHtmlFragment } from './mail-html/mail-html.fragment';
import { TypeNotifications } from 'services/notifications/type-notifications.enum';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import FlexLoader from '../../components/flex-loader/flex-loader.component';

export const NotificationsFragment: React.FC<NotificationsFragmentProps> = (props) => {
    const { useController = useNotificationsController } = props;
    const controller = useController();
    const { translate } = useTranslator();

    const data = {
        increase: translate({ key: 'notifications.increase-title' }),
        expired: translate({ key: 'notifications.expired-title' }),
        requeriments: translate({ key: 'notifications.requeriments-title' }),
        news: translate({ key: 'notifications.news-title' }),
        observations: translate({ key: 'notifications.observations-title' }),
    };
    const notificationBodyText = {
        increase: '',
        expired: '',
        requeriments: '',
        news: translate({ key: 'notifications.news-text' }),
        observations: translate({ key: 'notifications.observations-text' }),
    };

    const onChangesCheckBox = (checked: any, rol: string, type: string) => {
        controller.onCheckBoxPressed({ rol, checked: checked.target.checked, type });
    };

    const onChangeSwitch = (checked: boolean, type: string, rol: string) => {
        controller.onSwitchPressed({ checked, type, rol });
    };
    // Render

    return (
        <div className={'notifications'}>
            <MailHtmlFragment
                isVisible={controller.isMailHtmlVisible}
                onCancel={controller.onMailHtmlCanceled}
                onMailHtmlAdded={controller.onMailHtmlAdded}
            />
            {controller.isLoading ? (
                <FlexLoader />
            ) : (
                <Row>
                    <Col xs={24}>
                        <Header>
                            <Typography.Title level={4}>
                                {translate({ key: 'notifications.what-to-send' })}
                            </Typography.Title>
                        </Header>
                        <Card
                            className="card-head"
                            title="Mails"
                            extra={
                                <Row gutter={10}>
                                    <Col xs={12} className={'ch-column'}>
                                        <Typography.Text style={{ fontWeight: 'bold' }}>
                                            {translate({ key: 'properties.owners' })}
                                        </Typography.Text>
                                    </Col>
                                    <Col xs={12} className={'ch-column'}>
                                        <Typography.Text style={{ fontWeight: 'bold' }}>
                                            {translate({ key: 'contracts.renters' })}
                                        </Typography.Text>
                                    </Col>
                                </Row>
                            }
                        >
                            {controller.notifications &&
                                controller.notifications
                                    .sort((a, b) => a.id! - b.id!)
                                    .map((element) => (
                                        <Card bordered={false} key={element.id}>
                                            <div>
                                                <Row gutter={10}>
                                                    <Col xs={10}>
                                                        <Typography.Text strong={true}>
                                                            {data[element.type ?? 'increase']}
                                                        </Typography.Text>
                                                        <Button
                                                            type="link"
                                                            style={{ fontSize: 13 }}
                                                            onClick={() =>
                                                                controller.onMailHtmlPressed({
                                                                    type: element.type ?? '',
                                                                    title: data[element.type ?? 'increase'],
                                                                })
                                                            }
                                                        >
                                                            {translate({ key: 'notifications.see-mail' })}
                                                        </Button>
                                                    </Col>
                                                    <Col xs={7} className={'ch-column'}>
                                                        <Checkbox
                                                            defaultChecked={element.owners}
                                                            disabled={
                                                                element.type === TypeNotifications.REQUERIMENTS
                                                                    ? true
                                                                    : false
                                                            }
                                                            onChange={(checked) =>
                                                                onChangesCheckBox(checked, 'owners', element.type ?? '')
                                                            }
                                                        />
                                                    </Col>
                                                    <Col xs={7} className={'ch-column'}>
                                                        <Checkbox
                                                            defaultChecked={element.renters}
                                                            onChange={(checked) =>
                                                                onChangesCheckBox(
                                                                    checked,
                                                                    'renters',
                                                                    element.type ?? '',
                                                                )
                                                            }
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Card>
                                    ))}
                        </Card>
                        <div className={'reports'}>
                            <Header>
                                <Typography.Title level={4}>
                                    {translate({ key: 'notifications.what-notices-receive' })}
                                </Typography.Title>
                            </Header>
                            {controller.notificationUser &&
                                controller.notificationUser
                                    .sort((a, b) => a.id! - b.id!)
                                    .map((element) => (
                                        <div key={element.id}>
                                            <Card bordered={false}>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center',
                                                        padding: '5px',
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            textAlign: 'left',
                                                            paddingRight: '20px',
                                                            lineHeight: '1.3em',
                                                        }}
                                                    >
                                                        <Typography.Text strong={true} style={{ marginBottom: '10px' }}>
                                                            {data[element.type ?? 'news']}
                                                        </Typography.Text>
                                                        <Typography.Text>
                                                            {notificationBodyText[element.type ?? 'observations']}
                                                        </Typography.Text>
                                                    </div>
                                                    <div>
                                                        <Switch
                                                            defaultChecked={element.userRealEstate}
                                                            onChange={(value) =>
                                                                onChangeSwitch(value, element.type ?? '', 'user')
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    ))}
                        </div>
                    </Col>
                </Row>
            )}
        </div>
    );
};
