import React from 'react';
import 'auth/components/button-logout/button-logout.scss';
import { Button, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocalSession } from 'auth/helpers/session.hooks';

export interface ButtonLogoutProps {
    color: string;
}

const ButtonLogout: React.FC<ButtonLogoutProps> = (props) => {
    const { t } = useTranslation();
    const [, , killSession] = useLocalSession();

    return (
        <div className={'button-logout'}>
            <Button
                type="link"
                onClick={() => {
                    killSession();
                }}
            >
                <Typography.Text className="text-logout">{t('general.logout')}</Typography.Text>
            </Button>
        </div>
    );
};

export default ButtonLogout;
