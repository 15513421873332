import { useLocalSession } from 'auth/helpers/session.hooks';
import { ApiSettingsService } from './api-settings.service';
import { ProvinceDto, RealEstateDto, RealEstateInputDto } from './settings.dto';

export interface SettingsService {
    editSettings(input: RealEstateInputDto): Promise<RealEstateDto>;
    getAllProvinces(): Promise<ProvinceDto[]>;
    getOneProvince(provinceId: number): Promise<ProvinceDto>;
    getRealEstate(): Promise<RealEstateDto>;
}

export const useAPISettingsService = (): SettingsService => {
    const [getSession] = useLocalSession();
    const session = getSession();
    return new ApiSettingsService(session);
};
