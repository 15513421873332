import { CompletePersonDto } from 'services/persons/person-complete.dto';
import { ContractDto } from './contract.dto';
import { ObservationDto } from 'services/observations/observation.dto';
import { CompletePropertyDto } from 'services/properties/property-complete.dto';

export class CompleteContractDto extends ContractDto {
    id?: number;
    startPrice?: number;
    initialDate?: Date;
    finishDate?: Date;
    durationMonths?: number;
    property?: CompletePropertyDto;
    renters?: CompletePersonDto[];
    guarantors?: CompletePersonDto[];
    updateFrequency?: number;
    percentageByAdministration?: number;
    contractFile?: { file: FileDto };
    observations?: ObservationDto[];
    rentalUpdates?: any[];
    isNewLawContract?: boolean;
    contractIsCurrent?: boolean;
}
export class FileDto {
    id?: number;
    fileUrl?: string;
    fileKey?: string;
}
