import { useLocalSession } from 'auth/helpers/session.hooks';
import { MultipleRentalPaymentsDto, RentalPaymentDto } from './rentalPayment.dto';
import { ApiRentalPaymentService } from './api-rentalPaymentsService';
import { FollowUpDto } from '../followUps/followUp.dto';
import { DownloadableItem } from './receipt.dto';

export interface RentalPaymentService {
    onPaymentChange(input: RentalPaymentDto): Promise<FollowUpDto>;
    addMultiplePayments(input: MultipleRentalPaymentsDto[]): Promise<FollowUpDto[]>;
    onIssueReceipt(input: { followUpIds: number[] }): Promise<DownloadableItem[]>;
    onIssueSettlements(input: {
        followUpIds: number[];
        allSelected: boolean;
    }): Promise<{ selectedFollowUpsByOwner: DownloadableItem[]; currentMonthFollowUpsByOwner: DownloadableItem[] }>;
}

export const useApiRentalPaymentService = (): RentalPaymentService => {
    const [getSession] = useLocalSession();
    const session = getSession();
    return new ApiRentalPaymentService(session);
};
