import React, { Dispatch, SetStateAction } from 'react';
import { CompleteFollowUpDto } from 'services/followUps/followUp.dto';

export interface FollowUpContextInterface {
    canPay: boolean;
    followUp: CompleteFollowUpDto;
    setFollowUp: Dispatch<SetStateAction<CompleteFollowUpDto>>;
}

export const FollowUpContext = React.createContext<FollowUpContextInterface>({
    canPay: false,
    followUp: {},
    setFollowUp: () => {
        // Nothing to do
    },
});
