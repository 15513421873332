import React from 'react';
import 'fragments/property-card/create-edit-liability/create-edit-liability.scss';
import { Input, Form, Select, Radio, Checkbox, DatePicker, Divider } from 'antd';
import { fixSelectOnScroll } from 'tools/view-hooks/fix-autocomplete';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { AritmeticOperation, LiabilityPayerType, LiabilityPaymentTerm } from 'services/liabilities/liabilities.service';
import { useCreateEditLiabilityController } from 'fragments/property-card/create-edit-liability/create-edit-liability.controller';
import { CreateEditLiabilityFragmentProps } from 'fragments/property-card/create-edit-liability/interfaces';

const { RangePicker } = DatePicker;

const { TextArea } = Input;
export const CreateEditLiabilityFragment: React.FC<CreateEditLiabilityFragmentProps> = (props) => {
    const { useController = useCreateEditLiabilityController } = props;
    const controller = useController(
        props.form,
        props.editValues,
        props.createForPropertyId,
        props.createForFollowUp,
        props.onLiabilityAdded,
    );

    const { translate } = useTranslator();

    // Render
    return (
        <div className={'create-property-service'}>
            <Form
                onFinish={controller.onFormSubmit}
                form={props.form}
                className={'edit-property-services'}
                initialValues={controller.formInitialValues}
            >
                <Form.Item name="uniqueOrRecurrent" label={'Tipo'} rules={[{ required: true }]}>
                    <Radio.Group
                        buttonStyle="solid"
                        disabled={controller.isUniqueOrRecurrentDisabled}
                        onChange={(e) => controller.handleUniqueOrRecurrentChange(e.target.value)}
                        size={'small'}
                    >
                        <Radio.Button value="unique">Único</Radio.Button>
                        <Radio.Button value="recurrent">Recurrente</Radio.Button>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    name="serviceTypeId"
                    label={translate({ key: 'property-services.service-type' })}
                    rules={[{ required: true }]}
                >
                    <Select
                        placeholder={translate({ key: 'property-services.select-a-service' })}
                        getPopupContainer={fixSelectOnScroll}
                        size={'middle'}
                    >
                        {controller.typeServiceList.map((service) => (
                            <Select.Option key={service.id} value={service.id}>
                                {service.typeServiceTitle}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="name"
                    label={translate({ key: 'property-services.name' })}
                    rules={[{ required: true, message: 'Porfavor ingrese un nombre al servicio' }]}
                >
                    <Input placeholder={translate({ key: 'property-services.enter-service-name' })} size={'middle'} />
                </Form.Item>

                <Form.Item name="payerType" label={'A pagar por'}>
                    <Radio.Group buttonStyle="solid" size={'small'}>
                        <Radio.Button value={LiabilityPayerType.OWNER}>Propietario</Radio.Button>
                        <Radio.Button value={LiabilityPayerType.RENTER}>Inquilino</Radio.Button>
                    </Radio.Group>
                </Form.Item>

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Form.Item name="showInSettlement" valuePropName={'checked'} style={{ marginBottom: 0 }}>
                        <Checkbox>Mostrar en la liquidación</Checkbox>
                    </Form.Item>
                    <Form.Item shouldUpdate={(prev, curr) => prev.showInSettlement != curr.showInSettlement}>
                        {({ getFieldValue }) => {
                            const show = getFieldValue('showInSettlement');
                            return (
                                show && (
                                    <Form.Item name="settlementOperation">
                                        <Radio.Group buttonStyle="solid" size="small">
                                            <Radio.Button value={AritmeticOperation.SUBSTRACT}>Restando</Radio.Button>
                                            <Radio.Button value={AritmeticOperation.ADD}>Sumando</Radio.Button>
                                        </Radio.Group>
                                    </Form.Item>
                                )
                            );
                        }}
                    </Form.Item>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Form.Item name="showInReceipt" valuePropName={'checked'}>
                        <Checkbox>Mostrar en el recibo</Checkbox>
                    </Form.Item>
                    <Form.Item shouldUpdate={(prev, curr) => prev.showInReceipt != curr.showInReceipt}>
                        {({ getFieldValue }) => {
                            const show = getFieldValue('showInReceipt');
                            return (
                                show && (
                                    <Form.Item name="receiptOperation">
                                        <Radio.Group buttonStyle="solid" size="small">
                                            <Radio.Button value={AritmeticOperation.SUBSTRACT}>Restando</Radio.Button>
                                            <Radio.Button value={AritmeticOperation.ADD}>Sumando</Radio.Button>
                                        </Radio.Group>
                                    </Form.Item>
                                )
                            );
                        }}
                    </Form.Item>
                </div>

                <Form.Item name="defaultAmount" label={'Monto por defecto'}>
                    <Input type={'number'} addonBefore="$" placeholder="Ingresar monto por defecto" size={'middle'} />
                </Form.Item>

                <Form.Item name="observation">
                    <TextArea placeholder={'Observación'} size={'middle'} />
                </Form.Item>

                {controller.showRecurrentInputs && (
                    <>
                        <Divider />
                        <Form.Item name="paymentTerm" label={'Tipo de pago'}>
                            <Radio.Group buttonStyle="solid" size={'small'} disabled={controller.isPaymentTermDisabled}>
                                <Radio.Button value={LiabilityPaymentTerm.CURRENT_MONTH}>Mes Corriente</Radio.Button>
                                <Radio.Button value={LiabilityPaymentTerm.EXPIRED_MONTH}>Mes Vencido</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item noStyle shouldUpdate={(curr, prev) => curr.paymentTerm != prev.paymentTerm}>
                            {(values) => {
                                const isContractExpiredTerm =
                                    values.getFieldValue('paymentTerm') == LiabilityPaymentTerm.EXPIRED_MONTH &&
                                    !controller.isIndeterminateTimeChecked;
                                return (
                                    <Form.Item
                                        label={'Período'}
                                        help={
                                            isContractExpiredTerm
                                                ? "Al seleccionar 'Mes Vencido' recuerde seleccionar el periodo en el cual quiere imputar los pagos. Ej. En julio imputaría un pago de junio"
                                                : ''
                                        }
                                    >
                                        <Form.Item
                                            name="monthDuration"
                                            rules={[
                                                {
                                                    required: !controller.isRangeTimeDisabled,
                                                    message: 'Porfavor ingresar rango de fechas',
                                                },
                                            ]}
                                        >
                                            <RangePicker
                                                format={'YYYY/MM'}
                                                picker="month"
                                                disabled={controller.isRangeTimeDisabled}
                                                size={'middle'}
                                            />
                                        </Form.Item>
                                        <Checkbox
                                            className={'indeterminate-time-checkbox'}
                                            checked={controller.isIndeterminateTimeChecked}
                                            onChange={(e) => controller.handleIndeterminateTimeChange(e.target.checked)}
                                            disabled={controller.isIndeterminateTimeDisabled}
                                        >
                                            Tiempo Indeterminado
                                        </Checkbox>
                                    </Form.Item>
                                );
                            }}
                        </Form.Item>
                    </>
                )}
            </Form>
        </div>
    );
};
