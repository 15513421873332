import { useContext, useEffect, useState } from 'react';
import { FollowUpCardTaxesController } from 'fragments/follow-up-card/follow-up-card-taxes/interfaces';
import { FollowUpContext } from 'fragments/follow-up-card/follow-up.context';
import { Form } from 'antd';
import {
    FollowupLiabilityDto,
    LiabilitiesService,
    LiabilityDto,
    useAPILiabilitiesService,
} from 'services/liabilities/liabilities.service';

export const useFollowUpCardTaxesController = (
    liabilitiesService: LiabilitiesService = useAPILiabilitiesService(),
): FollowUpCardTaxesController => {
    /* State */
    const { followUp, setFollowUp } = useContext(FollowUpContext);
    const [isAddServiceVisible, setIsAddServiceVisible] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        if (!followUp.liabilities) {
            fetchLiabilities();
        }
    }, [followUp]);

    const onClickOkayInAddServiceModal = async () => {
        form.submit();
    };

    const onLiabilityAdded = () => {
        fetchLiabilities();
        setIsAddServiceVisible(false);
    };

    const onClickCancelInAddServiceModal = () => {
        setIsAddServiceVisible(false);
    };

    const onClickAddService = () => {
        setIsAddServiceVisible(true);
    };

    /* Private Methods */

    const fetchLiabilities = () => {
        if (!followUp.id) {
            return;
        }
        liabilitiesService
            .getAllFollowUpLiabilities(followUp.id)
            .then((liabilitiesDtos) => {
                setFollowUp((prevState) => {
                    return { ...prevState, liabilities: liabilitiesDtos };
                });
            })
            .catch(() => {
                // Show error ?
            });
    };

    // Return state and events
    return {
        form,
        followUp,
        isAddServiceVisible,
        onClickCancelInAddServiceModal,
        onClickAddService,
        onClickOkayInAddServiceModal,
        onLiabilityAdded,
    };
};
