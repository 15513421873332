import React from 'react';
import 'fragments/contract-card/modal-information-interest/modal-information-interest.scss';
import { ModalInformationInterestFragmentProps } from 'fragments/contract-card/modal-information-interest/interfaces';
import { useModalInformationInterestController } from 'fragments/contract-card/modal-information-interest/modal-information-interest.controller';
import ModalForm from 'components/modal-form/modal-form.component';
import InterestOwed from 'components/interest-owed/interest-owed.component';
import { useTranslator } from 'tools/view-hooks/translator-hook';

export const ModalInformationInterestFragment: React.FC<ModalInformationInterestFragmentProps> = (props) => {
    const { useController = useModalInformationInterestController } = props;
    const controller = useController(props.onContractInformationAdded);
    const { translate } = useTranslator();

    // Render
    return (
        <div className={'modal-information-interest'}>
            <ModalForm
                isvisible={props.isVisible}
                onCancel={props.onCancel}
                onFinish={controller.onFormSubmit}
                form={controller.form}
                isLoading={controller.isLoading}
                title={translate({ key: 'contracts.information' })}
                layout={'vertical'}
                initialValues={controller.initialValues}
            >
                <InterestOwed disabled={controller.isDisabled} />
            </ModalForm>
        </div>
    );
};
