import { useContext, useEffect, useState } from 'react';

import { Form } from 'antd';

import { EditContractController, EditContractFormValues } from 'fragments/contract-card/edit-contract/interfaces';
import { ContractContext } from '../contract-card.component';
import { SelectorOption } from 'fragments/add-property/interfaces';
import { PersonsService, useAPIPersonsService } from 'services/persons/persons.service';
import { useAPIContractService } from 'services/contracts/contracts.service';
import { CompletePersonDto } from 'services/persons/person-complete.dto';
import { PropertiesService, useAPIPropertieService } from 'services/properties/properties.service';
import { CompletePropertyDto } from 'services/properties/property-complete.dto';
import { useAPISettingsService } from 'services/settings/settings.service';
import { ProvinceDto } from 'services/settings/settings.dto';
import { useMessenger } from 'tools/view-hooks/messenger-hook';

export const useEditContractController = (
    onFinish: () => void,
    contractService = useAPIContractService(),
    personsService: PersonsService = useAPIPersonsService(),
    propertiesService: PropertiesService = useAPIPropertieService(),
    settingsService = useAPISettingsService(),
    messenger = useMessenger(),
): /* <--Dependency Injections  like services hooks */
EditContractController => {
    const [isLoading, setIsLoading] = useState(false);
    const [selectIsOpen, setSelectIsOpen] = useState(false);
    const { contract, setContract } = useContext(ContractContext);
    const [formInstance] = Form.useForm();
    const [personsOptions, setPersonsOptions] = useState<SelectorOption[]>([]);
    const [propertiesOptions, setPropertiesOptions] = useState<SelectorOption[]>([]);
    const [provincesOptions, setProvincesOptions] = useState<SelectorOption[]>([]);

    /* Listeners */

    useEffect(() => {
        fetchPersons();
        fetchProperties();
        fetchProvinces();
    }, []);

    /* View Events */
    const onFormSubmit = (inputs: EditContractFormValues) => {
        if (!contract.id) {
            return;
        }
        setIsLoading(true);
        contractService
            .updateContract(inputs, contract.id)
            .then((output) => {
                fetchProperties();
                fetchPersons();
                setContract((prevState) => {
                    return { ...prevState, ...output, renters: output.renters, property: output.property };
                });
                formInstance.resetFields();
                onFinish();
            })
            .catch(() => {
                messenger.showErrorMessage({ key: 'properties.add-property-detail-error' });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };
    /* Private Methods */

    const mapDtoPersonsToSelectorOptions = (dto: CompletePersonDto): SelectorOption => {
        return {
            value: dto.id ?? 0,
            label: `${dto.name} ${dto.lastName}` ?? '',
        };
    };

    const setPersonsOptionsDontVisible = (dtos: SelectorOption[]) => {
        const dontVisible = contract.renters ? contract.renters.filter((renter) => !renter.isVisible ?? 0) : [];
        if (dontVisible[0]) {
            for (const renter of dontVisible) {
                const name = renter.name ?? '';
                const lastName = renter.lastName ?? '';
                const label = name + ' ' + lastName + ' ' + '(eliminado/a)';
                const option = { value: renter.id ?? 0, label };
                const dto = [...dtos, option];
                setPersonsOptions(dto);
            }
        }
    };

    const fetchPersons = () => {
        personsService.getAllPersons().then((dtos) => {
            const dto = dtos.map(mapDtoPersonsToSelectorOptions);
            setPersonsOptions(dto);
            setPersonsOptionsDontVisible(dto);
        });
    };

    const mapPropertiesDtoToSelectorOptions = (dto: CompletePropertyDto): SelectorOption => {
        return {
            value: dto.id ?? 0,
            label: dto.alias ?? '',
        };
    };

    const setPropertiesOptionsDontVisible = (dtos: SelectorOption[]) => {
        if (contract.property && !contract.property.isVisible) {
            const alias = contract.property.alias ?? '';
            const label = alias + ' ' + '(eliminado/a)';
            const option = { value: contract.property.id ?? 0, label };
            const dto = [...dtos, option];
            setPropertiesOptions(dto);
        }
    };

    const fetchProperties = () => {
        propertiesService.getAllProperties().then((dtos) => {
            const dto = dtos.map(mapPropertiesDtoToSelectorOptions);
            setPropertiesOptions(dto);
            setPropertiesOptionsDontVisible(dto);
        });
    };

    /* Private Methods */
    const validateRenters = (rule: any, value: any, callback: any) => {
        if (value.length > 10 || value.length <= 0) {
            callback('Permitidos: máximo diez - minimo uno');
        } else {
            callback();
        }
        return;
    };

    const mapToRentersId =
        contract.renters && contract.renters[0] ? contract.renters.map((renters) => renters.id ?? 0) : [];

    const mapToPropertyId = contract.property ? contract.property.id ?? 0 : 0;

    const fetchProvinces = () => {
        settingsService.getAllProvinces().then((dtos) => {
            setProvincesOptions(dtos.map(mapDtoProvincesToSelectorOptions));
        });
    };

    const mapDtoProvincesToSelectorOptions = (dto: ProvinceDto): SelectorOption => {
        return {
            value: dto.id ?? 0,
            label: dto.name ?? '',
        };
    };

    const onClickSelect = () => {
        setSelectIsOpen(!selectIsOpen);
    };

    const onBlurSelect = () => {
        setSelectIsOpen(false);
    };

    // Return state and events
    return {
        form: formInstance,
        isLoading,
        initialValues: { ...contract, rentersId: mapToRentersId, propertyId: mapToPropertyId },
        onFormSubmit,
        personsOptions,
        propertiesOptions,
        validateRenters,
        provincesOptions,
        onClickSelect,
        selectIsOpen,
        onBlurSelect,
    };
};
