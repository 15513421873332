import React, { Dispatch, SetStateAction } from 'react';
import { CompletePersonDto } from 'services/persons/person-complete.dto';

export interface PersonContextInterface {
    person: CompletePersonDto;
    setPerson: Dispatch<SetStateAction<CompletePersonDto>>;
}

export const PersonContext = React.createContext<PersonContextInterface>({
    person: {},
    setPerson: () => {
        // Nothing to do
    },
});
