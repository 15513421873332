import React, { ReactNode, useEffect, useState } from 'react';
import { Switch, Route, Link, Redirect, useRouteMatch } from 'react-router-dom';
import { Avatar, Button, Divider, Layout, Menu, Typography } from 'antd';
import 'components/sider-nav-bar/sider-nav-bar.scss';
import ButtonLogout from 'auth/components/button-logout/button-logout.component';
import SiderToggle from 'components/sider-toggle/sider-toggle.component';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { ReactComponent as IconDrawer } from 'assets/icons-svg/icons-navegaci-n-drawer.svg';

export interface TopBarScreen {
    title: string;
    icon?: ReactNode;
    component: ReactNode;
}

export interface SiderNavBarProps {
    avatar?: ReactNode;
    admin: string;
    email: string;
    realEstate: string;
    sections: { screens: { [key: string]: TopBarScreen } }[];
    siderExtra?: ReactNode;
    showSider: boolean;
    footer: string;
    contentTop?: ReactNode;
    buttonLogout?: ReactNode;
}

export const SiderNavBar: React.FC<SiderNavBarProps> = (props) => {
    const firstPathMatch = useRouteMatch('/:first');
    const firstRestPathMatch = useRouteMatch('/:first/:rest');
    const params: unknown = firstPathMatch ? firstPathMatch.params : firstRestPathMatch?.params;
    const parsedParams = params as { first: string };
    const firstPath = parsedParams?.first;
    const { translate } = useTranslator();
    const [isDrawerVisible, setIsDrawerVisible] = useState(false);

    const [width, setWidth] = useState<number>(window.innerWidth);
    useEffect(() => {
        window.addEventListener('resize', () => setWidth(window.innerWidth));
        return () => {
            window.removeEventListener('resize', () => setWidth(window.innerWidth));
        };
    }, []);

    const isMobile: boolean = width <= 768;

    const getTitleForScreen = (screenKey: string): string => {
        const screens: { [key: string]: TopBarScreen } = {};

        props.sections.forEach((section) => {
            Object.keys(section.screens).forEach((key) => {
                const screen = section.screens[key];
                screens[key] = screen;
            });
        });
        const selectedScreen = screens[screenKey];
        return selectedScreen ? selectedScreen.title : '';
    };

    const AvatarDropdown = (
        <div className={'avatar-dropdown'}>
            <div className="avatar">
                <Avatar size={'large'} src={props.avatar ? props.avatar : './placeholder.jpg'} />
                {props.admin || props.realEstate ? (
                    <div className="container-name">
                        <Typography.Text className="name-admin" ellipsis={true}>
                            {props.admin}
                        </Typography.Text>
                        <Typography.Text className="name-realEstate" ellipsis={true} style={{ width: 120 }}>
                            {props.realEstate}
                        </Typography.Text>
                    </div>
                ) : (
                    <div className="container-name">
                        <Typography.Text className="name-admin" ellipsis={true}>
                            {props.email}
                        </Typography.Text>
                        <Button type="link" href="/configuracion">
                            <Typography.Text className="button-complete">
                                {translate({ key: 'general.complete-your-data' })}
                            </Typography.Text>
                        </Button>
                    </div>
                )}
            </div>
            <div className="title-header">
                <Typography.Title level={3} className="title-screen">
                    {getTitleForScreen(firstPath)}
                </Typography.Title>
            </div>
        </div>
    );

    const BottomNavMenu = (
        <Menu selectedKeys={[firstPath]} mode="horizontal" style={{ zIndex: 999 }} className={'BottomNavMenu'}>
            {props.sections.map((section, index) => {
                if (index > 1) return null;
                return Object.keys(section.screens).map((screenKey) => {
                    const screen = section.screens[screenKey];
                    return (
                        <Menu.Item key={screenKey} icon={screen.icon}>
                            <Link to={'/' + screenKey} />
                        </Menu.Item>
                    );
                });
            })}
            <Menu.Item key={'menu'} icon={<IconDrawer />}>
                <a
                    onClick={() => {
                        setIsDrawerVisible((prevState) => !prevState);
                    }}
                />
            </Menu.Item>
        </Menu>
    );

    const NavMenu = props.sections.map((section, index) => {
        if (isMobile && index < 2) return null;
        return (
            <div key={index}>
                <Menu selectedKeys={[firstPath]} mode="inline">
                    {Object.keys(section.screens).map((screenKey) => {
                        const screen = section.screens[screenKey];
                        return (
                            <Menu.Item
                                key={screenKey}
                                icon={screen.icon}
                                onClick={() => setIsDrawerVisible(false)}
                                title={''}
                            >
                                {screenKey === 'inicio' ? (
                                    <Link to={'/'}> {screen.title} </Link>
                                ) : (
                                    <Link to={'/' + screenKey}> {screen.title} </Link>
                                )}
                            </Menu.Item>
                        );
                    })}
                </Menu>
                <div style={{ marginRight: 10, marginLeft: 10 }}>
                    <Divider />
                </div>
            </div>
        );
    });

    const ScreenSwitcher = (
        <Switch>
            {props.sections.map((section) => {
                return Object.keys(section.screens).map((screenKey) => {
                    const screen = section.screens[screenKey];
                    return (
                        <Route
                            key={screenKey}
                            path={'/' + screenKey}
                            render={() => {
                                document.title = `ATP - ${screen.title}`;
                                return screen.component;
                            }}
                        />
                    );
                });
            })}
            <Route key={'/'}>
                <Redirect to={'/' + Object.keys(props.sections[0].screens)[0]} />
            </Route>
        </Switch>
    );

    const isCollapsed = isMobile ? !isDrawerVisible : false;

    const Sider = (
        <>
            {props.showSider ? (
                <Layout.Sider
                    breakpoint="lg"
                    collapsedWidth="0"
                    width={220}
                    trigger={null}
                    collapsed={isCollapsed}
                    style={{ zIndex: 998, left: !isMobile ? 0 : 'unset', right: isMobile ? 0 : 'unset' }}
                >
                    {NavMenu}
                    <div className="container-button-logout">
                        <ButtonLogout key={'logout'} color={'#ededed'} />
                    </div>
                    {props.siderExtra ? props.siderExtra : null}
                </Layout.Sider>
            ) : null}
        </>
    );

    return (
        <Layout className={'sider-nav-bar'}>
            <Layout.Header className={'sider-header'}>
                {SiderToggle}
                {AvatarDropdown}
            </Layout.Header>
            <Layout className={'sider-content-wrapper'}>
                {Sider}
                <div className={'content-footer-wrapper'}>
                    <Layout.Content className={props.showSider ? 'sider-content' : 'sider-content-full'}>
                        <div className="content-fragments">
                            {props.contentTop ? props.contentTop : null}
                            {ScreenSwitcher}
                        </div>
                    </Layout.Content>
                    <Layout.Footer className={props.showSider ? 'sider-footer' : 'sider-footer-full'}>
                        <Typography.Text className="text-footer">{props.footer}</Typography.Text>
                    </Layout.Footer>
                </div>
            </Layout>
            {isMobile && BottomNavMenu}
        </Layout>
    );
};
