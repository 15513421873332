import { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

import { ContractsController } from 'fragments/contracts/interfaces';
import { ContractCardFragmentProps } from 'fragments/contract-card/interfaces';
import { ContractsService, useAPIContractService } from 'services/contracts/contracts.service';
import { CompleteContractDto } from 'services/contracts/contract-complete.dto';
import { useAPIMembershipService } from 'services/memberships/memberships.service';
import { useMessenger } from 'tools/view-hooks/messenger-hook';

export const useContractsController = (
    membershipsService = useAPIMembershipService(),
    contractsService: ContractsService = useAPIContractService(),
    messenger = useMessenger(),
): ContractsController => {
    /* State */
    const [isLoading, setIsLoading] = useState(false);
    const [isAddContractModalVisible, setIsAddContractModalVisible] = useState(false);
    const [isUpdatePlanModalVisible, setIsUpdatePlanModalVisible] = useState(false);
    const [cardProps, setCardProps] = useState<ContractCardFragmentProps[]>([]);
    const [isLoadingUpdateButton, setIsLoadingUpdateButton] = useState(false);

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    const query = useQuery();

    /* Listeners */
    useEffect(() => {
        fetchCardsContracts();
        if (query.get('preapproval_id') || query.get('addNew')) {
            onAddContractPressed();
        }
    }, []);

    /* View Events */
    const onClickUpdate = () => {
        setIsLoadingUpdateButton(true);
        membershipsService
            .upgradeMembership()
            .then((url) => {
                window.open(url);
            })
            .catch((err) => {
                console.log('error catch', err.response.data);
            });
    };
    const onAddContractPressed = () => {
        membershipsService
            .getCurrentMembershipDetail()
            .then((dto) => {
                if (dto.remainingContracts != undefined && dto.remainingContracts > 0) {
                    setIsAddContractModalVisible(true);
                } else {
                    setIsUpdatePlanModalVisible(true);
                }
            })
            .catch((err) => {
                messenger.showErrorMessage({ key: 'common-errors.web-error-message' });
            });
    };

    const onAddContractCanceled = () => {
        setIsAddContractModalVisible(false);
    };
    const onUpdatePlanCanceled = () => {
        setIsUpdatePlanModalVisible(false);
    };

    const onDeleteContract = (id: number) => {
        contractsService
            .deleteContract(id)
            .then(() => {
                removeCardProp(id);
            })
            .catch(() => {
                messenger.showErrorMessage({ key: 'contracts.remove-contracts-error' });
            });
    };
    const onDeleteContractFile = (id: number) => {
        contractsService
            .deleteContractFile(id)
            .then(() => {
                removeCardProp(id);
            })
            .catch(() => {
                messenger.showErrorMessage({ key: 'contracts.remove-contract-file-error' });
            });
    };

    /* Private Methods */
    const removeCardProp = (id: number) => {
        setCardProps((actualState) => {
            return actualState.filter((item) => {
                return item.contractDto.id != id;
            });
        });
    };

    const mapDtoToCardProps = (dto: CompleteContractDto): ContractCardFragmentProps => {
        return {
            key: `${dto.id}`,
            contractDto: dto,
            onDeleteContractFile: onDeleteContractFile,
            onDelete: onDeleteContract,
        };
    };
    const fetchCardsContracts = () => {
        setIsLoading(true);
        contractsService
            .getAllContracts()
            .then((dtos) => {
                const cardPropsMapped = dtos.map(mapDtoToCardProps);
                setCardProps(cardPropsMapped);
            })
            .catch(() => {
                messenger.showErrorMessage({ key: 'contracts.get-contracts-error' });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const onContractAdded = (dto: CompleteContractDto) => {
        const cardProp = mapDtoToCardProps(dto);
        setCardProps([cardProp, ...cardProps]);
        setIsAddContractModalVisible(false);
    };

    const onSearch = (query: string) => {
        contractsService
            .searchContract({ param: query })
            .then((resp) => {
                const cardPropsMapped = resp.map(mapDtoToCardProps);
                setCardProps(cardPropsMapped);
            })
            .catch((err) => {
                messenger.showErrorMessage({ key: 'persons.remove-person-error' });
            });
    };

    // Return state and events
    return {
        /* State */
        isLoading,
        isLoadingUpdateButton,
        onClickUpdate,
        contractCardProps: cardProps,
        isAddContractModalVisible,
        isUpdatePlanModalVisible,
        mustUpdatePlan: true,
        /* Events */
        onSearch,
        onAddContractPressed,
        onAddContractCanceled,
        onUpdatePlanCanceled,
        onContractAdded,
    };
};
