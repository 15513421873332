import React from 'react';
import 'components/observations-card/observation-card.scss';
import { Descriptions, Select, Space, Typography } from 'antd';
import { useDateHelper } from 'tools/view-hooks/date-helper-hook';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { fixSelectOnScroll } from 'tools/view-hooks/fix-autocomplete';

export interface ObservationCardProps {
    id: number;
    status: string;
    description: string;
    date: Date;
    onSelectedObservationStatusChange: (value: any) => void;
}

const ObservationCard: React.FC<ObservationCardProps> = (props) => {
    const { translate } = useTranslator();
    const dateHelper = useDateHelper();
    const { Option } = Select;

    return (
        <div className="observation-card">
            <Space>
                <Descriptions column={1}>
                    <Descriptions.Item>
                        <Space style={{ flex: 1 }} size={'large'}>
                            <Typography.Title level={5}>{dateHelper.getReadableDate(props.date)}</Typography.Title>
                            <Select
                                onChange={props.onSelectedObservationStatusChange}
                                value={props.status}
                                size={'small'}
                                getPopupContainer={fixSelectOnScroll}
                            >
                                <Option value="pending" size="small">
                                    {translate({ key: 'observations.pending' })}
                                </Option>
                                <Option value="checked" size="small">
                                    {translate({ key: 'observations.checked' })}
                                </Option>
                            </Select>
                        </Space>
                    </Descriptions.Item>
                    <Descriptions.Item className={'long-description'}>
                        <Typography.Text className={'observation-description'}>
                            {translate({ key: 'observations.description' })}: {props.description}
                        </Typography.Text>
                    </Descriptions.Item>
                </Descriptions>
            </Space>
        </div>
    );
};

export default ObservationCard;
