import React from 'react';
import 'fragments/person-card/person-card.scss';
import iconEdit from 'assets/icons-svg/icon-edit.svg';
import { PersonCardFragmentProps } from 'fragments/person-card/interfaces';
import { usePersonCardController } from 'fragments/person-card/person-card.controller';
import { Button, Col, Descriptions, Row, Space, Tag, Typography, Image } from 'antd';
import DeleteModalButton from 'components/delete-modal-button/delete-modal-button.component';
import { PersonContext } from './person-card.component';
import { EditPersonFragment } from './person-edit/persons-edit.fragment';
import { useTranslator } from 'tools/view-hooks/translator-hook';

export const PersonCardFragment: React.FC<PersonCardFragmentProps> = (props) => {
    const { useController = usePersonCardController } = props;
    const controller = useController(props.completePersonDto);
    const { translate } = useTranslator();
    const { Text } = Typography;

    // Render
    return (
        <PersonContext.Provider value={{ person: controller.person, setPerson: controller.setPerson }}>
            <div className={'person-card'}>
                <EditPersonFragment
                    onPersonEdit={controller.onPersonEdit}
                    onCancel={controller.onPersonEditCanceled}
                    isVisible={controller.isPersonEditModalVisible}
                />

                <Row className={'person-card-header'}>
                    <Col xs={24} lg={12}>
                        <Typography.Title level={5}>{controller.title}</Typography.Title>
                    </Col>
                    <Col xs={24} lg={12} className={'person-tag'}>
                        {controller.rolPersonStatus === 'active' ? (
                            <Space>
                                {controller.activesRoles &&
                                    controller.activesRoles.map((rol) => (
                                        <Tag key={rol}> {translate({ key: `persons.${rol}` })}</Tag>
                                    ))}
                                <Tag color="success"> {translate({ key: 'persons.active' })}</Tag>
                            </Space>
                        ) : (
                            <Tag color="red"> {translate({ key: 'persons.inactive' })} </Tag>
                        )}
                    </Col>
                </Row>
                <Row gutter={10} className={'person-columns'}>
                    <Col xs={24} lg={8}>
                        <Descriptions column={1} labelStyle={{ fontWeight: 'bold' }}>
                            <Descriptions.Item label={translate({ key: 'persons.' + controller.typeDoc })}>
                                <Text>{controller.numberDoc}</Text>
                            </Descriptions.Item>
                            <Descriptions.Item label={translate({ key: 'persons.cuit' })}>
                                <Text>{controller.cuit}</Text>
                            </Descriptions.Item>
                            <Descriptions.Item label={translate({ key: 'persons.cbu' })}>
                                <Text>{controller.cbu}</Text>
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                    <Col xs={24} lg={8}>
                        <Descriptions column={1} labelStyle={{ fontWeight: 'bold' }}>
                            <Descriptions.Item label={translate({ key: 'persons.telephone' })}>
                                {controller.phone}
                            </Descriptions.Item>
                            <Descriptions.Item label={translate({ key: 'persons.email' })}>
                                {controller.email}
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                    <Col xs={24} lg={8}>
                        <Descriptions column={1} labelStyle={{ fontWeight: 'bold' }}>
                            <Descriptions.Item label={translate({ key: 'persons.address' })}>
                                {controller.address}
                                <br />
                                {controller.provinceCity}
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                    <Col xs={24}>
                        <Descriptions.Item>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    marginTop: '20px',
                                }}
                            >
                                <Button
                                    style={{
                                        padding: '10px',
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        alignItems: 'center',
                                        backgroundColor: '#7258db',
                                        marginRight: '8px',
                                    }}
                                    onClick={controller.onPersonEditPressed}
                                >
                                    <Image preview={false} src={iconEdit} />
                                </Button>
                                <DeleteModalButton
                                    id={controller.id ?? 0}
                                    onClickDelete={props.onDelete}
                                    title={`${translate({ key: 'persons.sure-delete-person' })} ${controller.title}?`}
                                />
                            </div>
                        </Descriptions.Item>
                    </Col>
                </Row>
            </div>
        </PersonContext.Provider>
    );
};
