import React, { Dispatch, SetStateAction } from 'react';
import { Session } from 'auth/models/session';
import { CurrentMembershipDetailsDto, MembershipOutputDto } from '../../services/memberships/membership.dto';
import { RealEstateDto } from 'services/settings/settings.dto';

export interface SessionContextInterface {
    isLoading: boolean;
    session: Session;
    setSession: (session: Session) => void;
    sessionData: SessionData;
    setSessionData: Dispatch<SetStateAction<SessionData>>;
}

export interface SessionData {
    avatarUrl?: string;
    mustUpgradePlan?: boolean;
    currentMembership?: CurrentMembershipDetailsDto;
    realEstate?: RealEstateDto;
}

export const SessionContext = React.createContext<SessionContextInterface>({
    isLoading: true,
    session: new Session(),
    setSession: () => {
        /* Nothing to do here*/
    },
    sessionData: {},
    setSessionData: () => {
        /* Nothing to do here*/
    },
});
