import React from 'react';
import 'fragments/add-person/add-person.scss';
import ModalForm from 'components/modal-form/modal-form.component';
import { Form, Input, InputNumber } from 'antd';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { GenerateFreeReceiptFragmentProps } from './interfaces';
import { useGenerateFreeReceiptController } from './generate-free-receipt.controller';
import TextArea from 'antd/lib/input/TextArea';
import CurrencyInput from 'components/currency-input/currency-input.component';

export const GenerateFreeReceiptFragment: React.FC<GenerateFreeReceiptFragmentProps> = (props) => {
    const { useController = useGenerateFreeReceiptController } = props;
    const controller = useController(props.onGeneratePdfFreeReceiptAdded);
    const { translate } = useTranslator();

    // Render
    return (
        <ModalForm
            className={'generate-free-receipt'}
            onFinish={controller.onFormSubmit}
            form={controller.form}
            isvisible={props.isVisible}
            onCancel={props.onCancel}
            isLoading={controller.isLoading}
            layout={'vertical'}
            wrapperCol={{ span: '20', offset: '2' }}
            title={translate({ key: 'generate-free-receipt.new-receipt' })}
        >
            <Form.Item
                label={translate({ key: 'generate-free-receipt.complete-name' })}
                labelAlign="left"
                labelCol={{ span: '20' }}
                name="name"
                rules={[{ required: true }]}
                wrapperCol={{ span: '24' }}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label={translate({ key: 'generate-free-receipt.address' })}
                labelAlign="left"
                labelCol={{ span: '20' }}
                name="address"
                rules={[{ required: false }]}
                wrapperCol={{ span: '24' }}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label={translate({ key: 'generate-free-receipt.city' })}
                labelAlign="left"
                labelCol={{ span: '24' }}
                name="city"
                rules={[{ required: false }]}
                wrapperCol={{ span: '24' }}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label={translate({ key: 'generate-free-receipt.amount' })}
                labelAlign="left"
                labelCol={{ span: '20' }}
                name="amount"
                rules={[{ required: true }]}
                wrapperCol={{ span: '12' }}
            >
                <CurrencyInput size={'large'} className="input-rent-amount" placeholder={''} />
            </Form.Item>
            <Form.Item
                label={translate({ key: 'generate-free-receipt.concept' })}
                labelAlign="left"
                labelCol={{ span: '20' }}
                name="concept"
                rules={[{ required: true }]}
                wrapperCol={{ span: '24' }}
            >
                <TextArea showCount maxLength={250} autoSize={{ minRows: 3, maxRows: 4 }} />
            </Form.Item>
        </ModalForm>
    );
};
