import { useContext, useState } from 'react';
import { Form } from 'antd';

import {
    AddPropertyDetailController,
    PropertyDetailFormValues,
} from 'fragments/property-card/add-property-detail/interfaces';
import { useAPIPropertieService } from 'services/properties/properties.service';
import { PropertyContext } from 'fragments/property-card/property.context';
import { useMessenger } from 'tools/view-hooks/messenger-hook';

export const useAddPropertyDetailController = (
    onFinish: () => void,
    propertiesService = useAPIPropertieService(),
    messenger = useMessenger(),
): AddPropertyDetailController => {
    /* State */
    const [isLoading, setIsLoading] = useState(false);
    const { property, setProperty } = useContext(PropertyContext);
    const [formInstance] = Form.useForm();
    /* Listeners */

    /* View Events */
    const onFormSubmit = (inputs: PropertyDetailFormValues) => {
        if (!property.id) {
            return;
        }
        setIsLoading(true);
        propertiesService
            .updateProperty(inputs, property.id)
            .then((output) => {
                setProperty((prevState) => {
                    return { ...prevState, ...output };
                });
                onFinish();
            })
            .catch(() => {
                messenger.showErrorMessage({ key: 'properties.add-property-detail-error' });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };
    /* Private Methods */

    // Return state and events
    return { form: formInstance, isLoading, initialValues: property, onFormSubmit };
};
