import React from 'react';

import { Button, Empty, Table, Typography } from 'antd';
import { ConfigProvider } from 'antd';

import 'fragments/follow-ups-list/follow-ups-list.scss';
import { FollowUpsListFragmentProps } from 'fragments/follow-ups-list/interfaces';
import { useFollowUpsListController } from 'fragments/follow-ups-list/follow-ups-list.controller';
import { FollowUpCardFragment } from 'fragments/follow-up-card/follow-up-card.fragment';
import { FollowUpCardFragmentProps } from 'fragments/follow-up-card/interfaces';
import FlexLoader from 'components/flex-loader/flex-loader.component';
import DownloadReceiptsModal from 'components/download-receipts-modal/download-receipts-modal.component';
import DownloadSettlementsModal from 'components/download-settlements-modal/download-settlements-modal.component';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { GenerateFreeReceiptFragment } from 'fragments/generate-free-receipt/generate-free-receipt.fragment';
import { FilterInicioFragment } from 'fragments/filter-inicio/filter-inicio.fragment';
import RowWrapper from 'components/row-wrapper/row-wrapper.component';
import iconGenericReceipt from 'assets/icons-svg/icons-recibo-libre.svg';

export const FollowUpsListFragment: React.FC<FollowUpsListFragmentProps> = (props) => {
    const { useController = useFollowUpsListController } = props;
    const controller = useController();
    const { translate } = useTranslator();
    const { Column } = Table;
    // Render
    return (
        <div className={'follow-ups-list'}>
            <DownloadReceiptsModal
                dto={controller.receiptsModalViewModel}
                isVisible={controller.isReceiptsModalVisible}
                onCancel={controller.onReceiptsModalCancel}
            />
            <DownloadSettlementsModal
                dto={controller.settlementsModalViewModel}
                isVisible={controller.isSettlementsModalVisible}
                onCancel={controller.onSettlementsModalCancel}
            />
            <GenerateFreeReceiptFragment
                isVisible={controller.isGenerateFreeReceiptVisible}
                onCancel={controller.onGenerateFreeReceiptCanceled}
                onGeneratePdfFreeReceiptAdded={controller.onGenerateFreeReceiptAdded}
            />
            <FilterInicioFragment
                offset={controller.currentPage}
                limit={controller.limitOfPagination}
                onFilterChange={controller.onFilterChange}
                onCleanFilter={controller.onCleanFilter}
            />
            <div>
                <div className={'action-btns'}>
                    <Button
                        size={'middle'}
                        type="text"
                        onClick={controller.onGenerateFreeReceiptPressed}
                        className={'generic-receipt'}
                    >
                        <img src={iconGenericReceipt} />
                        <Typography.Text>{translate({ key: 'follow-ups.generate-free-receipt' })}</Typography.Text>
                    </Button>
                </div>
                <ConfigProvider
                    renderEmpty={() => (
                        <>
                            {controller.isFiltering ? (
                                <Empty
                                    image={false}
                                    description={
                                        <Typography.Text>
                                            {translate({ key: 'follow-ups.filters-empty-result-message' })}
                                        </Typography.Text>
                                    }
                                />
                            ) : (
                                <Empty
                                    image={false}
                                    description={
                                        <Typography.Text>
                                            {translate({ key: 'follow-ups.you-do-not-have-followUps-loaded-yet' })}
                                            <br />
                                            {translate({ key: 'follow-ups.you-can-start-by' })}
                                            <Button type="link" size="small" href={'/contratos?addNew=true'}>
                                                {translate({ key: 'follow-ups.adding-a-contract' })}
                                            </Button>
                                        </Typography.Text>
                                    }
                                />
                            )}
                        </>
                    )}
                    componentSize={'large'}
                >
                    <Table
                        components={{ body: { row: RowWrapper } }}
                        pagination={{
                            pageSize: controller.limitOfPagination,
                            total: controller.total,
                            onChange: controller.onChangePage,
                            current: controller.currentPage,
                        }}
                        dataSource={controller.viewModels}
                        rowSelection={controller.rowSelection}
                        onRow={(item) => {
                            return item.header;
                        }}
                        rowKey={(item) => item.key}
                        loading={controller.isLoading}
                    >
                        <Column
                            render={(text: string, props: FollowUpCardFragmentProps) => (
                                <FollowUpCardFragment {...props} />
                            )}
                        />
                    </Table>
                </ConfigProvider>
            </div>
        </div>
    );
};
