import React from 'react';
import 'components/row-wrapper/row-wrapper.scss';

export interface RowWrapperProps {
    headerVisible: boolean;
    title: string;
}

const RowWrapper: React.FC<RowWrapperProps> = (props) => {
    return (
        <>
            {props.headerVisible && (
                <tr>
                    <td colSpan={2} className={'follow-up-month-title'}>
                        {props.title}
                    </td>
                </tr>
            )}
            <tr className={'atp-card'}>{props.children}</tr>
        </>
    );
};

export default RowWrapper;
