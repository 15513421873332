import { useEffect, useState } from 'react';

import { Key } from 'antd/lib/table/interface';
import { FollowUpsListController } from 'fragments/follow-ups-list/interfaces';
import { FollowUpCardFragmentProps } from 'fragments/follow-up-card/interfaces';
import { FollowUpService, useApiFollowUpService } from 'services/followUps/followUp.service';
import { CompleteFollowUpDto, FollowUpDto } from 'services/followUps/followUp.dto';
import { RentalPaymentService, useApiRentalPaymentService } from 'services/rentalPayments/rentalPayment.service';
import { DownloadableItem } from 'services/rentalPayments/receipt.dto';
import { useMessenger } from 'tools/view-hooks/messenger-hook';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { useAPIMembershipService } from 'services/memberships/memberships.service';

export const useFollowUpsListController = (
    followUpService: FollowUpService = useApiFollowUpService(),
    rentalPaymentService: RentalPaymentService = useApiRentalPaymentService(),
    membershipsService = useAPIMembershipService(),
    messenger = useMessenger(),
): FollowUpsListController => {
    const { translate } = useTranslator();
    /* State */
    const [isLoading, setIsLoading] = useState(false);
    const [viewModels, setViewModels] = useState<FollowUpCardFragmentProps[]>([]);
    const [selectedRows, setSelectedRows] = useState<FollowUpCardFragmentProps[]>([]);
    const [receiptsModalViewModel, setReceiptsModalViewModel] = useState<DownloadableItem[]>([]);
    const [isReceiptsModalVisible, setIsReceiptsModalVisible] = useState(false);
    const [isSettlementsModalVisible, setIsSettlementsModalVisible] = useState(false);
    const [settlementsModalViewModel, setSettlementsModalViewModel] = useState<{
        selectedFollowUpsByOwner: DownloadableItem[];
        currentMonthFollowUpsByOwner: DownloadableItem[];
    }>({ selectedFollowUpsByOwner: [], currentMonthFollowUpsByOwner: [] });
    const [isGenerateFreeReceiptVisible, setIsGenerateFreeReceiptVisible] = useState(false);
    const [total, setTotal] = useState(0);
    const [offset, setOffset] = useState(0);
    const [filters, setFilters] = useState<any | undefined>(undefined);

    const limitOfPagination = 30;

    /* Listeners */
    useEffect(() => {
        showFollowUps().finally();
    }, [filters, offset]);

    const fetchCanPay = async (): Promise<boolean> => {
        const dto = await membershipsService.getCurrentMembershipDetail();
        return dto.canPay ?? false;
    };

    const showFollowUps = async () => {
        setIsLoading(true);
        const canPay = await fetchCanPay();
        if (filters != undefined) {
            followUpService
                .applyFilters({
                    ...filters,
                    offset,
                    limit: limitOfPagination,
                })
                .then((resp) => {
                    const newViewModels = resp.data?.map((dto) => mapDtoToCardFollowUp(dto, canPay)) ?? [];
                    const withHeaders = addHeaders(newViewModels);
                    setTotal(resp.total ?? newViewModels.length);
                    setViewModels(withHeaders);
                })
                .finally(() => setIsLoading(false));
        } else {
            pagination(canPay);
        }
    };

    const pagination = (canPay: boolean) => {
        const input = { offset, limit: limitOfPagination };
        setIsLoading(true);
        followUpService
            .getAllFollowUps(input)
            .then((resp) => {
                const newViewModels = resp.data?.map((dto) => mapDtoToCardFollowUp(dto, canPay)) ?? [];
                const withHeaders = addHeaders(newViewModels);
                setTotal(resp.total ?? newViewModels.length);
                setViewModels(withHeaders);
            })
            .finally(() => setIsLoading(false));
    };

    /* View Events */
    const onReceiptsModalCancel = () => {
        setIsReceiptsModalVisible(false);
    };
    const onSettlementsModalCancel = () => {
        setIsSettlementsModalVisible(false);
    };

    const mapDtoToCardFollowUp = (dto: CompleteFollowUpDto, canPay: boolean): FollowUpCardFragmentProps => {
        return { key: `${dto.id}`, dto: dto, header: { title: '', headerVisible: false }, canPay };
    };

    const newListFromUpdate = (prevState: FollowUpCardFragmentProps[], updates: FollowUpDto[]) => {
        return prevState.map((item) => {
            const updatedItem = updates.find((i) => i.id == Number(item.key));
            if (updatedItem) {
                return { ...item, dto: { ...item.dto, ...updatedItem } };
            }
            return item;
        });
    };

    const payRents = () => {
        const input = selectedRows.map((row) => {
            return {
                idFollowUp: Number(row.key),
                isPaid: true,
            };
        });
        rentalPaymentService
            .addMultiplePayments(input)
            .then((dto) => {
                setViewModels((prevState) => {
                    return newListFromUpdate(prevState, dto);
                });
                setSelectedRows([]);
            })
            .catch(() => {
                messenger.showErrorMessage({ key: 'followUp.error-on-multiple-pay' });
            });
    };

    const dontPayRents = () => {
        const input = selectedRows.map((row) => {
            return {
                idFollowUp: Number(row.key),
                isPaid: false,
            };
        });
        rentalPaymentService
            .addMultiplePayments(input)
            .then((dto) => {
                setViewModels((prevState) => {
                    return newListFromUpdate(prevState, dto);
                });
                setSelectedRows([]);
            })
            .catch(() => {
                messenger.showErrorMessage({ key: 'followUp.error-on-multiple-pay' });
            });
    };
    const issueRentalReceipt = () => {
        const followUpIdsArray: number[] = [];
        selectedRows.map((row) => {
            followUpIdsArray.push(Number(row.key));
        });
        rentalPaymentService
            .onIssueReceipt({ followUpIds: followUpIdsArray })
            .then((dto) => {
                setIsReceiptsModalVisible(true);
                setReceiptsModalViewModel(dto);
            })
            .catch(() => {
                messenger.showErrorMessage({ key: 'followUp.error-on-issue-receipts' });
            });
    };
    const issueSettlements = () => {
        const followUpIdsArray: number[] = [];
        selectedRows.map((row) => {
            followUpIdsArray.push(Number(row.key));
        });
        rentalPaymentService
            .onIssueSettlements({
                followUpIds: followUpIdsArray,
                allSelected: selectedRows.length === viewModels.length,
            })
            .then((dto) => {
                setIsSettlementsModalVisible(true);
                setSettlementsModalViewModel(dto);
            })
            .catch(() => {
                messenger.showErrorMessage({ key: 'followUp.error-on-issue-receipts' });
            });
    };
    const rowSelection = {
        hideSelectAll: false,
        selections: [
            {
                key: 'pay-multiple',
                text: translate({ key: 'follow-ups.mark-as-paid' }),
                onSelect: payRents,
            },
            {
                key: 'dont-pay-multiple',
                text: translate({ key: 'follow-ups.mark-as-dont-paid' }),
                onSelect: dontPayRents,
            },
            {
                key: 'issue-receipt',
                text: translate({ key: 'follow-ups.generate-rent-receipt' }),
                onSelect: issueRentalReceipt,
            },
            {
                key: 'issue-settlement',
                text: translate({ key: 'follow-ups.generate-settlements' }),
                onSelect: issueSettlements,
            },
        ],
        selectedRowKeys: selectedRows.map((i) => i.key),
        onChange: (selectedKeys: Key[], selectedRows: FollowUpCardFragmentProps[]) => {
            setSelectedRows(selectedRows);
        },
    };
    const onGenerateFreeReceiptAdded = () => {
        setIsGenerateFreeReceiptVisible(false);
    };
    const onGenerateFreeReceiptPressed = () => {
        setIsGenerateFreeReceiptVisible(true);
    };

    const onGenerateFreeReceiptCanceled = () => {
        setIsGenerateFreeReceiptVisible(false);
    };

    const addHeaders = (vm: FollowUpCardFragmentProps[]): FollowUpCardFragmentProps[] => {
        const headersTitles: string[] = [];
        const meses = [
            'Enero',
            'Febrero',
            'Marzo',
            'Abril',
            'Mayo',
            'Junio',
            'Julio',
            'Agosto',
            'Septiembre',
            'Octubre',
            'Noviembre',
            'Diciembre',
        ];

        return vm.map((item) => {
            const itemMonth = (item.dto.month ?? 1) - 1;
            const itemYear = item.dto.year ?? '';
            const headerTitle = `${itemYear} - ${meses[itemMonth]}`;
            const show = !headersTitles.includes(headerTitle);
            if (show) {
                headersTitles.push(headerTitle);
            }
            item.header = {
                title: headerTitle,
                headerVisible: show,
            };
            return item;
        });
    };

    const onChangePage = (page: number) => {
        const currentPage = page - 1;
        const newOffset = currentPage * limitOfPagination;
        setOffset(newOffset);
    };

    const onFilterChange = (newFilters: any) => {
        setFilters(newFilters);
    };

    const onCleanFilter = () => {
        setFilters(undefined);
    };

    // Return state and events
    return {
        isGenerateFreeReceiptVisible,
        isReceiptsModalVisible,
        isSettlementsModalVisible,
        receiptsModalViewModel,
        settlementsModalViewModel,
        rowSelection,
        viewModels,
        isLoading,
        currentPage: offset / limitOfPagination + 1,
        limitOfPagination,
        total,
        isFiltering: filters != undefined,
        onGenerateFreeReceiptCanceled,
        onGenerateFreeReceiptAdded,
        onGenerateFreeReceiptPressed,
        onReceiptsModalCancel,
        onSettlementsModalCancel,
        onChangePage,
        onFilterChange,
        onCleanFilter,
    };
};
