import axios from 'axios';
import { TypeServicesService } from 'services/typeServices/type-services.service';
import { TypeServiceDto } from 'services/typeServices/type-services.dto';

export class ApiTypeServicesService implements TypeServicesService {
    async getAllTypeServices(): Promise<TypeServiceDto[]> {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/liability-types`);
        return response.data as TypeServiceDto[];
    }
}
