import React from 'react';
import 'components/avatar-item-view/avatar-item-view.scss';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';

class SiderToggle extends React.Component {
    state = {
        collapsed: false,
    };

    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    render() {
        return (
            <>
                {React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                    className: 'trigger',
                    onClick: this.toggle,
                })}
            </>
        );
    }
}

export default SiderToggle;
