import { ObservationStatusEnum } from './observation-status.enum';

export class CompleteObservationDto {
    id?: number;
    description?: string;
    status?: ObservationStatusEnum;
    month?: number;
    day?: number;
    year?: number;
}
