/* eslint-disable */
import React, { useState } from 'react';
import 'components/interest-owed/interest-owed.scss';
import { Col, Form, InputNumber, Row, Select } from 'antd';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import {fixSelectOnScroll} from "tools/view-hooks/fix-autocomplete";

export interface InterestOwedProps {
    disabled?: boolean;
}

const InterestOwed: React.FC<InterestOwedProps> = (props) => {
    // State
    const { translate } = useTranslator();

    return <div className={'interest-owed'}>
            <Row gutter={10}>
                <Col span={12}>
                    <Form.Item
                        name="expiredDay"
                        label={translate({ key: 'contracts.expired-day' })}
                        rules={[{ required: true }]}
                    >
                        <InputNumber minLength={0} type='number' disabled={props.disabled}/>
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={10}>
                <Col span={12}>
                    <Form.Item
                        rules={[{ required: true }]}
                        name="debtCollection"
                        label={translate({ key: 'contracts.debt-collection' })}
                    >
                        <InputNumber minLength={0} type='number' disabled={props.disabled}/>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="typeOfCharge"
                        label={translate({ key: 'contracts.debt-collection' })}
                        rules={[{ required: true }]}
                    >
                        <Select
                         disabled={props.disabled}
                            options={[
                                { label: translate({ key: 'contracts.percentage' }), value: 'percentage' },
                                { label: translate({ key: 'contracts.amount' }), value: 'amountfixed' },
                            ]}
                         getPopupContainer={fixSelectOnScroll}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={10}>
                <Col span={12}>
                    <Form.Item
                        rules={[{ required: true }]}
                        name="initialDayInterest"
                        label={translate({ key: 'contracts.initial-day' })}
                    >
                        <InputNumber minLength={0} type='number' disabled={props.disabled}/>
                    </Form.Item>
                </Col>
            </Row>
    </div>;
};

export default InterestOwed;
