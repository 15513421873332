import React from 'react';

import TextArea from 'antd/lib/input/TextArea';
import { DatePicker, Select } from 'antd';

import 'fragments/contract-card/contract-add-observation/contract-add-observation.scss';
import { ContractAddObservationFragmentProps } from 'fragments/contract-card/contract-add-observation/interfaces';
import { useContractAddObservationController } from 'fragments/contract-card/contract-add-observation/contract-add-observation.controller';
import DynamicInputList from 'components/dynamic-input-list/dynamic-input-list.component';
import ModalForm from 'components/modal-form/modal-form.component';
import { useTranslator } from 'tools/view-hooks/translator-hook';

export const ContractAddObservationFragment: React.FC<ContractAddObservationFragmentProps> = (props) => {
    const { useController = useContractAddObservationController } = props;
    const controller = useController(props.onObservationAdded);
    const { translate } = useTranslator();
    const { Option } = Select;

    // Render
    return (
        <ModalForm
            className={'contract-add-observation'}
            onFinish={controller.onFormSubmit}
            form={controller.form}
            isvisible={props.isVisible}
            onCancel={props.onCancel}
            isLoading={controller.isLoading}
            layout={'vertical'}
            initialValues={controller.initialValues}
            title={translate({ key: 'observations.add-observation' })}
        >
            <DynamicInputList
                name={'observations'}
                addButtonLabel={translate({ key: 'observations.add-observation' })}
                items={[
                    {
                        name: 'status',
                        label: translate({ key: 'observations.state' }),
                        rules: [{ required: false }],
                        input: (
                            <Select>
                                <Option key={'pending'} value="pending">
                                    {translate({ key: 'observations.pending' })}
                                </Option>
                                <Option key={'checked'} value="checked">
                                    {translate({ key: 'observations.checked' })}
                                </Option>
                            </Select>
                        ),
                    },
                    {
                        name: 'date',
                        label: translate({ key: 'observations.select-date' }),
                        rules: [{ required: true }],
                        input: <DatePicker format={'DD/MM/YYYY'} />,
                    },
                    {
                        name: 'description',
                        label: translate({ key: 'observations.add-observation' }),
                        rules: [{ required: true }],
                        input: (
                            <TextArea
                                showCount
                                maxLength={250}
                                placeholder={translate({
                                    key: 'observations.observations',
                                })}
                                autoSize={{ minRows: 3, maxRows: 5 }}
                            />
                        ),
                    },
                ]}
            />
        </ModalForm>
    );
};
