import React from 'react';

import { Link } from 'react-router-dom';
import { Button, Form, Input, Typography, Image, Row, Col } from 'antd';

import 'auth/fragments/register/register.scss';
import ATPlogo from 'assets/img/atp_logo_white.png';
import { RegisterFragmentProps } from 'auth/fragments/register/interfaces';
import { useRegisterController } from 'auth/fragments/register/register.controller';
import { useTranslator } from 'tools/view-hooks/translator-hook';

export const RegisterFragment: React.FC<RegisterFragmentProps> = (props) => {
    const { useController = useRegisterController } = props;
    const controller = useController();
    const { translate } = useTranslator();
    const { Title } = Typography;

    // Render
    return (
        <div className={'register'}>
            <div className="header">
                <Image className="logo" preview={false} width={150} src={ATPlogo} />
            </div>
            <Row>
                <Col
                    xs={{ span: 22, offset: 1 }}
                    sm={{ span: 16, offset: 4 }}
                    md={{ span: 12, offset: 6 }}
                    lg={{ span: 10, offset: 7 }}
                    xl={{ span: 8, offset: 8 }}
                    xxl={{ span: 6, offset: 9 }}
                >
                    <div className="auth-form">
                        <Title level={3}>{translate({ key: 'auth.register-title' })}</Title>
                        <Form name="basic" initialValues={{ remember: true }} onFinish={controller.onRegisterSubmit}>
                            <Form.Item
                                label={translate({ key: 'auth.email-input-label' })}
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        type: 'email',
                                        message: `${translate({ key: 'auth.you-must-enter-a-valid-email' })}`,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label={translate({ key: 'auth.password-input-label' })}
                                name="password"
                                rules={[{ required: true }]}
                            >
                                <Input.Password />
                            </Form.Item>

                            <Form.Item className="btn-send">
                                <Button type="primary" block={true} htmlType="submit" loading={controller.isLoading}>
                                    {translate({ key: 'auth.register-button-label' })}
                                </Button>
                            </Form.Item>
                        </Form>
                        <Link to={'/login'}>
                            <Button>{translate({ key: 'auth.go-to-login-button-label' })}</Button>
                        </Link>
                    </div>
                </Col>
            </Row>
        </div>
    );
};
