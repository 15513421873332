import { useLocalSession } from 'auth/helpers/session.hooks';
import { UpdateNotificationDto } from './updateNotification.dto';
import { CompleteNotificationsDto } from './complete-notifications.dto';
import { ApiNotificationsService } from './api-notifications.service';

export interface NotificationsService {
    getAllNotifications(): Promise<CompleteNotificationsDto[]>;
    updateNotifications(input: UpdateNotificationDto): Promise<CompleteNotificationsDto>;
}

export const useAPINotificationsService = (): NotificationsService => {
    const [getSession] = useLocalSession();
    const session = getSession();
    return new ApiNotificationsService(session);
};
