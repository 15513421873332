import { useEffect, useState } from 'react';
import { plainToClass } from 'class-transformer';

import { Form } from 'antd';

import { AddPersonController } from './interfaces';
import { SelectorOption } from 'fragments/add-property/interfaces';
import { ProvinceDto } from 'services/settings/settings.dto';
import { useAPIPersonsService } from 'services/persons/persons.service';
import { PersonDto } from 'services/persons/person.dto';
import { CompletePersonDto } from 'services/persons/person-complete.dto';
import { useAPISettingsService } from 'services/settings/settings.service';
import { useMessenger } from 'tools/view-hooks/messenger-hook';

export const useAddPersonController = (
    onPersonAdded: (dto: CompletePersonDto) => void,
    personsService = useAPIPersonsService(),
    settingsService = useAPISettingsService(),
    messenger = useMessenger(),
): AddPersonController => {
    /* State */
    const [isLoading, setIsLoading] = useState(false);
    const [selectIsOpen, setSelectIsOpen] = useState(false);
    const [formInstance] = Form.useForm();
    const [provincesOptions, setProvincesOptions] = useState<SelectorOption[]>([]);

    /* Listeners */
    useEffect(() => {
        fetchProvinces();
    }, []);

    /* View Events */
    const onFormSubmit = (inputs: any) => {
        const dni = inputs.numberDoc.split('.');
        inputs.numberDoc = dni.join('');
        setIsLoading(true);
        const dto = plainToClass(PersonDto, inputs);
        if (!dto.cuit) {
            delete dto.cuit;
        }
        personsService
            .addPerson(dto)
            .then((dto) => {
                formInstance.resetFields();
                onPersonAdded(dto);
            })
            .catch(() => {
                messenger.showErrorMessage({ key: 'add-persons.default-error' });
            })
            .finally(() => {
                setIsLoading(false);
            });
        setIsLoading(false);
    };
    const fetchProvinces = () => {
        settingsService.getAllProvinces().then((dtos) => {
            setProvincesOptions(dtos.map(mapDtoProvincesToSelectorOptions));
        });
    };

    const mapDtoProvincesToSelectorOptions = (dto: ProvinceDto): SelectorOption => {
        return {
            value: dto.id ?? 0,
            label: dto.name ?? '',
        };
    };

    const onClickSelect = () => {
        setSelectIsOpen(!selectIsOpen);
    };

    /* Private Methods */

    // Return state and events
    return {
        form: formInstance,
        isLoading,
        onFormSubmit,
        provincesOptions,
        onClickSelect,
        selectIsOpen,
    };
};
