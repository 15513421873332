import React, { ReactNode } from 'react';
import 'components/dynamic-input-list/dynamic-input-list.scss';
import { Button, Divider, Form, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Rule } from 'rc-field-form/lib/interface';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { Typography } from 'antd/es';

export interface InputItem {
    name: string;
    label?: ReactNode;
    rules: Rule[];
    input: React.ReactNode;
    hidden?: boolean;
}
export interface DynamicInputListProps {
    name: string;
    addButtonLabel: string;
    items: InputItem[];
    onDelete?: () => void;
}

const DynamicInputList: React.FC<DynamicInputListProps> = (props) => {
    const { translate } = useTranslator();
    return (
        <div className={'dynamic-input-list'}>
            <Form.List name={props.name}>
                {(fields, { add, remove }) => (
                    <div>
                        {fields.map(({ key, name, fieldKey, ...restField }) => (
                            <div key={fieldKey}>
                                <Space align={'start'}>
                                    <div>
                                        {props.items.map((item: InputItem) => {
                                            return (
                                                <Form.Item
                                                    key={item.name}
                                                    {...restField}
                                                    label={item.label}
                                                    name={[name, item.name]}
                                                    fieldKey={[fieldKey, item.name]}
                                                    rules={item.rules}
                                                    hidden={item.hidden}
                                                >
                                                    {item.input}
                                                </Form.Item>
                                            );
                                        })}
                                    </div>
                                    <Button size={'small'} type={'link'} onClick={() => remove(name)}>
                                        <Typography.Text style={{ color: '#1890ff' }}>
                                            {translate({ key: 'general.remove' })}
                                        </Typography.Text>
                                    </Button>
                                </Space>
                                <Divider />
                            </div>
                        ))}
                        <Form.Item>
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                {props.addButtonLabel}
                            </Button>
                        </Form.Item>
                    </div>
                )}
            </Form.List>
        </div>
    );
};

export default DynamicInputList;
