import { Session } from '../../auth/models/session';
import axios from 'axios';
import { plainToClass } from 'class-transformer';
import { ContractDto, InformationInterestDto } from './contract.dto';
import { ContractsService } from './contracts.service';
import { CompleteContractDto } from './contract-complete.dto';
import { RescindContractDto } from './rescind-contract.dto';

export class ApiContractsService implements ContractsService {
    constructor(private session: Session) {}
    async getAllContracts(): Promise<CompleteContractDto[]> {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/contracts`, {
            headers: { Authorization: `Bearer ${this.session.getToken()}` },
        });
        return response.data.map((item: any) => plainToClass(CompleteContractDto, item));
    }
    async addContract(input: ContractDto): Promise<CompleteContractDto> {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/contracts`, input, {
            headers: { Authorization: `Bearer ${this.session.getToken()}` },
        });
        return plainToClass(CompleteContractDto, response.data);
    }

    async searchContract(input: { param: string }): Promise<CompleteContractDto[]> {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/contracts/search`, input, {
            headers: { Authorization: `Bearer ${this.session.getToken()}` },
        });
        return response.data.map((item: any) => plainToClass(CompleteContractDto, item));
    }

    async deleteContract(id: number): Promise<void> {
        return axios.delete(`${process.env.REACT_APP_API_BASE_URL}/contracts/${id}`, {
            headers: { Authorization: `Bearer ${this.session.getToken()}` },
        });
    }

    async rescindContract(input: RescindContractDto): Promise<ContractDto> {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/contracts/rescind`, input, {
            headers: { Authorization: `Bearer ${this.session.getToken()}` },
        });
        return plainToClass(ContractDto, response.data);
    }

    async updateContract(inputs: ContractDto, id: number): Promise<CompleteContractDto> {
        const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/contracts/${id}`, inputs, {
            headers: { Authorization: `Bearer ${this.session.getToken()}` },
        });
        return response.data as CompleteContractDto;
    }
    async updateContractDetails(inputs: ContractDto, id: number): Promise<CompleteContractDto> {
        const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/contracts/details/${id}`, inputs, {
            headers: { Authorization: `Bearer ${this.session.getToken()}` },
        });
        return response.data as CompleteContractDto;
    }

    async updateInformationInterest(input: InformationInterestDto, id: number): Promise<InformationInterestDto> {
        const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/contracts/information/${id}`, input, {
            headers: { Authorization: `Bearer ${this.session.getToken()}` },
        });
        return response.data as InformationInterestDto;
    }

    async deleteContractFile(id: number): Promise<void> {
        return axios.delete(`${process.env.REACT_APP_API_BASE_URL}/contracts/file/${id}`, {
            headers: { Authorization: `Bearer ${this.session.getToken()}` },
        });
    }
}
