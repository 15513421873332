import React from 'react';
import 'components/currency-input/currency-input.scss';
import { InputNumber, InputNumberProps } from 'antd';

export type CurrencyInputProps = InputNumberProps;

export const currencyFormatter = (amount?: number | string): string => {
    if (!amount || amount == 0) return '';
    return new Intl.NumberFormat('es-ar', {
        style: 'currency',
        currency: 'ARS',
    }).format(+amount);
};

const currencyParser = (val?: string): number => {
    if (val == undefined || val == '0' || Number.isNaN(val)) return 0;
    const getDigitsFromValue = (value = '') => value.replace(/(-(?!\d))|[^0-9|-]/g, '') || '';

    const padDigits = (digits: string) => {
        const desiredLength = 3;
        const actualLength = digits.length;

        if (actualLength >= desiredLength) {
            return digits;
        }

        const amountToAdd = desiredLength - actualLength;
        const padding = '0'.repeat(amountToAdd);

        return padding + digits;
    };

    const removeLeadingZeros = (number: string) => number.replace(/^0+([0-9]+)/, '$1');

    const addDecimalToNumber = (number: string, separator: string): string => {
        const centsStartingPosition = number.length - 2;
        const dollars = removeLeadingZeros(number.substring(0, centsStartingPosition));
        const cents = number.substring(centsStartingPosition);
        return dollars + separator + cents;
    };

    const toCurrency = (value: string, separator = '.') => {
        const digits = getDigitsFromValue(value);
        const digitsWithPadding = padDigits(digits);
        return Number(addDecimalToNumber(digitsWithPadding, separator));
    };
    return toCurrency(val);
};

const CurrencyInput: React.FC<CurrencyInputProps> = (props) => {
    return (
        <InputNumber
            {...props}
            parser={currencyParser}
            placeholder={currencyFormatter(Number(props.placeholder))}
            formatter={currencyFormatter}
        />
    );
};

export default CurrencyInput;
