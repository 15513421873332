import { ApiObservationService } from './api-observations.service';
import { useLocalSession } from 'auth/helpers/session.hooks';
import { ObservationDto } from './observation.dto';
import { CompleteObservationDto } from './complete-observation.dto';

export interface ObservationService {
    getAllObservations(id: number): Promise<CompleteObservationDto[]>;
    addMultiObservations(contractId: number, input: ObservationDto[]): Promise<CompleteObservationDto[]>;
    deleteObservation(id: number): Promise<void>;
    updateObservation(id: number, input: ObservationDto): Promise<CompleteObservationDto[]>;
}

export const useAPIObservationsService = (): ObservationService => {
    const [getSession] = useLocalSession();
    const session = getSession();
    return new ApiObservationService(session);
};
