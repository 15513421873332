import React from 'react';
import 'auth/fragments/reset-password/reset-password.scss';
import { Button, Form, Input, Image, Row, Col } from 'antd';
import ATPlogo from 'assets/img/atp_logo_white.png';
import { ResetPasswordFragmentProps } from 'auth/fragments/reset-password/interfaces';
import { Link } from 'react-router-dom';
import { useResetPasswordController } from 'auth/fragments/reset-password/reset-password.controller';
import { useTranslator } from 'tools/view-hooks/translator-hook';

export const ResetPasswordFragment: React.FC<ResetPasswordFragmentProps> = (props) => {
    const { useController = useResetPasswordController } = props;
    const controller = useController();
    const { translate } = useTranslator();

    // Render
    return (
        <div className={'reset-password'}>
            <div className="header">
                <Image className="logo" preview={false} width={150} src={ATPlogo} />
            </div>
            <Row>
                <Col
                    xs={{ span: 22, offset: 1 }}
                    sm={{ span: 16, offset: 4 }}
                    md={{ span: 12, offset: 6 }}
                    lg={{ span: 10, offset: 7 }}
                    xl={{ span: 8, offset: 8 }}
                    xxl={{ span: 6, offset: 9 }}
                >
                    <div className="auth-form">
                        <Form
                            name="basic"
                            initialValues={{ remember: true }}
                            onFinish={controller.onResetPasswordSubmit}
                        >
                            <Form.Item
                                label={translate({ key: 'auth.password-input-label' })}
                                name="password"
                                rules={[{ required: true }]}
                            >
                                <Input.Password />
                            </Form.Item>

                            <Form.Item
                                label={translate({ key: 'auth.repeat-password-input-label' })}
                                name="repeatPassword"
                                rules={[{ required: true }]}
                            >
                                <Input.Password />
                            </Form.Item>

                            <Form.Item className="btn-send">
                                <Button type="primary" block={true} htmlType="submit" loading={controller.isLoading}>
                                    {translate({ key: 'auth.reset-password-button-babel' })}
                                </Button>
                            </Form.Item>
                        </Form>
                        <Link to={'/login'}>
                            <Button>{translate({ key: 'auth.go-to-login-button-label' })}</Button>
                        </Link>
                    </div>
                </Col>
            </Row>
        </div>
    );
};
