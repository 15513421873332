import React from 'react';
import 'fragments/rescind-contract/rescind-contract.scss';
import { RescindContractFragmentProps } from 'fragments/rescind-contract/interfaces';
import { useRescindContractController } from 'fragments/rescind-contract/rescind-contract.controller';
import ModalForm from '../../components/modal-form/modal-form.component';
import { useTranslator } from '../../tools/view-hooks/translator-hook';
import { Form, Input } from 'antd';
import { DatePicker } from 'antd/es';
import { fixSelectOnScroll } from 'tools/view-hooks/fix-autocomplete';

export const RescindContractFragment: React.FC<RescindContractFragmentProps> = (props) => {
    const { useController = useRescindContractController } = props;
    const controller = useController(props.contractId, props.onFinish);
    const { translate } = useTranslator();
    const { TextArea } = Input;
    const monthFormat = 'YYYY/MM';

    // Render
    return (
        <ModalForm
            className={'rescind-contract-modal-form'}
            onFinish={controller.onFormSubmit}
            form={controller.form}
            isvisible={props.isVisible}
            onCancel={props.onCancel}
            isLoading={controller.isLoading}
            title={translate({ key: 'contracts.rescind-contract' })}
        >
            <Form.Item
                label={translate({ key: 'contracts.date' })}
                labelAlign="left"
                labelCol={{ span: '7', offset: '0' }}
                name="date"
                rules={[{ required: true }]}
            >
                <DatePicker format={monthFormat} picker="month" getPopupContainer={fixSelectOnScroll} />
            </Form.Item>
            <Form.Item name="observation">
                <TextArea
                    showCount
                    maxLength={250}
                    placeholder={translate({ key: 'contracts.observations' })}
                    autoSize={{ minRows: 3, maxRows: 5 }}
                />
            </Form.Item>
        </ModalForm>
    );
};
