import { Session } from '../../auth/models/session';
import axios from 'axios';
import { MembershipsService } from './memberships.service';
import { CurrentMembershipDetailsDto } from './membership.dto';

export class ApiMembershipsService implements MembershipsService {
    constructor(private session: Session) {}
    async getCurrentMembershipDetail(): Promise<CurrentMembershipDetailsDto> {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/membership/current`, {
            headers: { Authorization: `Bearer ${this.session.getToken()}` },
        });
        return response.data;
    }
    async upgradeMembership(): Promise<any> {
        const response = await axios.put(
            `${process.env.REACT_APP_API_BASE_URL}/membership/upgrade`,
            {},
            {
                headers: { Authorization: `Bearer ${this.session.getToken()}` },
            },
        );
        return response.data;
    }
    async cancelMembership(): Promise<any> {
        const response = await axios.put(
            `${process.env.REACT_APP_API_BASE_URL}/membership/cancel`,
            {},
            {
                headers: { Authorization: `Bearer ${this.session.getToken()}` },
            },
        );
        return response.data;
    }
}
