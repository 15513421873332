import { useContext, useEffect, useState } from 'react';
import { SettingsController, SettingsFormValues } from 'fragments/settings/interfaces';
import { Form } from 'antd';
import { useAPISettingsService } from '../../services/settings/settings.service';
import { useMessenger } from '../../tools/view-hooks/messenger-hook';
import { ProvinceDto, RealEstateDto } from '../../services/settings/settings.dto';
import { SelectorOption } from '../add-contract/interfaces';
import { SessionContext } from 'auth/helpers/session.context';
import { useAPIMembershipService } from '../../services/memberships/memberships.service';

export const useSettingsController = (
    membershipsService = useAPIMembershipService(),
    settingsService = useAPISettingsService(),
    messenger = useMessenger(),
): SettingsController => {
    /* State */
    const [isLoading, setIsLoading] = useState(false);
    const [provincesOptions, setProvincesOptions] = useState<SelectorOption[]>([]);
    const [realEstate, setRealEstate] = useState<RealEstateDto | undefined>();
    const [isImgLoading, setIsImgLoading] = useState<boolean>(false);
    const [imgUrl, setImgUrl] = useState('');
    const { setSessionData } = useContext(SessionContext);
    const [planName, setPlanName] = useState('');
    const [maxContracts, setMaxContracts] = useState('');
    const [planPrice, setPlanPrice] = useState('');

    const [formInstance] = Form.useForm();

    /* Listeners */

    useEffect(() => {
        fetchMembership();
        fetchRealEstate();
        fetchProvinces();
    }, []);

    /* View Events */
    const onCancelPlan = () => {
        membershipsService
            .cancelMembership()
            .then((dto) => {
                setPlanName(dto.currentMembership.plan.name);
                setPlanPrice(dto.currentMembership.plan.price);
                setMaxContracts(dto.currentMembership.plan.maxContract);
                setSessionData((prevSession) => {
                    return { ...prevSession, currentMembership: dto };
                });
            })
            .catch((err) => {
                messenger.showErrorMessage({ key: 'settings.default-error' });
            });
    };

    const onFormSubmit = (inputs: any) => {
        const parsedInputs = inputs as SettingsFormValues;

        parsedInputs.phone ? parsedInputs.phone : (parsedInputs.phone = 0);
        parsedInputs.number ? parsedInputs.number : (parsedInputs.number = 0);
        parsedInputs.cuit ? parsedInputs.cuit : (parsedInputs.cuit = 0);
        settingsService
            .editSettings(parsedInputs)
            .then((response) => {
                setSessionData((prevSession) => {
                    return { ...prevSession, realEstate: response };
                });
                setRealEstate(response);
                setImgUrl(response.iconRealEstate ?? '');
                if (response.province?.id) {
                    fetchProvinces();
                }
                messenger.showSuccessMessage({ key: 'settings.successfully-updated' });
            })
            .catch(() => {
                messenger.showErrorMessage({ key: 'settings.default-error' });
            });
    };
    const fetchRealEstate = () => {
        setIsLoading(true);
        settingsService
            .getRealEstate()
            .then((dto) => {
                setRealEstate(dto);
                setImgUrl(dto.iconRealEstate ?? '');
            })
            .catch(() => {
                messenger.showErrorMessage({ key: 'settings.get-settings-error' });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };
    const fetchProvinces = () => {
        settingsService.getAllProvinces().then((dtos) => {
            setProvincesOptions(dtos.map(mapDtoProvincesToSelectorOptions));
        });
    };

    const fetchMembership = () => {
        membershipsService
            .getCurrentMembershipDetail()
            .then((dto) => {
                setPlanName(dto.currentMembership?.plan?.name ?? '');
                setMaxContracts(String(dto.currentMembership?.plan?.maxContract ?? 0));
                setPlanPrice(String(dto.currentMembership?.plan?.price ?? 0));
            })
            .catch((err) => {
                messenger.showErrorMessage({ key: 'common-errors.web-error-message' });
            });
    };

    const handleChange = (info: any) => {
        if (info.file.status === 'uploading') {
            setIsImgLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            const newUrl = info.file.response.iconRealEstate;
            setImgUrl(newUrl);
            setIsImgLoading(false);
            setSessionData((prevSession) => {
                return { ...prevSession, avatarUrl: newUrl };
            });
        }
    };

    /* Private Methods */

    const mapDtoProvincesToSelectorOptions = (dto: ProvinceDto): SelectorOption => {
        return {
            value: dto.id ?? 0,
            label: dto.name ?? '',
        };
    };
    // Return state and events
    return {
        /* State */
        initialValues: realEstate
            ? {
                  ...realEstate,
                  provinceId: realEstate.province?.id,
              }
            : {},
        form: formInstance,
        isLoading,
        provincesOptions,
        realEstateViewModel: realEstate ?? {},
        isImgLoading,
        imgUrl: imgUrl != '' ? imgUrl + '?id=' + Math.random() : '',
        planName,
        planPrice,
        maxContracts,
        /*Events */
        onFormSubmit,
        handleChange,
        onCancelPlan,
    };
};
