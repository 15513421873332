import { SessionContext } from 'auth/helpers/session.context';
import { MainViewController } from 'fragments/main/interfaces';
import { useContext, useEffect } from 'react';
import { useAPISettingsService } from 'services/settings/settings.service';
import { useAPIMembershipService } from 'services/memberships/memberships.service';

export const useMainController = (
    membershipsService = useAPIMembershipService(),
    realEstateService = useAPISettingsService(),
): MainViewController => {
    const { setSessionData } = useContext(SessionContext);
    useEffect(() => {
        fetchCurrentSubscription();
        fetchRealEstate();
    }, []);

    const fetchCurrentSubscription = () => {
        membershipsService.getCurrentMembershipDetail().then((dto) => {
            setSessionData((prevState) => {
                return { ...prevState, currentMembership: dto };
            });
        });
    };

    const fetchRealEstate = () => {
        realEstateService.getRealEstate().then((dto) => {
            setSessionData((prevState) => {
                return { ...prevState, avatarUrl: dto.iconRealEstate, realEstate: dto };
            });
        });
    };

    return {};
};
