import axios from 'axios';
import { plainToClass } from 'class-transformer';
import { Session } from 'auth/models/session';
import { PdfFreeReceiptService } from 'services/pdfFreeReceipt/pdf-free-receipt.service';
import { CreatePdfFreeReceiptDto } from 'services/pdfFreeReceipt/complete-pdf-free-receipt.dto';
import { PdfFreeReceiptDto } from 'services/pdfFreeReceipt/pdf-free-receipt.dto';

export class ApiPdfFreeReceiptService implements PdfFreeReceiptService {
    constructor(private session: Session) {}
    async generatePdfFreeReceipt(body: CreatePdfFreeReceiptDto): Promise<PdfFreeReceiptDto> {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/configuration/pdf/free`, body, {
            headers: { Authorization: `Bearer ${this.session.getToken()}` },
        });
        return plainToClass(PdfFreeReceiptDto, response.data);
    }
}
