import axios from 'axios';
import { plainToClass } from 'class-transformer';
import { Session } from 'auth/models/session';
import { MultipleRentalPaymentsDto, RentalPaymentDto } from 'services/rentalPayments/rentalPayment.dto';
import { RentalPaymentService } from './rentalPayment.service';
import { FollowUpDto } from '../followUps/followUp.dto';
import { DownloadableItem } from './receipt.dto';

export class ApiRentalPaymentService implements RentalPaymentService {
    constructor(private session: Session) {}
    async onPaymentChange(input: RentalPaymentDto): Promise<FollowUpDto> {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/rentalPayment`, input, {
            headers: { Authorization: `Bearer ${this.session.getToken()}` },
        });
        return plainToClass(FollowUpDto, response.data);
    }

    async addMultiplePayments(input: MultipleRentalPaymentsDto[]): Promise<FollowUpDto[]> {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/rentalPayment/multiple`, input, {
            headers: { Authorization: `Bearer ${this.session.getToken()}` },
        });
        return response.data.map((data: any) => plainToClass(FollowUpDto, data));
    }

    async onIssueReceipt(input: { followUpIds: number[] }): Promise<DownloadableItem[]> {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/receipt/multi`, input, {
            headers: { Authorization: `Bearer ${this.session.getToken()}` },
        });
        return response.data;
    }

    async onIssueSettlements(input: {
        followUpIds: number[];
        allSelected: boolean;
    }): Promise<{ selectedFollowUpsByOwner: DownloadableItem[]; currentMonthFollowUpsByOwner: DownloadableItem[] }> {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/liquidation`, input, {
            headers: { Authorization: `Bearer ${this.session.getToken()}` },
        });
        return response.data;
    }
}
