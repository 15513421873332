import { useContext, useEffect, useState } from 'react';
import {
    InformationInterestModal,
    ModalInformationInterestController,
} from 'fragments/contract-card/modal-information-interest/interfaces';
import { Form } from 'antd';
import { ContractContext } from '../contract-card.component';
import { ContractsService, useAPIContractService } from 'services/contracts/contracts.service';

export const useModalInformationInterestController = (
    onContractInformationAdded: () => void,
    contractsService: ContractsService = useAPIContractService(),
): /* <--Dependency Injections  like services hooks */
ModalInformationInterestController => {
    /* State */
    const { contract, setContract } = useContext(ContractContext);
    const [isLoading, setIsLoading] = useState(false);
    const [formInstance] = Form.useForm();
    const [isDisabled, setIsDisabled] = useState(false);

    useEffect(() => {
        if (contract.dateResition || !contract.contractIsCurrent) {
            setIsDisabled(true);
        } else {
            false;
        }
    }, []);

    const onFormSubmit = (inputs: any) => {
        setIsLoading(true);
        contractsService
            .updateInformationInterest(inputs, contract.id ?? 0)
            .then((resp) => {
                setContract({
                    ...contract,
                    debtCollection: resp.debtCollection ?? 0,
                    expiredDay: resp.expiredDay ?? 10,
                    initialDayInterest: resp.initialDayInterest ?? 1,
                    typeOfCharge: resp.typeOfCharge ?? 'perecentage',
                });
                onContractInformationAdded();
            })
            .catch((err) => console.log(err))
            .finally(() => setIsLoading(false));
    };

    const initialValuesInformationInterest: InformationInterestModal = {
        expiredDay: contract.expiredDay ?? 10,
        initialDayInterest: contract.initialDayInterest ?? 1,
        typeOfCharge: contract.typeOfCharge ?? 'percentage',
        debtCollection: contract.debtCollection ?? 0,
    };

    return {
        form: formInstance,
        isLoading,
        initialValues: initialValuesInformationInterest,
        onFormSubmit,
        contract,
        isDisabled,
    };
};
