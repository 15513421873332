import { ApiPropertieService } from './api-propertie.service';
import { useLocalSession } from 'auth/helpers/session.hooks';
import { CompletePropertyDto } from 'services/properties/property-complete.dto';
import { PropertyDto } from 'services/properties/property.dto';

export interface PropertiesService {
    getAllProperties(): Promise<CompletePropertyDto[]>;
    getOneProperty(propertyId: number): Promise<CompletePropertyDto>;
    addProperty(input: PropertyDto): Promise<CompletePropertyDto>;
    searchProperty(input: { param: string }): Promise<CompletePropertyDto[]>;
    deleteProperty(id: number): Promise<void>;
    getOnePropertyByContractId(id: number): Promise<CompletePropertyDto>;
    updateProperty(inputs: PropertyDto, id: number): Promise<CompletePropertyDto>;
}

export const useAPIPropertieService = (): PropertiesService => {
    const [getSession] = useLocalSession();
    const session = getSession();
    return new ApiPropertieService(session);
};
