import { useEffect, useState } from 'react';
import { UpdateYourPlanController } from 'fragments/update-your-plan/interfaces';
import { Form } from 'antd';
import { plainToClass } from 'class-transformer';
import { PersonDto } from '../../services/persons/person.dto';
import { useAPIMembershipService } from '../../services/memberships/memberships.service';

export const useUpdateYourPlanController = (
    membershipsService = useAPIMembershipService(),
): /* <--Dependency Injections  like services hooks */
UpdateYourPlanController => {
    /* State */
    const [maxContracts, setMaxContracts] = useState(0);
    const [nextPlanPrice, setNextPlanPrice] = useState(0);
    const [nextPlanMaxContracts, setNextPlanMaxContracts] = useState(0);
    const [nextPlanName, setNextPlanName] = useState('');

    /* Listeners */
    useEffect(() => {
        fetchMembershipDetails();
    }, []);
    /* View Events */
    const fetchMembershipDetails = () => {
        membershipsService.getCurrentMembershipDetail().then((dto) => {
            setNextPlanPrice(dto.nextPlan?.price ?? 0);
            setMaxContracts(dto.currentMembership?.plan?.maxContract ?? 0);
            setNextPlanMaxContracts(dto.nextPlan?.maxContract ?? 0);
            setNextPlanName(dto.nextPlan?.name ?? '');
        });
    };

    /* Private Methods */

    // Return state and events
    return { maxContracts, nextPlanName, nextPlanMaxContracts, nextPlanPrice };
};
