import React from 'react';

import './edit-contract.scss';
import { EditContractFragmentProps } from 'fragments/contract-card/edit-contract/interfaces';
import { useEditContractController } from 'fragments/contract-card/edit-contract/edit-contract.controller';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import ModalForm from 'components/modal-form/modal-form.component';
import SelectOrCreateItem from 'components/select-or-create-item/select-or-create-item.component';
import CreatePropertyFields from 'components/create-property-fields/create-property-fields.component';
import CreatePersonsFields from 'components/create-persons-fields/create-persons-fields.component';

export const EditContractFragment: React.FC<EditContractFragmentProps> = (props) => {
    const { useController = useEditContractController } = props;
    const controller = useController(props.onFinish);
    const { translate } = useTranslator();

    return (
        <ModalForm
            className={'edit-contract'}
            onFinish={controller.onFormSubmit}
            form={controller.form}
            isvisible={props.isVisible}
            onCancel={props.onCancel}
            isLoading={controller.isLoading}
            layout={'vertical'}
            initialValues={controller.initialValues}
            title={translate({ key: 'contracts.edit-contract' })}
        >
            <SelectOrCreateItem
                createFormItemName={'newProperty'}
                selectOptions={controller.propertiesOptions}
                optionFilterProp={'label'}
                createComponent={
                    <CreatePropertyFields
                        personsOptions={controller.personsOptions}
                        provincesOptions={controller.provincesOptions}
                    />
                }
                createTitle={translate({ key: 'contracts.property-data' })}
                name={'propertyId'}
                label={translate({ key: 'contracts.properties' })}
                requiredForm={true}
                rulesComponent={[
                    {
                        validator: (_: any, value: any, callback: any) => {
                            if (
                                !value ||
                                !value.street ||
                                !value.district ||
                                !value.provinceId ||
                                !value.city ||
                                !value.owners ||
                                (value.owners === -1 &&
                                    (!value.newOwner ||
                                        !value.newOwner.name ||
                                        !value.newOwner.lastName ||
                                        !value.newOwner.numberDoc))
                            ) {
                                callback('Complete los datos requeridos');
                                return;
                            }
                            callback();
                        },
                    },
                ]}
            />
            <SelectOrCreateItem
                name={'rentersId'}
                createFormItemName={'newRenter'}
                selectOptions={controller.personsOptions}
                selectMode={'multiple'}
                optionFilterProp={'label'}
                onClickSelect={controller.onClickSelect}
                isOpen={controller.selectIsOpen}
                onBlurSelect={controller.onBlurSelect}
                createComponent={<CreatePersonsFields provincesOptions={controller.provincesOptions} />}
                createTitle={translate({ key: 'contracts.renter-data' })}
                label={translate({ key: 'contracts.renters' })}
                requiredForm={true}
                rulesComponent={[
                    {
                        validator: (_: any, value: any, callback: any) => {
                            if (!value || !value.name || !value.lastName || !value.numberDoc) {
                                callback('Complete los datos requeridos');
                                return;
                            }
                            callback();
                        },
                    },
                ]}
            />
        </ModalForm>
    );
};
