import React from 'react';
import 'fragments/update-your-plan/update-your-plan.scss';
import { UpdateYourPlanFragmentProps } from 'fragments/update-your-plan/interfaces';
import { useUpdateYourPlanController } from 'fragments/update-your-plan/update-your-plan.controller';
import { Divider, Modal, Space, Typography, Tag } from 'antd';
import { useTranslator } from '../../tools/view-hooks/translator-hook';

export const UpdateYourPlanFragment: React.FC<UpdateYourPlanFragmentProps> = (props) => {
    const { useController = useUpdateYourPlanController } = props;
    const controller = useController();
    const { translate } = useTranslator();
    const { Text } = Typography;
    const { Title } = Typography;

    // Render
    return (
        <Modal
            className={'update-plan-modal-form'}
            visible={props.isVisible}
            onCancel={props.onCancel}
            onOk={props.handleOnClickUpdate}
            confirmLoading={props.isLoadingUpdateButton}
            okText={translate({ key: 'memberships.update' })}
            width={600}
        >
            <Title className={'title'} level={3}>
                {translate({ key: 'memberships.you-have-reached-the-imit-of-your-plan' })}
            </Title>
            <Space size={'small'} align={'center'}>
                <Text className={'update-plan-body'}>
                    {translate({ key: 'memberships.your-current-plan-allows-you-to-have-up-to' })}{' '}
                    <strong>
                        {' '}
                        {controller.maxContracts} {translate({ key: 'memberships.active-contracts' })},
                    </strong>
                    {translate({ key: 'memberships.to-add-more-contracts-you-must-update-your-plan' })}
                </Text>
            </Space>
            <Divider> -- </Divider>
            <div className={'next-plan'}>
                <Tag color="purple">{translate({ key: 'memberships.next-plan' })}</Tag>
            </div>
            <Title className={'plan-name'} level={4}>
                {controller.nextPlanName}
            </Title>
            <Space size={'small'} align={'center'} className={'plan-active-contracts'}>
                <Text>
                    {controller.nextPlanMaxContracts}
                    {translate({ key: 'memberships.active-contracts' })}
                </Text>
            </Space>
            <Title type={'success'} className={'plan-price'} level={3}>
                $ {controller.nextPlanPrice}
            </Title>
        </Modal>
    );
};
