import { useContext, useState } from 'react';
import { RescindContractController } from 'fragments/rescind-contract/interfaces';
import { Form } from 'antd';
import { useMessenger } from '../../tools/view-hooks/messenger-hook';
import { useAPIContractService } from '../../services/contracts/contracts.service';
import { RescindContractDto } from '../../services/contracts/rescind-contract.dto';
import { ContractContext } from 'fragments/contract-card/contract-card.component';

export const useRescindContractController = (
    contractId: number,
    onFinish: () => void,
    contractsService = useAPIContractService(),
    messenger = useMessenger(),
): RescindContractController => {
    /* State */
    const [isLoading, setIsLoading] = useState(false);
    const { setContract } = useContext(ContractContext);
    const [formInstance] = Form.useForm();

    /* Listeners */

    /* View Events */
    const onFormSubmit = (inputs: any) => {
        setIsLoading(true);
        const date = inputs.date;
        delete inputs.date;

        const dto = inputs as RescindContractDto;
        dto.month = date.month() + 1;
        dto.year = date.year();
        dto.idContract = contractId;

        contractsService
            .rescindContract(dto)
            .then((dto) => {
                setContract((prevState) => {
                    return { ...prevState, ...dto };
                });
                formInstance.resetFields();
                onFinish();
            })
            .catch(() => {
                messenger.showErrorMessage({ key: 'contracts.add-contract-error' });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };
    /* Private Methods */

    // Return state and events
    return { form: formInstance, isLoading, onFormSubmit };
};
