import { useState } from 'react';
import { PersonCardController } from 'fragments/person-card/interfaces';
import { CompletePersonDto } from 'services/persons/person-complete.dto';

export const usePersonCardController = (completePersonDto: CompletePersonDto): PersonCardController => {
    /* State */
    const [person, setPerson] = useState(completePersonDto);
    const [isPersonEditModalVisible, setIsPersonEditModalVisible] = useState<boolean>(false);

    const onPersonEditPressed = () => {
        setIsPersonEditModalVisible(true);
    };
    const onPersonEditCanceled = () => {
        setIsPersonEditModalVisible(false);
    };

    const onPersonEdit = (dto: CompletePersonDto) => {
        setIsPersonEditModalVisible(false);
        setPerson(dto);
    };

    const onPersonDetailAdded = (dto: CompletePersonDto) => {
        setIsPersonEditModalVisible(false);
        setPerson(dto);
    };

    /* Listeners */

    /* View Events */

    /* Private Methods */

    // Return state and events
    const provinceName = person.province && person.province.name ? person.province.name : '';
    const districtSeparator = person.district ? 'Bº' : '';
    const provinceSeparator = provinceName || person.city ? '-' : '';
    const floorDepartmentSeparator = person.floorDepartment ? '-' : '';
    const street = person.street ? person.street : '';
    const number = person.number ? person.number : '';
    const district = person.district ? person.district : '';
    const floorDepartment = person.floorDepartment ? person.floorDepartment : '';
    const city = person.city ? person.city : '';
    const personAddress = `${street} ${number} ${floorDepartmentSeparator} ${floorDepartment} ${districtSeparator} ${district} ${provinceSeparator}`;
    const provinceCity = ` ${provinceName} ${city}`;

    return {
        id: person.id ?? 0,
        name: person.name ?? '',
        typeDoc: person.typeDoc ?? undefined,
        numberDoc: person.numberDoc ?? '',
        cuit: person.cuit ?? '-',
        phone: person.phone ?? '-',
        email: person.email ?? '',
        address: personAddress ?? '',
        provinceCity,
        cbu: person.cbu ?? '-',
        rolPersonStatus: person.rolPersonStatus ?? '',
        activesRoles: person.activesRoles ?? [],
        isVisible: person.isVisible ?? false,
        title: `${person.name} ${person.lastName}` ?? '',
        onPersonEditPressed: onPersonEditPressed,
        onPersonEditCanceled: onPersonEditCanceled,
        onPersonEdit: onPersonEdit,
        isPersonEditModalVisible,
        onPersonDetailAdded: onPersonDetailAdded,
        person,
        setPerson,
    };
};
