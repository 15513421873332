import { useContext, useEffect, useState } from 'react';

import { Form } from 'antd';

import { EditPropertyController, EditPropertyFormValues } from 'fragments/property-card/edit-property/interface';
import { useAPIPropertieService } from 'services/properties/properties.service';
import { useMessenger } from 'tools/view-hooks/messenger-hook';
import { PropertyContext } from 'fragments/property-card/property.context';
import { SelectorOption } from 'fragments/add-property/interfaces';
import { PersonsService, useAPIPersonsService } from 'services/persons/persons.service';
import { CompletePersonDto } from 'services/persons/person-complete.dto';
import { useAPISettingsService } from 'services/settings/settings.service';
import { ProvinceDto } from 'services/settings/settings.dto';

export const useEditPropertyController = (
    onFinish: () => void,
    propertiesService = useAPIPropertieService(),
    personsService: PersonsService = useAPIPersonsService(),
    settingsService = useAPISettingsService(),

    messenger = useMessenger(),
): EditPropertyController => {
    /* State */
    const [isLoading, setIsLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const { property, setProperty } = useContext(PropertyContext);
    const [formInstance] = Form.useForm();
    const [personsOptions, setPersonsOptions] = useState<SelectorOption[]>([]);
    const [provincesOptions, setProvincesOptions] = useState<SelectorOption[]>([]);

    /* Listeners */

    useEffect(() => {
        fetchPersons();
        fetchProvinces();
    }, []);

    /* View Events */
    const onFormSubmit = (inputs: EditPropertyFormValues) => {
        if (!property.id) {
            return;
        }
        setIsLoading(true);
        propertiesService
            .updateProperty(inputs, property.id)
            .then((output) => {
                setProperty((prevState) => {
                    return { ...prevState, ...output, province: output.province, owners: output.owners };
                });
                fetchPersons();
                onFinish();
            })
            .catch(() => {
                messenger.showErrorMessage({ key: 'properties.add-property-detail-error' });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };
    /* Private Methods */

    const mapDtoPersonsToSelectorOptions = (dto: CompletePersonDto): SelectorOption => {
        return {
            value: dto.id ?? 0,
            label: `${dto.name} ${dto.lastName}` ?? '',
        };
    };

    const setPersonsOptionsDontVisible = (dtos: SelectorOption[]) => {
        const dontVisible = property.owners ? property.owners.filter((owner) => !owner.isVisible ?? 0) : [];
        if (dontVisible[0]) {
            for (const owner of dontVisible) {
                const name = owner.name ?? '';
                const lastName = owner.lastName ?? '';
                const label = name + ' ' + lastName + ' ' + '(eliminado/a)';
                const option = { value: owner.id ?? 0, label };
                const dto = [...dtos, option];
                setPersonsOptions(dto);
            }
        }
    };

    const fetchPersons = () => {
        personsService.getAllPersons().then((dtos) => {
            const dto = dtos.map(mapDtoPersonsToSelectorOptions);
            setPersonsOptions(dto);
            setPersonsOptionsDontVisible(dto);
        });
    };

    /* Private Methods */
    const validateOwners = (rule: any, value: any, callback: any) => {
        if (value.length > 3 || value.length === 0) {
            callback('Permitidos: máximo 3 - mínimo uno');
        } else {
            callback();
        }
        return;
    };

    const fetchProvinces = () => {
        settingsService.getAllProvinces().then((dtos) => {
            setProvincesOptions(dtos.map(mapDtoProvincesToSelectorOptions));
        });
    };

    const mapDtoProvincesToSelectorOptions = (dto: ProvinceDto): SelectorOption => {
        return {
            value: dto.id ?? 0,
            label: dto.name ?? '',
        };
    };

    const mapToOwnersId = property.owners ? property.owners.map((owner) => owner.id ?? 0) : [];

    const onClickSelect = () => {
        setIsOpen(!isOpen);
    };

    const onBlurSelect = () => {
        setIsOpen(false);
    };
    // Return state and events
    return {
        form: formInstance,
        isLoading,
        initialValues: { ...property, ownersId: mapToOwnersId, provinceId: property.province?.id ?? 0 },
        onFormSubmit,
        personsOptions,
        validateOwners,
        provincesOptions,
        isOpen,
        onClickSelect,
        onBlurSelect,
    };
};
