import React from 'react';

import { Button, Descriptions, Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import 'fragments/property-card/property-card-details/property-card-details.scss';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { ContractCardDetailsFragmentProps } from './interface';
import { useContractCardDetailsController } from './contract-details.controller';
import { EditContractsDetailsFragment } from './edit-contracts-details/edit-contracts-details.fragment';

export const ContractCardDetailsFragment: React.FC<ContractCardDetailsFragmentProps> = (props) => {
    const { useController = useContractCardDetailsController } = props;
    const controller = useController();
    const { translate } = useTranslator();

    // Render
    return (
        <div className={'contract-details'}>
            <EditContractsDetailsFragment
                isVisible={controller.isEditModalVisible}
                onCancel={controller.onEditCanceled}
                onFinish={controller.onFinish}
            />
            <Descriptions
                title={translate({ key: 'contracts.contracts-detail' })}
                column={1}
                labelStyle={{ fontWeight: 'bold' }}
                extra={<EditOutlined className={'link-icon'} onClick={controller.onEditContractDetailsPressed} />}
            >
                <Descriptions.Item label={translate({ key: 'contracts.start-date' })}>
                    <Typography.Text>{controller.startDateContract}</Typography.Text>
                </Descriptions.Item>
                <Descriptions.Item label={translate({ key: 'contracts.period' })}>
                    <Typography.Text>
                        {controller.durationContract} {translate({ key: 'contracts.years' })}
                    </Typography.Text>
                </Descriptions.Item>
                <Descriptions.Item label={translate({ key: 'contracts.administration-percentage' })}>
                    <Typography.Text>{controller.percentageByAdministration}%</Typography.Text>
                </Descriptions.Item>
                <Descriptions.Item label={translate({ key: 'contracts.start-price' })}>
                    <Typography.Text>{controller.startPrice}</Typography.Text>
                </Descriptions.Item>

                <Descriptions.Item label={translate({ key: 'contracts.guarantors' })}>
                    <Typography.Text>{controller.guarantorName}</Typography.Text>
                </Descriptions.Item>

                <Descriptions.Item>
                    {controller.fileUrl && (
                        <div className={'contracts-links'}>
                            <Button className={'contract-pdf'} type="link" href={controller.fileUrl}>
                                {translate({ key: 'contracts.see-attached-pdf' })}
                            </Button>
                        </div>
                    )}
                </Descriptions.Item>
            </Descriptions>
        </div>
    );
};
