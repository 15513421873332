import { useContext, useEffect, useState } from 'react';
import { EditPersonController, PersonFormValues } from 'fragments/person-card/person-edit/interface';
import { Form } from 'antd';
import { useMessenger } from 'tools/view-hooks/messenger-hook';
import { PersonContext } from '../person-card.component';
import { PersonsService, useAPIPersonsService } from 'services/persons/persons.service';
import { PersonDto } from 'services/persons/person.dto';
import { CompletePersonDto } from 'services/persons/person-complete.dto';
import { useAPISettingsService } from 'services/settings/settings.service';
import { SelectorOption } from 'fragments/add-property/interfaces';
import { ProvinceDto } from 'services/settings/settings.dto';

export const useEditPersonController = (
    onPersonDetailAdded: (dto: CompletePersonDto) => void,
    personService: PersonsService = useAPIPersonsService(),
    messenger = useMessenger(),
    settingsService = useAPISettingsService(),
): EditPersonController => {
    /* State */
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectIsOpen, setSelectIsOpen] = useState<boolean>(false);
    const { person, setPerson } = useContext(PersonContext);
    const [formInstance] = Form.useForm();
    const [provincesOptions, setProvincesOptions] = useState<SelectorOption[]>([]);

    /* Listeners */

    useEffect(() => {
        fetchProvinces();
    }, []);
    /* View Events */

    /* Private Methods */
    const onFormSubmit = (inputs: any) => {
        if (!person.id) {
            return;
        }
        const dni = inputs.numberDoc.split('.');
        inputs.numberDoc = dni.join('');
        setIsLoading(true);
        const parsedInputs = inputs as PersonFormValues;
        const body = parsedInputs as PersonDto;
        if (body.number === '') {
            body.number = undefined;
        }
        personService
            .updatePerson(person.id, body ?? [])
            .then((dto) => {
                setPerson((prevState) => {
                    return { ...prevState, dto };
                });
                onPersonDetailAdded(dto);
            })
            .catch(() => {
                messenger.showErrorMessage({ key: 'services-taxes.deafult-error' }); //cambiar error. Debería ser un error de observation.
            })
            .finally(() => {
                setIsLoading(false);
            });
    };
    const fetchProvinces = () => {
        settingsService.getAllProvinces().then((dtos) => {
            setProvincesOptions(dtos.map(mapDtoProvincesToSelectorOptions));
        });
    };

    const mapDtoProvincesToSelectorOptions = (dto: ProvinceDto): SelectorOption => {
        return {
            value: dto.id ?? 0,
            label: dto.name ?? '',
        };
    };

    const onClickSelect = () => {
        setSelectIsOpen(!selectIsOpen);
    };

    return {
        initialValues: { ...person, provinceId: person.province?.id ?? 0 },
        form: formInstance,
        isLoading,
        provincesOptions,
        onFormSubmit,
        onClickSelect,
        selectIsOpen,
    };
};
