import { useContext, useState } from 'react';
import { PropertyCardDetailsController } from 'fragments/property-card/property-card-details/interfaces';
import { PropertyContext } from 'fragments/property-card/property.context';
import { useTranslator } from 'tools/view-hooks/translator-hook';

export const usePropertyCardDetailsController = (translator = useTranslator()): PropertyCardDetailsController => {
    const { translate } = translator;
    /* State */
    const [isDetailFormVisible, setIsDetailFormVisible] = useState(false);
    const { property } = useContext(PropertyContext);

    /* Listeners */

    /* View Events */

    const onEditDetailPressed = () => {
        setIsDetailFormVisible(true);
    };

    const onDetailCanceled = () => {
        setIsDetailFormVisible(false);
    };

    const onDetailUpdated = () => {
        setIsDetailFormVisible(false);
    };

    /* Private Methods */

    // Return state and events
    const yesLabel = translate({ key: 'general.yes' });
    const noLabel = translate({ key: 'general.no' });
    return {
        roomsCountLabel: property.rooms ? `${property.rooms}` : '-',
        bathCountLabel: property.bathRooms ? `${property.bathRooms}` : '-',
        isFurnishedLabel: property.isFurnished ? yesLabel : noLabel,
        description: property.description ?? '-',
        isDetailFormVisible,
        onEditDetailPressed,
        onDetailUpdated,
        onDetailCanceled,
    };
};
