import axios from 'axios';
import { plainToClass } from 'class-transformer';
import { Session } from 'auth/models/session';
import { ObservationDto } from './observation.dto';
import { ObservationService } from './observation.service';
import { CompleteObservationDto } from './complete-observation.dto';

export class ApiObservationService implements ObservationService {
    constructor(private session: Session) {}
    async getAllObservations(id: number): Promise<CompleteObservationDto[]> {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/observation/${id}`, {
            headers: { Authorization: `Bearer ${this.session.getToken()}` },
        });
        return response.data.map((item: any) => plainToClass(CompleteObservationDto, item));
    }

    async addMultiObservations(contractId: number, input: ObservationDto[]): Promise<CompleteObservationDto[]> {
        const response = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/observation/multi/${contractId}`,
            input,
            {
                headers: { Authorization: `Bearer ${this.session.getToken()}` },
            },
        );
        return response.data as CompleteObservationDto[];
    }

    async deleteObservation(id: number): Promise<void> {
        return axios.delete(`${process.env.REACT_APP_API_BASE_URL}/observation/${id}`, {
            headers: { Authorization: `Bearer ${this.session.getToken()}` },
        });
    }

    async updateObservation(id: number, input: ObservationDto): Promise<CompleteObservationDto[]> {
        const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/observation/${id}`, input, {
            headers: { Authorization: `Bearer ${this.session.getToken()}` },
        });
        return response.data as CompleteObservationDto[];
    }
}
