import { useContext, useState } from 'react';

import moment from 'moment';

import { ContractsObservationsController } from './interfaces';
import { ObservationCardProps } from 'components/observations-card/obsevation-card.component';
import { ContractContext } from '../contract-card.component';
import { ObservationService, useAPIObservationsService } from 'services/observations/observation.service';
import { useMessenger } from 'tools/view-hooks/messenger-hook';

export const useContractsObservationsController = (
    observationService: ObservationService = useAPIObservationsService(),
    messenger = useMessenger(),
): ContractsObservationsController => {
    const [isAddObservationModalVisible, setIsAddObservationModalVisible] = useState<boolean>(false);
    const { contract, setContract } = useContext(ContractContext);

    const onAddObservationPressed = () => {
        setIsAddObservationModalVisible(true);
    };
    const onAddObservationCanceled = () => {
        setIsAddObservationModalVisible(false);
    };

    const onObservationAdded = () => {
        setIsAddObservationModalVisible(false);
    };

    const mapDtosToServices = (): ObservationCardProps[] => {
        if (!contract.observations) {
            return [];
        }
        return (
            contract.observations
                .sort((a, b) => {
                    return moment(a.date).isBefore(moment(b.date)) ? -1 : 1;
                })
                .map((observation) => {
                    return {
                        id: observation.id ?? 0,
                        status: observation.status ?? '',
                        description: observation.description ?? '',
                        date: observation.date ?? new Date(),
                        onSelectedObservationStatusChange: (value) => {
                            observationService
                                .updateObservation(observation.id ?? 0, { status: value, idContract: contract.id })
                                .then((dto) => {
                                    setContract((prevState) => {
                                        return { ...prevState, observations: dto };
                                    });
                                })
                                .catch(() => {
                                    messenger.showErrorMessage({ key: 'observations.add-observations-error' });
                                });
                        },
                    };
                }) ?? []
        );
    };
    const observationsPending = mapDtosToServices().filter((element) => element.status === 'pending');
    const observationsChecked = mapDtosToServices().filter((element) => element.status === 'checked');

    return {
        isAddObservationModalVisible: isAddObservationModalVisible,
        observationViewModel: observationsPending.concat(observationsChecked),
        onAddObservationPressed: onAddObservationPressed,
        onAddObservationCanceled: onAddObservationCanceled,
        onObservationAdded: onObservationAdded,
    };
};
