import { CompletePersonDto } from 'services/persons/person-complete.dto';

export class PropertyDto {
    id?: number;
    district?: string;
    postalCode?: string;
    street?: string;
    number?: number;
    floorDepartment?: string;
    alias?: string;
    rooms?: number;
    bathRooms?: number;
    isFurnished?: boolean;
    description?: string;
    owners?: CompletePersonDto[];
    provinceId?: number;
    city?: string;
}
