import React from 'react';
import { Divider, Form, Input, InputNumber, Select, Row, Col } from 'antd';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import ModalForm from 'components/modal-form/modal-form.component';
import { EditPropertyFragmentProps } from './interface';
import { useEditPropertyController } from './edit-property.controller';
import './edit-property.scss';
import { fixSelectOnScroll } from 'tools/view-hooks/fix-autocomplete';

export const EditPropertyFragment: React.FC<EditPropertyFragmentProps> = (props) => {
    const { useController = useEditPropertyController } = props;
    const controller = useController(props.onFinish);
    const { translate } = useTranslator();
    return (
        <ModalForm
            className={'edit-property'}
            onFinish={controller.onFormSubmit}
            form={controller.form}
            isvisible={props.isVisible}
            onCancel={props.onCancel}
            isLoading={controller.isLoading}
            layout={'vertical'}
            initialValues={controller.initialValues}
            title={translate({ key: 'add-property.edit-property' })}
            autoComplete={'off'}
        >
            <Form.Item
                name="ownersId"
                label={translate({ key: 'add-property.owners' })}
                required={true}
                rules={[
                    {
                        validator: controller.validateOwners,
                    },
                ]}
            >
                <Select
                    allowClear
                    mode="multiple"
                    placeholder={translate({ key: 'add-property.select-an-owner' })}
                    options={controller.personsOptions}
                    optionFilterProp="label"
                    onClick={controller.onClickSelect}
                    open={controller.isOpen}
                    onBlur={controller.onBlurSelect}
                    getPopupContainer={fixSelectOnScroll}
                />
            </Form.Item>
            <Divider />
            <Row gutter={10}>
                <Col xs={{ span: 24 }} xl={{ span: 12 }}>
                    <Form.Item label={translate({ key: 'properties.street' })} required={true} name="street">
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 12 }} md={{ span: 12 }} xl={{ span: 6 }}>
                    <Form.Item label={translate({ key: 'properties.number' })} required={false} name="number">
                        <InputNumber minLength={0} placeholder={'S/N'} type="number" />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 12 }} md={{ span: 12 }} xl={{ span: 6 }}>
                    <Form.Item label={translate({ key: 'properties.floor-department' })} name="floorDepartment">
                        <Input style={{ textTransform: 'uppercase' }} />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item label={translate({ key: 'properties.district' })} required={true} name="district">
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item label={translate({ key: 'properties.postal-code' })} required={false} name="postalCode">
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                        rules={[{ required: true }]}
                        name="provinceId"
                        label={translate({ key: 'settings.province' })}
                    >
                        <Select
                            allowClear
                            showSearch
                            options={controller.provincesOptions}
                            optionFilterProp="label"
                            getPopupContainer={fixSelectOnScroll}
                        />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item rules={[{ required: true }]} name="city" label={translate({ key: 'settings.city' })}>
                        <Input autoComplete="off" />
                    </Form.Item>
                </Col>
            </Row>
        </ModalForm>
    );
};
