import React from 'react';
import 'fragments/property-card/property-card-taxes/property-card-taxes.scss';
import { PropertyCardTaxesFragmentProps } from 'fragments/property-card/property-card-taxes/interfaces';
import { usePropertyCardTaxesController } from 'fragments/property-card/property-card-taxes/property-card-taxes.controller';
import { ListPropertyServicesFragment } from 'fragments/property-card/list-property-services/list-property-services.fragment';
import { Descriptions, Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useTranslator } from 'tools/view-hooks/translator-hook';

export const PropertyCardTaxesFragment: React.FC<PropertyCardTaxesFragmentProps> = (props) => {
    const { useController = usePropertyCardTaxesController } = props;
    const controller = useController();
    const { translate } = useTranslator();

    // Render
    return (
        <div className={'property-card-taxes'}>
            <ListPropertyServicesFragment
                isVisible={controller.isServiceFormVisible}
                onCancel={controller.onEditServicesCanceled}
                onFinished={controller.onServicesUpdated}
            />
            <Descriptions
                title={translate({ key: 'properties.services-and-taxes' })}
                column={1}
                labelStyle={{ fontWeight: 'bold' }}
                extra={<EditOutlined className={'link-icon'} onClick={controller.onEditServicesPressed} />}
            >
                {controller.servicesDescriptions.length == 0 ? (
                    <Typography.Text>{translate({ key: 'properties.no-services' })}</Typography.Text>
                ) : (
                    controller.servicesDescriptions.map((itemView) => {
                        return (
                            <Descriptions.Item key={itemView.label} label={itemView.label}>
                                {itemView.value}
                            </Descriptions.Item>
                        );
                    })
                )}
            </Descriptions>
        </div>
    );
};
