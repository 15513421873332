import React, { useState } from 'react';
import 'fragments/property-card/property-card.scss';
import { PropertyCardFragmentProps } from 'fragments/property-card/interfaces';
import { usePropertyCardController } from 'fragments/property-card/property-card.controller';
import { Col, Descriptions, Row, Space, Typography } from 'antd';
import DeleteModalButton from 'components/delete-modal-button/delete-modal-button.component';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { PropertyCardTaxesFragment } from 'fragments/property-card/property-card-taxes/property-card-taxes.fragment';
import { PropertyCardDetailsFragment } from 'fragments/property-card/property-card-details/property-card-details.fragment';
import { PropertyContext } from 'fragments/property-card/property.context';
import { EditPropertyFragment } from './edit-property/edit-property.fragment';
import { EditOutlined } from '@ant-design/icons';

export const PropertyCardFragment: React.FC<PropertyCardFragmentProps> = (props) => {
    const { useController = usePropertyCardController } = props;
    const controller = useController(props.propertyDto);
    const { translate } = useTranslator();
    const { Text } = Typography;

    // Render
    return (
        <PropertyContext.Provider value={{ property: controller.property, setProperty: controller.setProperty }}>
            <div className={'property-card'} key={controller.id}>
                <EditPropertyFragment
                    isVisible={controller.isEditModalVisible}
                    onCancel={controller.onEditCanceled}
                    onFinish={controller.onFinish}
                />
                <Space align={'start'}>
                    <Typography.Title level={5}>{controller.title}</Typography.Title>
                    <EditOutlined className={'link-icon'} onClick={controller.onEditPropertyPressed} />
                </Space>
                <Row gutter={10}>
                    <Col xs={24} lg={8}>
                        <Descriptions className={'property-card-header'} column={1} labelStyle={{ fontWeight: 'bold' }}>
                            <Descriptions.Item>{controller.subtitle}</Descriptions.Item>
                            <Descriptions.Item label={translate({ key: 'properties.owner' })}>
                                <Text>{controller.ownerName}</Text>
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                    <Col xs={24} lg={7}>
                        <PropertyCardTaxesFragment />
                    </Col>
                    <Col xs={24} lg={7}>
                        <PropertyCardDetailsFragment />
                    </Col>
                    <Col xs={24} lg={2}>
                        <DeleteModalButton
                            id={controller.id}
                            onClickDelete={props.onDelete}
                            title={`${translate({ key: 'properties.sure-delete-property' })} ${
                                controller.title
                            }? ${translate({ key: 'properties.warning' })}`}
                        />
                    </Col>
                </Row>
            </div>
        </PropertyContext.Provider>
    );
};
