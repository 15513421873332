export enum DocType {
    DNI = 'dni',
    PASSPORT = 'passport',
    IDENTIFICATION = 'identification',
}
export class PersonDto {
    name?: string;
    lastName?: string;
    typeDoc?: DocType;
    numberDoc?: string;
    cuit?: number | string;
    phone?: number | string;
    district?: string;
    postalCode?: string;
    street?: string;
    number?: number | string;
    floorDepartment?: string;
    provinceId?: number;
    city?: string;
    email?: string;
    cbu?: string;
}
