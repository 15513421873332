import React from 'react';
import 'components/modal-form/modal-form.scss';
import { Col, Form, Modal, Row, Typography } from 'antd';
import { FormInstance, FormProps } from 'antd/lib/form';

export interface ModalFormProps<Values = []> extends FormProps {
    isvisible?: boolean;
    form: FormInstance<Values>;
    isLoading?: boolean;
    title?: string;
    onFinish?: (d: any) => void;
    onCancel?: () => void;
    onResetFields?: () => void;
    okButtonLabel?: string;
    createButton?: JSX.Element;
    isEditSingleServiceVisible?: boolean;
}
const ModalForm: React.FC<ModalFormProps> = (props) => {
    return (
        <Modal
            className={props.className}
            centered
            visible={props.isvisible}
            cancelButtonProps={{ disabled: props.isLoading }}
            okButtonProps={{ loading: props.isLoading }}
            okText={props.okButtonLabel}
            closable={!props.isLoading}
            maskClosable={!props.isLoading}
            onCancel={() => {
                props.onCancel && props.onCancel();
                props.form.resetFields();
            }}
            onOk={props.form?.submit}
            destroyOnClose={true}
        >
            <Row align="middle">
                <Col xxl={16}>
                    {props.title ? (
                        <Typography.Title className="title" level={3}>
                            {props.title}
                        </Typography.Title>
                    ) : null}
                </Col>
                {!props.isEditSingleServiceVisible ? (
                    <Col xxl={8} style={{ marginBottom: '30px' }}>
                        {props.createButton ? props.createButton : null}
                    </Col>
                ) : (
                    ''
                )}
            </Row>

            <Form colon={false} {...props}>
                {props.children}
            </Form>
        </Modal>
    );
};

export default ModalForm;
