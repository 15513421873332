import { useEffect, useState } from 'react';
import { FollowUpCardController } from 'fragments/follow-up-card/interfaces';
import { CompleteFollowUpDto } from 'services/followUps/followUp.dto';

export const useFollowUpCardController = (dto: CompleteFollowUpDto): FollowUpCardController => {
    /* State */
    const [followUp, setFollowUp] = useState(dto);
    const [tagColor, setTagColor] = useState('');
    const [tagName, setTagName] = useState('');
    const [tagClassName, setTagClassName] = useState('');

    /* Listeners */
    useEffect(() => {
        calculateTag();
    }, [followUp]);

    /* View Events */
    const onUpdate = (dto: CompleteFollowUpDto): void => {
        setFollowUp(dto);
    };

    /* Private Methods */
    const calculateTag = () => {
        if (followUp.followUpStatus === 'isPaid') {
            setTagName('Pagado');
            setTagColor('green');
            setTagClassName('follow-up-status-tag');
        } else if (followUp.followUpStatus === 'expired') {
            setTagName('En mora');
            setTagColor('red');
            setTagClassName('follow-up-status-tag');
        } else {
            setTagName('Pendiente');
            setTagColor('default');
            setTagClassName('follow-up-status-tag');
        }
    };

    // Return state and events
    return {
        ownerName: `${followUp.ownerName}`,
        renterName: `${followUp.renterName}`,
        tagName,
        tagClassName,
        tagColor,
        title: followUp.alias ?? '-',
        followUp,
        setFollowUp,
        onUpdate,
    };
};
