import React from 'react';

import { Divider, Form, Input, InputNumber, Select, Row, Col } from 'antd';

import 'fragments/add-property/add-property.scss';
import { AddPropertyFragmentProps } from 'fragments/add-property/interfaces';
import { useAddPropertyController } from 'fragments/add-property/add-property.controller';
import ModalForm from 'components/modal-form/modal-form.component';
import SelectOrCreateItem from 'components/select-or-create-item/select-or-create-item.component';
import CreatePersonsFields from 'components/create-persons-fields/create-persons-fields.component';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { fixAutocomplete, fixSelectOnScroll } from 'tools/view-hooks/fix-autocomplete';

export const AddPropertyFragment: React.FC<AddPropertyFragmentProps> = (props) => {
    const { useController = useAddPropertyController } = props;
    const controller = useController(props.onPropertyAdded);
    const { translate } = useTranslator();
    // Render
    return (
        <ModalForm
            className={'add-property'}
            onFinish={controller.onFormSubmit}
            form={controller.form}
            isvisible={props.isVisible}
            onCancel={props.onCancel}
            isLoading={controller.isLoading}
            layout={'vertical'}
            okButtonLabel={translate({ key: 'general.add' })}
            title={translate({ key: 'properties.add-property' })}
            autoComplete={'off'}
        >
            <Row gutter={10}>
                <Col xs={{ span: 24 }}>
                    <SelectOrCreateItem
                        name={'owners'}
                        createFormItemName={'newOwner'}
                        selectOptions={controller.personsOptions}
                        selectMode={'multiple'}
                        selectPlaceholder={translate({ key: 'add-property.select-an-owner' })}
                        optionFilterProp={'label'}
                        createComponent={<CreatePersonsFields provincesOptions={controller.provincesOptions} />}
                        createTitle={translate({ key: 'properties.title-data-owner' })}
                        onClickSelect={controller.onClickSelect}
                        isOpen={controller.selectIsOpen}
                        onBlurSelect={controller.onBlurSelect}
                        label={translate({ key: 'add-property.owners' })}
                        requiredForm={true}
                        rules={[
                            { required: true },
                            {
                                validator: controller.validateOwners,
                            },
                        ]}
                        rulesComponent={[
                            {
                                validator: (_: any, value: any, callback: any) => {
                                    if (!value || !value.name || !value.lastName || !value.numberDoc) {
                                        callback('Complete los datos requeridos');
                                        return;
                                    }
                                    callback();
                                },
                            },
                        ]}
                    />
                </Col>
                <Divider />
                <Col xs={{ span: 24 }} xl={{ span: 12 }}>
                    <Form.Item
                        label={translate({ key: 'properties.street' })}
                        name="street"
                        rules={[{ required: true }]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 12 }} md={{ span: 12 }} xl={{ span: 6 }}>
                    <Form.Item
                        label={translate({ key: 'properties.number' })}
                        name="number"
                        rules={[{ required: false }]}
                    >
                        <InputNumber minLength={0} placeholder={'S/N'} type="number" />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 12 }} md={{ span: 12 }} xl={{ span: 6 }}>
                    <Form.Item
                        label={translate({ key: 'properties.floor-department' })}
                        name="floorDepartment"
                        rules={[{ required: false }]}
                    >
                        <Input style={{ textTransform: 'uppercase' }} />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                        label={translate({ key: 'properties.district' })}
                        name="district"
                        rules={[{ required: true }]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                        label={translate({ key: 'properties.postal-code' })}
                        name="postalCode"
                        rules={[{ required: false }]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }} id="select-search">
                    <Form.Item
                        rules={[{ required: true }]}
                        name="provinceId"
                        label={translate({ key: 'settings.province' })}
                    >
                        <Select
                            allowClear
                            showSearch
                            options={controller.provincesOptions}
                            optionFilterProp="label"
                            onFocus={fixAutocomplete}
                            getPopupContainer={fixSelectOnScroll}
                        />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item rules={[{ required: true }]} name="city" label={translate({ key: 'settings.city' })}>
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
        </ModalForm>
    );
};
